import {useContext, useRef, useEffect, useCallback} from 'react';
import {useNavigate} from '@reach/router'

import {LocalNavigateContext} from 'hocs/withLocalNavigateContext';


function useSmartNavigate() {
    const setLocalPathAndOptions = useContext(LocalNavigateContext);
    const navigate = useNavigate();

    // TODO: create hook

    // inside-render flag
    const isInsideRenderRef = useRef(null);
    isInsideRenderRef.current = true;
    // after-render delayed action
    const afterRenderDelayedActionRef = useRef(null);
    // runs after each render
    useEffect(() => {
        isInsideRenderRef.current = false;
        if (afterRenderDelayedActionRef.current) {
            const action = afterRenderDelayedActionRef.current;
            afterRenderDelayedActionRef.current = null;
            action();
        }
    })

    const log = (type, to, options) =>
`#navigate${type ? '('+type+')' : ''}:
${to}${options && options.state ? " /" + JSON.stringify(options && options.state) : ""}`;

    const smartNavigate = useCallback((to, options = undefined) => {
        if (setLocalPathAndOptions) {
            console.log(log('local', to, options));
            const action = () => setLocalPathAndOptions({to, options});
            if (isInsideRenderRef.current) {
                afterRenderDelayedActionRef.current = action;
            } else {
                action();
            }
        } else {
            console.log(log('', to, options));
            navigate(to, options);
        }
    }, [setLocalPathAndOptions, isInsideRenderRef, afterRenderDelayedActionRef, navigate]);

    return smartNavigate;
}

export default useSmartNavigate;