import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Col} from 'reactstrap'
import compose from 'lodash/flowRight'

import useSmartNavigate from 'hooks/useSmartNavigate'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import LabelWithInfo from './elements/LabelWithInfo'
import {JSONText} from 'components/fields/JSONTextVal'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'

import ConstellationSolutionView from '../../appEvent/views/solutionViews/ConstellationSolutionView'
import {TabBlock} from '../blocks/TabBlock'
import withLocalNavigateContext from 'hocs/withLocalNavigateContext'
import withUriParams from '../../hocs/withUriParams'

import entityUtils from 'utils/entityUtils'
import {extractPrototypeParameters} from './utils/simulationTaskUtils'

import staticPrototypes from 'constants/_@_staticPrototypes.json'
import {SIMULATION} from 'constants/constants'

export const PrototypeParametersContext = React.createContext(undefined);


const SimulationTaskEditorVal = (props) => {
    const {
        noLabel, wide, name, unit, info, infoImage, disabled, data, field, error,
        prototypeParametersField} = props;
    let value = data[field] ? data[field]: "";

    const {t} = useTranslation();

    const prototypeParameters = extractPrototypeParameters(entityUtils.safeJSONParse(data[prototypeParametersField] || "{}"));

    const [showEditor, setShowEditor] = useState(false);

    const onChange = (value) => {
        props.onChange(data, field, value);
    };

    const onSaveAndClose = (value) => {
        onChange(value);
        setShowEditor(false);
    };

    const contents =
        <>
            {showEditor &&
            <>
                {!error &&
                <SimulationTaskEditorModal title={name} initialJsonString={value} onSave={onSaveAndClose} onCancel={() => setShowEditor(false)}
                                           prototypeParameters={prototypeParameters}
                                           disabled={disabled} />}
                {error &&
                <div>{t('components.fields.SimulationTaskEditorVal.displayError')}{error}</div>}
            </>}

            <div className="flex-grow">
                <JSONText value={value} disabled={disabled} onChange={onChange} error={error} />
            </div>

            <Button className="ms-1" color="light" onClick={() => setShowEditor(true)}>
                <i className="fa fa-cog text-primary" />
            </Button>
        </>;

    if (noLabel) {
        return (
            <div className="form-group row g-0 d-flex align-items-start">
                {contents}
            </div>);
    } else {
        return (
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
                </Col>
                <Col sm={wide ? 10 : 9} className="d-flex align-items-start">
                    {contents}
                </Col>
            </div>);
    }
};


const SimulationTaskEditorModal = compose(
    withLocalNavigateContext,
    withUriParams([
        ['itemType', String, {values: ["simulation", "solution"], defaultValue: "simulation"}]]),
    withGeneratorDataSource)
((props) => {
    const {
        itemType,
        initialJsonString, title, onSave, onCancel, disabled,
        prototypeParameters,
        onChange, onValidate, isChanged, hasErrors, dataSource, setDataSource, saveAndReset} = props;

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const initialJson = useMemo(() => entityUtils.safeJSONParse(initialJsonString), [initialJsonString]);

    useEffect(() =>
        setDataSource(initialJson, undefined),
        [initialJson, setDataSource]);

    const itemTypeUriPart = (itemType && `/${itemType}`) || '';

    return (
        <PanelModal show title={title} onClose={onCancel} scrollable noPadding>
            {!disabled &&
            <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                              onSave={() => {
                                  let data = saveAndReset();
                                  data = entityUtils.filterFields(data, ['simulation', 'solution']);
                                  onSave(JSON.stringify(data))
                              }}
                              onCancel={onCancel} />}

            <TabBlock selectedTabId={itemType} tabs={[
                {name: t('components.fields.SimulationTaskEditorVal.simulation'), id: "simulation", onSelect: () => navigate(`${props.uri}/simulation${props.location.search}`)},
                {name: t('components.fields.SimulationTaskEditorVal.template'), id: "solution", onSelect: () => navigate(`${props.uri}/solution${props.location.search}`)}]} />

            {itemType === "simulation" &&
            <GeneratorBlock className="flex-grow d-flex"
                            data={dataSource}
                            field="simulation"
                            items={staticPrototypes[SIMULATION]}
                            collectionsDict={{spacecrafts: [], orbits: []}}
                            onChange={onChange}
                            onValidate={onValidate}
                            disabled={disabled}/>}

            {itemType === "solution" &&
            <PrototypeParametersContext.Provider value={prototypeParameters}>
                <ConstellationSolutionView dataSource={dataSource}
                                           field="solution"
                                           scenario={{parameters: {simulation: {"max-spacecrafts": 10, "max-stations": 10}}}}
                                           onChange={onChange}
                                           showSolution
                                           disabled={disabled}
                                           uri={`${props.uri}${itemTypeUriPart}`} uriParams={props.uriParams} location={props.location} />
            </PrototypeParametersContext.Provider>}

        </PanelModal>);
});

export default SimulationTaskEditorVal;