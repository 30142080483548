import React from 'react'
import {useTranslation} from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import classnames from 'classnames'

import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

const makeImageOrVideo = (props, t) => {
    let src = props.src || '';
    // remove prefix with slashes or leading slahses
    if (src.startsWith('http:/') || src.startsWith('https:/') || src.startsWith('/')) {
        let indSlash = src.indexOf('/');
        while (src[indSlash] === '/') indSlash++;
        src = src.substring(indSlash);
    }
    if (src.startsWith('youtube.com/') || src.startsWith('youtu.be/')) {
        src = src.substring(src.indexOf('/') + 1);
        src = "https://www.youtube.com/embed/" + src;
        return (
            <div className="ratio ratio-16x9 container">
                <iframe title={t('components.elements.MarkDownOutput.video')}
                        src={src} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
            </div>);
    }
    if (src.startsWith('vk.com/')) {
        // https://vk.com/video-49343931_456239055
        // https://vk.com/video146404193_456239020
        // https://vk.com/video?z=video-219984154_456239073%2Fpl_cat_trends
        // https://vk.com/video?z=video43618728_456323742%2Fpl_cat_trends
        // https://vk.com/video/@sputnixru?z=video-49343931_456239042%2Fclub49343931%2Fpl_-49343931_-2
        // https://vk.com/video/@myvideography?z=video146404193_456239020
        // https://vk.com/video/@video?z=video-146404193_456239020
        // https://vk.com/video/@video?z=video146404193_456239020

        const i1 = src.lastIndexOf('video') + 5;
        const i2 = src.indexOf('%', i1);
        src = i2 >= 0 ? src.substring(i1, i2) : src.substring(i1);
        const ids = src.split('_');
        return (
            <div className="ratio ratio-16x9 container">
                <iframe src={`https://vk.com/video_ext.php?oid=${ids[0]}&id=${ids[1]}&hd=2`}
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                        allowFullScreen>
                </iframe>
            </div>);
    }
    if (src.startsWith('rutube.ru/')) {
        // https://rutube.ru/video/1a9892856360c1b4c99654eb67883b78/
        // https://rutube.ru/video/1a9892856360c1b4c99654eb67883b78/?t=628       
        // https://rutube.ru/video/private/28ea1f6fb7a0a7e208893d4f8829f18c/?p=kC2sx_u-u9xH4TTTF54Ymg
        let i1 = src.indexOf('private/') + 8;
        if (i1 - 8 < 0) {
            i1 = src.indexOf('video/') + 6;
        }
        const i2 = src.indexOf("/", i1);
        src = i2 >= 0 ? src.substring(i1, i2) : src.substring(i1);
        return (
            <div className="ratio ratio-16x9 container">
                <iframe src={`https://rutube.ru/play/embed/${src}`}
                        frameBorder="0" allow="clipboard-write; autoplay"
                        webkitAllowFullScreen mozallowfullscreen allowFullScreen>
                </iframe>
            </div>);
    }
    return <img src={props.src} alt={props.alt} title={props.title} />;
};

export const MarkdownRender = (props) => {
    const {t} = useTranslation();

    const newProps = {
        ...props,
        remarkPlugins: [remarkGfm, remarkMath],
        rehypePlugins: [[rehypeKatex, { strict: false }]],
        components: {
            img: (props) => makeImageOrVideo(props, t),
            table: (props) => (<table className="table table-striped">{props.children}</table>)
        }
    };
    return (
        <ReactMarkdown {...newProps} />
    );
};


export default function MarkdownOutput(props) {
    return (<MarkdownRender className={classnames(props.className, ' markdown-container')}>{props.value}</MarkdownRender>);
};