import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useDebug from 'hooks/useDebug'

import {Alert, Button} from 'reactstrap'
import ListWithSort from 'components/blocks/ListWithSort'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withUriParams from 'hocs/withUriParams'
import withLocationStateParam from 'hocs/withLocationStateParam'
import ConstellationSolutionView from './solutionViews/ConstellationSolutionView'
import SimpleSolutionView from './solutionViews/SimpleSolutionView'

import {querySolutions, querySolution} from '../data/eventQueries'
import {createSolution, updateSolution, deleteSolution, cloneSolution, recalcSolution} from '../data/eventMutations'
import {deleteSolutions} from 'appAdmin/data/adminMutations'

import {breadcrumbsStore} from 'appBase/TopNav'
import {prepareSolutionObject, prepareSolutionObjectForSave} from '../data/eventObjects'
import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {SOLUTIONS_VIEW, FULL_SCREEN} from 'constants/constants'


export const getScenarioSolutionsStats = (scenario, solutions) => {
    const sentSolutionsNumber =
        (solutions && solutions.filter(s => ['recalc', 'running', 'evaluating', 'finished'].includes(s.status)).length) || 0;
    const maxSolutionsNumber = scenario.maxSolutionsNumber;
    const isMaxSentSolutionsNumberReached = maxSolutionsNumber && sentSolutionsNumber >= maxSolutionsNumber;
    return {sentSolutionsNumber, maxSolutionsNumber, isMaxSentSolutionsNumberReached};
};


const SolutionsView = compose(
    withUriParams([
        ['solutionId', Number]]),
    withLocationStateParam(FULL_SCREEN, 'isFullScreen'),
    // pollSolutions,  // will not run if props.loading.solutionsRawData exists
    querySolutions, // will not run if props.solutions exists
    querySolution,
    createSolution,
    updateSolution,
    deleteSolution,
    deleteSolutions,
    cloneSolution,
    recalcSolution,
    withGeneratorDataSource
)(props => {
    useDebug("SolutionsView", props);

    const {
        solutionId, user, solutions, scenario, event, disabled, isEnded, additionalEventStatus,
        additionalButtonsBlockInfo, queryAsRole,
        createSolution, updateSolution, deleteSolution, deleteSolutions, cloneSolution, recalcSolution,
        loading, errors, dataSource, setDataSource,
        // features:
        isFullScreen, enableDeleteTestSolutions, enableBackLink, showBackLink } = props;

    // const [barNavigationComponent, setBarNavigationComponent] = useState(null);

    const [error, setError] = useState(null);

    const navigate = useSmartNavigate();
    const {t} = useTranslation();
    const _t = (value) => t('appEvent.views.SolutionsView.' + value);

    // parse JSON strings (and do a little processing)
    const solution = useMemo(() => prepareSolutionObject(props.solution), [props.solution]);

    // setDataSource: either solution has changed, or dataSource is reset
    useEffect(() => {
        setDataSource(
            solution && solution.parameters,
            (data) =>
                updateSolution(
                    entityUtils.filterFields(
                        prepareSolutionObjectForSave(solution, data),
                        ['id', 'description', 'parameters']),
                    queryAsRole)
                .then(({data: {updateSolution: {solution, errors}}}) =>
                    setError(errors.map(e => parseBackendMessage(e, t)).join('\n'))));
    }, [solution, dataSource, setDataSource, updateSolution, queryAsRole, t]);

    // preparing links

    const containerId = SOLUTIONS_VIEW;
    breadcrumbsStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({
        name: _t('solutions'),
        to: props.uri,
        back: enableBackLink && 1
    });

    useEffect(() => {
        const isTerminal = !solutionId;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminal);
    });

    // subnavigation

    if ((solutionId && !solution && !loading.solution) ||   // no solution was loaded (or not authorized)
        (solution && solution.scenarioId !== scenario.id))  // not matching the scenario
    {
        navigate(`${props.uri}/..${props.location.search}`);
        return null;
    }

    // rendering

    const {
        saveAndReset, onChange, onValidate, isChanged, hasErrors,
        executeWithLock, executeWithConfirmation} = props;

    const selectedIndex = solutions && solutions.indexOf(solutions.find(s => (s.id === solutionId)));

    const {sentSolutionsNumber, maxSolutionsNumber, isMaxSentSolutionsNumberReached} =
        getScenarioSolutionsStats(scenario, solutions);

    const rows = (solutions && solutions.map(s => {
        const row = {
            fields: [<span className={"sim-" + s.status + "-name"}>{s.number}</span>,
                <span>{s.user.name}</span>,
                <span>{s.score}</span>],
            actions: {
                "select": () => navigate(`${props.uri}/${s.id}${props.location.search}`)
            }
        };

        if (!disabled) {
            row.actions.clone = !isMaxSentSolutionsNumberReached &&
                (() => cloneSolution(s.id, queryAsRole)
                    .then(({data: {cloneSolution: {solution, error}}}) => {
                        setError(error ? parseBackendMessage(error, t): null);
                        if (!error) {
                            navigate(`${props.uri}/${solution.id}${props.location.search}`);
                        }
                    }));

            if ((s.user.id === user.id) &&
                (['ready', 'failed'].includes(s.status) || (event && event.catalogEntryVersionId))) {
                row.actions.removeConfirmation = () =>
                    deleteSolution(s.id, queryAsRole)
                        .then(() => {
                            if (s.id === solutionId) {
                                setError(error ? parseBackendMessage(error, t): null);
                                navigate(`${props.uri}${props.location.search}`);
                            }
                        });
            }
        }
        return row;
    })) || [{fields: [_t('loading')], notSelectable: true}];

    if (!disabled && solutions && !isMaxSentSolutionsNumberReached) {
        rows.push({
            fields: [_t('createSolution')],
            className: "text-end",
            notSelectable: true,
            actions: {
                "click": () => createSolution({
                    scenarioId: scenario.id,
                    description: ""
                }, queryAsRole).then(({data: {createSolution: {solution, error}}}) => {
                    setError(error ? parseBackendMessage(error, t): null);
                    if (!error) {
                        navigate(`${props.uri}/${solution.id}${props.location.search}`);
                    }
                })
            }
        });
    }
    if (isMaxSentSolutionsNumberReached) {
        rows.push({
            fields: [t('appEvent.views.SolutionsView.limitReached')],
            className: "text-end",
            notSelectable: true
        });
    }

    const disabledSolutionEdit =
        !solution ||
        disabled ||
        solution.status !== "ready" ||
        solution.user.id !== user.id;

    const additionalActions = [];
    if (solution && solution.user.id === user.id && !isMaxSentSolutionsNumberReached && solution.status === 'ready') {
        const sendSolution =
            () => updateSolution({
                id: solution.id,
                status: "running"
            }, queryAsRole)
            .then(({data: {updateSolution: {errors}}}) =>
                setError(errors.map(e => parseBackendMessage(e, t)).join('\n')));
        // can always withdraw evaluating solutions of type test,
        //  others need confirmation if only one is left
        let sendSolutionWithConfirmation = sendSolution;
        if (!(scenario.isEvaluation && scenario.type === 'test') &&
            (sentSolutionsNumber === maxSolutionsNumber - 1))
        {
            sendSolutionWithConfirmation = () =>
                executeWithConfirmation(
                    sendSolution,
                    scenario.maxSolutionsNumber === 1
                        ? _t('oneSolutionLimit')
                        : _t('maxSolutionsLimit').replace('{maxNumber}', maxSolutionsNumber))
        }
        additionalActions.push({
            name: _t('sendSolution'),
            action: sendSolutionWithConfirmation
        });
    }
    if (solution && solution.user.id === user.id &&
       (['running', 'evaluating'].includes(solution.status) ||
       (event && event.catalogEntryVersionId && solution.status !== "ready"))) {
        additionalActions.push({
            disabled: !(
                // can withdraw running solutions of type sim & constellation
                (solution.status === 'running' && ['sim', 'constellation', 'prog'].includes(scenario.type)) ||
                // can withdraw evaluating solutions of type test
                (solution.status === 'evaluating' && scenario.type === 'test') ||
                // can withdraw failed solutions
                (solution.status === 'failed') ||
                // can withdraw catalog solutions (like courses in materials)
                (event && event.catalogEntryVersionId)),
            name: `${solution.status === "running" ?  _t('stopSolution'): _t('withdrawSolution')}`,
            action: () => executeWithConfirmation(
                () => updateSolution({
                    id: solution.id,
                    status: "ready"
                }, queryAsRole)
                    .then(({data: {updateSolution: {errors}}}) =>
                        setError(errors.map(e => parseBackendMessage(e, t)).join('\n'))),
                `${solution.status === "running" ? _t('stopSolutionQuestion'): _t('withdrawSolutionQuestion')}`)
        });
    }
    // recalculate?
    // can recalculate solutions without simulation
    if (solution && solution.user.id === user.id && ['sim', 'constellation', 'prog'].includes(scenario.type) &&
        ['finished', 'failed', 'evaluating'].includes(solution.status) && !solution.simulation)
    {
        additionalActions.push({
            name: _t('recalcSolution'),
            action: () => executeWithConfirmation(
                () => recalcSolution(solution.id, queryAsRole)
                        .then(({data: {recalcSolution: {solution, error}}}) =>
                            setError(error ? parseBackendMessage(error, t): null)),
                _t('recalcSolutionQuestion'))
        });
    }

    let progress = "";
    if (solution && solution.status === "running" && solution.resultNumber && solution.simulation && solution.simulation.resultTotal) {
        progress = (solution.resultNumber / solution.simulation.resultTotal) * 100;
        progress = Math.round(progress * 10) / 10;
        progress = "" + progress + "%";
    }

    return (
        <div className={!isFullScreen ? "flex-grow d-flex flex-row scroll-parent" : "flex-grow d-flex scroll-parent"}>
            {!isFullScreen &&
            <div className="w-25 d-flex flex-column scroll border border-dark border-start-0 border-top-0 border-bottom-0">
                <h5 className="mt-1 ms-4">{_t('solutions')}{additionalEventStatus}
                    {enableDeleteTestSolutions && solutions && solutions.length > 0 &&
                        <Button className="ms-2 smallbutton" color="danger" onClick={() => {
                            const isTest = true;
                            executeWithConfirmation(() => deleteSolutions(null, scenario.id, isTest)
                                .then(({data: {deleteSolutions: {error}}}) => setError(error ? parseBackendMessage(error, t): null)))
                        }}>{t('appAdmin.views.LicenseScenarioView.deleteTestSolutions')}</Button>}
                </h5>
                <ListWithSort rows={rows} headers={["", _t('student'), _t('grade')]} sizes={[70, "1*", 55]}
                              selectedIndex={selectedIndex} sortable
                              executeWithLock={executeWithLock} />
            </div>}
            <div className={`d-flex flex-column ${!isFullScreen ? 'w-75' : 'w-100'} scroll-parent`}>
                <SaveButtonsBlock withBack={showBackLink || isFullScreen}
                                  isChanged={isChanged}
                                  hasErrors={hasErrors}
                                  onSave={!disabled && (() => saveAndReset())}
                                  onCancel={!disabled && (() => setDataSource(null))}
                                  actions={additionalActions}
                                  additionalInfo={additionalButtonsBlockInfo}
                                  executeWithLock={executeWithLock} />
                {isEnded &&
                <Alert className="mb-0" color="danger">{_t('isEnded')}</Alert>}
                {((errors && errors.solution) || error) &&
                <Alert className="mb-0" color="danger">{errors.solution || error}</Alert>}
                <div className="flex-grow d-flex flex-column scroll">
                    {loading.solution && !dataSource && _t('loading')}
                    {scenario.type === 'constellation' && dataSource &&
                    <ConstellationSolutionView disabled={disabledSolutionEdit}
                                               dataSource={dataSource}  // parameters
                                               scenario={scenario}
                                               solution={solution}
                                               queryAsRole={queryAsRole}
                                               showSolution showResults
                                               onChange={onChange} onValidate={onValidate}
                                               progress={progress}
                                               loading={loading} errors={errors}
                                               uri={`${props.uri}/${solutionId}`} uriParams={props.uriParams} location={props.location} />}
                    {scenario.type !== 'constellation' && dataSource &&
                    <SimpleSolutionView disabled={disabledSolutionEdit}
                                        dataSource={dataSource}         // parameters
                                        scenario={scenario}
                                        solution={solution}
                                        queryAsRole={queryAsRole}
                                        showSolution showResults
                                        onChange={onChange} onValidate={onValidate}
                                        progress={progress}
                                        loading={loading} errors={errors}
                                        uri={`${props.uri}/${solutionId}`} uriParams={props.uriParams} location={props.location} />}
                </div>
            </div>
        </div>);
});

export default SolutionsView;
