// import * as THREE from 'three';
// import OrientationVal from 'components/fields/OrientationVal'

import prototypeUtils from "utils/prototypeUtils";

import staticPrototypes from 'constants/_@_staticPrototypes';


export default function calculateExpression(expression, field, data, fieldsInfo, collectionsDict) {
    function getRelativeField(expression) {
        let currentField = field;
        if (expression.startsWith('../')) {
            while (expression.startsWith('../')) {
                currentField = currentField.substring(0, currentField.lastIndexOf('.'));
                expression = expression.substring(3);
            }
        } else if (expression.startsWith('./')) {
            expression = expression.substring(2);
        }
        return currentField + (currentField && expression ? "." : "") + expression;
    }

    function getRelativeValue(expression) {
        const currentField = getRelativeField(expression);
        return prototypeUtils.get(data, currentField, fieldsInfo, collectionsDict);
    }


    if (expression.startsWith('../') || expression.startsWith('./')) {
        return getRelativeValue(expression);
    }

    if (expression.startsWith('/')) {
        return prototypeUtils.get(data, expression.substring(1), fieldsInfo, collectionsDict);
    }

    if (['spacecrafts', 'stations', 'orbits', 'resultsData'].some(col => expression.startsWith(col + '['))) {
        const lbrInd = expression.indexOf('[');
        const rbrInd = expression.indexOf(']');
        const colName = expression.substring(0, lbrInd);
        const idExpr = expression.substring(lbrInd + 1, rbrInd);
        const colExpr = '/' + expression.substring(rbrInd + 2); // skipping '].'

        const collection = collectionsDict[colName];
        let item;
        if (idExpr) {
            const itemLocalId = calculateExpression(idExpr, field, data, fieldsInfo, collectionsDict);
            item = collection.find(s => s.localId === itemLocalId);
        } else {
            item = collection;
        }

        let value;
        if (colExpr > '/') {
            const protoName = {
                "spacecrafts": "spacecraft",
                "stations": "station",
                "orbits": "orbit"
            }[colName];
            const itemFieldsInfo = prototypeUtils.getFieldsInfo(staticPrototypes[protoName]);
            value = calculateExpression(colExpr, "", item, itemFieldsInfo, collectionsDict);
        } else {
            value = item;
        }
        return value;
    }

    let r, m, x;
    switch (expression) {
        case "cubicalInertiaTensorEdge":
            r = getRelativeValue("../../../radius");
            return 2*r;
        case "sphericalInertiaTensor":
            r = getRelativeValue('../radius');
            m = getRelativeValue('../../../mass');
            x = (2.0/5.0)*m*r*r;
            return [[x,0,0],[0,x,0],[0,0,x]];
        case "cubicalInertiaTensor":
            r = getRelativeValue('../edge');
            m = getRelativeValue('../../../mass');
            x = (1.0/6.0)*m*r*r;
            return [[x,0,0],[0,x,0],[0,0,x]];
        case "currentDate":
            const dt = new Date();
            return `${dt.getFullYear()}-${dt.getMonth()+1}-${dt.getDate()}T00:00:00`;
        case "spacecraftMeanAnomaly":
            const parts = field.split('.');
            const spacecraftsPartInd = parts.findIndex(x => x === 'spacecrafts');
            if (spacecraftsPartInd >= 0 && spacecraftsPartInd < parts.length - 1) {
                const idPart = parts[spacecraftsPartInd + 1];
                if (idPart.startsWith('id:')) {
                    const getSimSpacecraftOrbitId = (sId) =>
                        prototypeUtils.get(data, `parameters.environment.near-earth.spacecrafts.id:${sId}.orbit.orbit.orbitId`, fieldsInfo, collectionsDict) ||
                        prototypeUtils.get(data, `parameters.environment.near-earth.spacecrafts.id:${sId}.orbit.override.type.orbit.orbitId`, fieldsInfo, collectionsDict);
                    const spacecraftLocalId = Number(idPart.substring(idPart.lastIndexOf(":") + 1));
                    const simSpacecrafts = prototypeUtils.get(data, "parameters.environment.near-earth.spacecrafts", fieldsInfo, collectionsDict) || [];
                    const simSpacecraft = simSpacecrafts.find(s => s.localId === spacecraftLocalId);
                    if (simSpacecraft) {
                        const simSpacecraftOrbitId = getSimSpacecraftOrbitId(spacecraftLocalId);
                        if (simSpacecraftOrbitId) {
                            const simSpacecraftsIds = simSpacecrafts.map(s => s.localId);
                            const sIdsOnOrbit = simSpacecraftsIds.filter(sId => getSimSpacecraftOrbitId(sId) === simSpacecraftOrbitId);
                            const spacecraftInd = sIdsOnOrbit.findIndex(sId => sId === spacecraftLocalId);
                            return sIdsOnOrbit.length > 0 ? 360 * spacecraftInd / sIdsOnOrbit.length : 0;
                        }
                        return 0;
                    }
                }
            }
            console.warn(`expressions: spacecraftMeanAnomaly for bad field ${field}!`);
            return 0;
        case "deviceOrientations":
            // // rootField:
            // const rootField = field.startsWith('devices') ? field.substring(0, field.indexOf('.', field.indexOf('.') + 1) + 1) : '';
            // // processing:
            // const functions = data[`${rootField}functions`] || [];
            // const deviceOrientation =
            //     OrientationVal.getQuaternionFromQuaternionArray(
            //         prototypeUtils.get(data, `${rootField}orientation`, fieldsInfo, collectionsDict));
            // const functionsDirections = functions
            //     .map(f => ({
            //         name: f.$name,
            //         localId: f.localId,
            //         direction: prototypeUtils.get(data, `${rootField}functions.id:${f.localId}.type.${f.type && f.type._type}.direction`, fieldsInfo, collectionsDict)
            //     }))
            //     .filter(f => f.direction)
            //     .map(f => ({
            //         name: f.name,
            //         direction: (new THREE.Vector3(f.direction[0], f.direction[1], f.direction[2])).normalize(),
            //         isMain: field.startsWith(`${rootField}functions.id:${f.localId}`)
            //     }));
            // return {
            //     orientation: deviceOrientation,
            //     directions: functionsDirections
            // };
            return {
                orientation: null,
                directions: null
            };
        default:
            console.warn(`Unknown expression ${expression}!`);
            return null;
    }
}