import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useDebug from 'hooks/useDebug'

import TopNav, {breadcrumbsStore, linksStore} from 'appBase/TopNav'
import withConfirmation from 'hocs/withConfirmation'
import withUriParams from 'hocs/withUriParams'
import NamedBlock from 'components/blocks/NamedBlock'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import EventView from './views/EventView'
import EventCard from './views/components/EventCard'
import entityUtils from "utils/entityUtils";

import {queryEvents} from './data/eventQueries'


const EventApp = compose(
    withUriParams([
        ['eventId', String]]),
    queryEvents,
    withConfirmation,
    withInitialDataLoadWaiting(['events'])
)((props) => {
    useDebug("EventApp", props);

    const {eventId, loading} = props;
    const {t} = useTranslation();

    // preparing links

    // initial breadcrumbs
    const containerId = null;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({
        name: t('appEvent.EventApp.allEvents'),
        to: props.uri,
        back: 1,
        nonBlocking: true });

    useEffect(() => {
        const isTerminalBreadcrumbs = !eventId;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
        linksStore.set(containerId, [], isTerminalBreadcrumbs);
    });

    // parsing JSON

    const events = useMemo(() =>
        props.events && props.events.map((event) => ({
            ...event,
            competitionRules: entityUtils.safeJSONParse(event.competitionRules)
        })), [props.events]);

    let blocks = [];

    if (!eventId) {
        const eventsByLicenseId = {};
        events.forEach(e => {
            eventsByLicenseId[e.license.id] = eventsByLicenseId[e.license.id] || [];
            eventsByLicenseId[e.license.id].push(e);
        });

        blocks = Object.values(eventsByLicenseId)
            .sort(entityUtils.sortByFieldDesc('number', block => block[0].license))
            .sort(entityUtils.sortByFieldDesc('priority', block => block[0].license))
            .map((block , i) => {
                const rows = [];

                let cardIndex = 0;
                const isOddCardsNumber =  block.length % 2 !== 0;

                for (let j = 0; j < Math.ceil(block.length / 2); j++) {
                    rows.push(
                        <div className="row mb-4" key={j}>
                            <EventCard event={block[cardIndex]} uri={props.uri} isLarge={isOddCardsNumber && cardIndex === 0} />

                            {block[cardIndex + 1] && (!isOddCardsNumber || cardIndex !== 0) &&
                            <EventCard event={block[cardIndex + 1]} uri={props.uri} />}
                        </div>
                    );
                    cardIndex += (isOddCardsNumber && cardIndex === 0) ? 1 : 2;
                }
                return (
                    <NamedBlock key={i} name={block[0].license.organizerName} nameClassName="mb-2">
                        {rows}
                    </NamedBlock>
                );
            });
    }

    return (
        <div className="d-flex flex-column h-100 w-100">
            <TopNav user={props.user} skipRootPath={props.uri} executeWithLock={props.executeWithLock} />

            {!eventId &&
            <div className="flex-grow d-flex flex-column scroll-parent">
                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appEvent.EventApp.events')}</h5>
                        <div className="container">
                            {loading.events && t('appEvent.EventApp.loading')}
                            {!loading.events && events.length === 0 && t('appEvent.EventApp.noAccess')}

                            {blocks}

                        </div>
                    </div>
                </div>
            </div>}

            {eventId && /* eventId is either normal event.id or event.link_uuid */
            <EventView eventId={eventId}
                       showBackLink
                       user={props.user} errors={props.errors} loading={props.loading} refetch={props.refetch}
                       setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                       resetActionLock={props.resetActionLock}
                       executeWithLock={props.executeWithLock}
                       executeWithConfirmation={props.executeWithConfirmation}
                       uri={`${props.uri}/${eventId}`} uriParams={props.uriParams} location={props.location} />}
        </div>
    );
});

export default EventApp;