import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Alert} from 'reactstrap'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useDebug from 'hooks/useDebug'

import {TabBlock} from 'components/blocks/TabBlock'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import LicenseScenarioView from 'appAdmin/views/LicenseScenarioView'
import AdminEventsView from 'appAdmin/views/AdminEventsView'

import {queryCatalogEntryWithVersions} from '../data/catalogQueries'
import {updateCatalogEntry, updateCatalogEntryVersion, cloneCatalogEntryVersion} from '../data/catalogMutations'

import {breadcrumbsStore} from 'appBase/TopNav'
import entityUtils from 'utils/entityUtils'
import dateUtils from 'utils/dateUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import catalogPrototypes from '../data/catalogPrototypes'
import {CATALOG_VIEW} from 'constants/constants'
import useSelectVersion from '../hooks/useSelectVersion'

const catalogEntryCourseProto = [
    ...catalogPrototypes.catalogEntry,
    ...catalogPrototypes.catalogEntryCoursePart];
const catalogEntryScenarioProto = [
    ...catalogPrototypes.catalogEntry,
    ...catalogPrototypes.catalogEntryScenarioPart];

const CatalogEntryView = compose(
    withUriParams([
        ['versionId', Number, {optional: true}],
        ['mode', String, {optional: true, values: ['edit']}],
        ['itemType', String, {optional: true, values: ['version', 'scenario', 'event']}]]),
    queryCatalogEntryWithVersions,
    // queryCatalogEntryVersionSolutions,
    updateCatalogEntry,
    updateCatalogEntryVersion, cloneCatalogEntryVersion,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['catalogEntryWithVersions'])
)(props => {

    useDebug('CatalogEntryView', props);

    const { versionId, mode, itemType,
        catalogEntryId, catalogEntryWithVersions,
        dataSource, setDataSource, updateCatalogEntry, updateCatalogEntryVersion,
        loading, errors } = props;

    const versionIdUriPart = (versionId && `/${versionId}`) || '';
    const modeUriPart = (mode && `/${mode}`) || '';
    const itemTypeUriPart = (itemType && `/${itemType}`) || '';
    const uriParamsUriPart = (props.uriParams && `/${props.uriParams}`) || '';

    const catalogEntryPrototype =
        catalogEntryWithVersions.type === "course" ?
            catalogEntryCourseProto:
            catalogEntryScenarioProto;

    const {t, i18n} = useTranslation();
    const [error, setError] = useState(null);
    const navigate = useSmartNavigate();

    // make a copy to change locally. Also allows us to setDataSource, because it is a stable object and dataSource would not change.
    const currentVersion = useMemo(() => {
        return catalogEntryWithVersions.catalogEntryVersions.find(v => v.id === versionId);
    },
    [catalogEntryWithVersions, versionId]);

    const {selectVersionDropdownAction, selectedVersionName} = useSelectVersion(
        catalogEntryWithVersions.catalogEntryVersions,
        currentVersion,
        useCallback((versionId) =>
                `${props.uri}/${versionId}${itemTypeUriPart}${uriParamsUriPart}${props.location.search}`,
            [props.uri, itemTypeUriPart, uriParamsUriPart, props.location.search]));

    const unpublishedVersion = catalogEntryWithVersions.catalogEntryVersions.find(v => !v.publishedAt);
    const lastVersion = catalogEntryWithVersions.catalogEntryVersions[catalogEntryWithVersions.catalogEntryVersions.length - 1];

    useEffect(() => {
        if (!currentVersion) {
            setDataSource(
                // stable object, returned directly from query
                catalogEntryWithVersions,
                (data) =>
                    updateCatalogEntry(entityUtils.filterFields(
                        data, ['id', 'catalogId', 'isArchived', 'nameMl', 'visibilityType'])));
        } else {
            // changing the local copy of object, it will be deep-copied when creating DataSource
            // thanks to stable local copy of object, dataSource would not be recreated again (it is linked to stable local copy of object)
            setDataSource(
                currentVersion,
                (data) =>
                    // top-level filtering
                    updateCatalogEntryVersion(entityUtils.filterFields(
                        data, ['id', 'nameMl', 'descriptionMl', 'comments']))
                        .then(({data: {updateCatalogEntryVersion: {catalogEntryVersion, error}}}) => {
                                setError(error ? parseBackendMessage(error, t): null);
                            }));
        }
    }, [catalogEntryWithVersions, versionId, currentVersion, t,
        setDataSource, updateCatalogEntry, updateCatalogEntryVersion]);

    // stable object for query override for AdminEventsView
    let licenseEventsDataOverride = useMemo(() => currentVersion && [currentVersion.event], [currentVersion]);

    // links

    const containerId = CATALOG_VIEW;
    breadcrumbsStore.register(containerId);

    useEffect(() => {
        const breadcrumbs = [];
        if (catalogEntryWithVersions) {
            breadcrumbs.push({name: t('appCatalog.section') + catalogEntryWithVersions.name, to: `${props.uri}${props.location.search}`});
        }
        if (versionId) {
            breadcrumbs.push({name: selectedVersionName, to: `${props.uri}/${versionId}/version${props.location.search}`, back: 1});
        }
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
    }, [catalogEntryWithVersions, versionId, itemType, props.uri, props.uriParams, containerId, t]);

    // subnavigation

    if (catalogEntryId && !catalogEntryWithVersions && !loading.catalogEntryWithVersions) {
        navigate(`${props.uri}/..${props.location.search}`);
        return null;
    } else if (versionId && !currentVersion) {
        navigate(`${props.uri}${props.location.search}`);
        return null;
    } else if (versionId && currentVersion && !itemType) {
        navigate(`${props.uri}/${versionId}/version${props.location.search}`);
        return null;
    }

    // rendering

    const {
        saveAndReset, onChange, onValidate, isChanged, hasErrors,
        executeWithLock, executeWithConfirmation } = props;

    const navigateWithLock = (to) => executeWithLock(() => navigate(to));

    let actions;
    let editConfirmationMessage;
    const rows = [];
    if (!currentVersion) {
        //no version selected
        actions = [
            mode !== 'edit' && !unpublishedVersion && {
                name: t('appCatalog.views.CatalogEntryView.createNew'),
                action: () => executeWithLock(() => executeWithConfirmation(() =>
                    props.cloneCatalogEntryVersion(lastVersion.id)
                        .then(({data: {cloneCatalogEntryVersion: {id, error}}}) => {
                                setError(error ? parseBackendMessage(error, t): null);
                            })))},
            mode !== 'edit' && {
                name: t('appCatalog.edit'),
                allowOnErrors: true,
                action: () => navigateWithLock(`${props.uri}/edit${props.location.search}`) }
        ];

        const cards = [catalogEntryWithVersions.catalogEntryVersions.map((v, i) => {
            const versionName = ((!v.publishedAt && t('appCatalog.hooks.useSelectVersionDropdownAction.notPublishedVersion')) ||
                    t('appCatalog.hooks.useSelectVersionDropdownAction.publishedVersion'))
                    .replace('{number}', i + 1)
                    .replace('{name}', v.name || dateUtils.dateToLocaleDateString(v.publishedAt, i18n));

            return (
                <NamedBlock key={i} name={versionName}
                        onClick={() => navigateWithLock(`${props.uri}/${v.id}/version${props.location.search}`)}
                        className={"col-10 col-md-5 p-3 m-4"}>
                    <span>{v.description}</span>
                </NamedBlock>);
        })];

        for (let i = 0; i < Math.ceil(cards.length / 2); i++) {
            rows[i] = <div className="row" key={i}>
                {cards[2 * i]}
                {cards[2 * i + 1]}
            </div>;
        }

    } else if (currentVersion && !currentVersion.publishedAt) {
        //current version is not published, we can edit or publish
        actions = [
            mode !== 'edit' && {
                name: t('appCatalog.views.CatalogEntryView.publish'),
                action: () => executeWithLock(() => executeWithConfirmation(() =>
                    props.updateCatalogEntryVersion({ id: dataSource.id, shouldPublish: true })
                        .then(({data: {updateCatalogEntryVersion: {catalogEntryVersion, error}}}) => {
                                setError(error ? parseBackendMessage(error, t): null);
                            }))) },
            mode !== 'edit' && itemType === 'version' && {
                name: t('appCatalog.edit'),
                allowOnErrors: true,
                action: () => navigateWithLock(`${props.uri}/${versionId}/edit/${itemType}${props.location.search}` )}
        ];
    } else if (currentVersion && currentVersion.publishedAt) {
        //current version is published
        editConfirmationMessage = <span>{t('appCatalog.views.CatalogEntryView.alreadyPublished')}<br />{t('appCatalog.views.CatalogEntryView.fixErrors')}</span>;
        actions = [
            mode !== 'edit' && !unpublishedVersion && {
                name: t('appCatalog.views.CatalogEntryView.copyVersion'),
                action: () => executeWithLock(() => executeWithConfirmation(() =>
                    props.cloneCatalogEntryVersion(dataSource.id)
                        .then(({data: {cloneCatalogEntryVersion: {id, error}}}) => {
                                setError(error ? parseBackendMessage(error, t): null);
                            })))},
            mode !== 'edit' && itemType === 'version' && {
                name: t('appCatalog.edit'),
                allowOnErrors: true,
                action: () => executeWithConfirmation(
                    () => navigateWithLock(`${props.uri}/${versionId}/edit/${itemType}${props.location.search}`),
                    editConfirmationMessage )}
        ];
    }

    actions = [...actions, selectVersionDropdownAction];

    return (
        <>
        {error &&
        <Alert className="mb-0" color="danger">{error}</Alert>}

        {(!versionId || itemType === 'version') &&
        // common saveButtonsBlock for entry (!versionId) and version (itemType==version)
        <SaveButtonsBlock withEditMode
                          withBack={itemType === 'version'}
                          executeWithLock={props.executeWithLock}
                          isChanged={isChanged}
                          hasErrors={hasErrors}
                          onSave={mode === 'edit' && (() => {
                              saveAndReset();
                              navigate(`${props.uri}${versionIdUriPart}${itemTypeUriPart}${props.location.search}`);
                          })}
                          onCancel={mode === 'edit' && (() => {
                              setDataSource(null);
                              navigate(`${props.uri}${versionIdUriPart}${itemTypeUriPart}${props.location.search}`);
                          })}
                          actions={actions} />}

        {!versionId &&
        // entry properties (!versionId)
        <>
            {dataSource &&
            <div className="workpanel-gray flex-grow scroll">
                <GeneratorBlock data={dataSource}
                                items={catalogEntryPrototype}
                                onChange={onChange} onValidate={onValidate}
                                collectionsDict={{}}
                                disabled={mode !== 'edit'} />
                <h5 className="mt-3">
                    {t('appCatalog.views.CatalogEntryView.versions')}
                </h5>
                <div className="container-fluid">{rows}</div>
            </div>}
        </>}

        {versionId &&
        // version properties
        <>
            {itemType === 'version' &&
            <>
                <TabBlock selectedTabId={itemType} tabs={[
                    {name: t('appCatalog.views.CatalogEntryView.versionProperties'),
                        id: "version", onSelect: () => navigateWithLock(`${props.uri}/${versionId}${modeUriPart}/version${props.location.search}`)},
                    currentVersion.event && {name: t('appCatalog.views.CatalogEntryView.eventProperties'),
                        id: "event", onSelect: () => navigateWithLock(`${props.uri}/${versionId}${modeUriPart}/event/event${props.location.search}`)},
                    currentVersion.scenario && {name: t('appCatalog.views.CatalogEntryView.scenarioProperties'),
                        id: "scenario", onSelect: () => navigateWithLock(`${props.uri}/${versionId}${modeUriPart}/scenario/scenario${props.location.search}`)}
                ]} />

                {dataSource &&
                <div className="flex-grow workpanel-gray scroll">
                    <GeneratorBlock data={dataSource}
                                    items={catalogPrototypes.catalogEntryVersion}
                                    onChange={onChange} onValidate={onValidate}
                                    collectionsDict={{}}
                                    disabled={mode !== 'edit'} />
                </div>}
            </>}

            {itemType === 'event' && currentVersion.event &&
            <AdminEventsView licenseId={null}    // no license queries
                             licenseEventsWithScenarios={licenseEventsDataOverride}    // override data instead of query
                             user={props.user}
                             enableScenarioTreeEditButton
                             enableCatalogPrototypes
                             enableBackLink     // return back to catalog version properties
                             showBackLink       // show back link
                             editConfirmationMessage={editConfirmationMessage}
                             loading={props.loading} errors={props.errors}
                             setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                             resetActionLock={props.resetActionLock}
                             executeWithLock={props.executeWithLock}
                             executeWithConfirmation={props.executeWithConfirmation}
                             uri={`${props.uri}/${versionId}/${itemType}`} uriParams={`${props.uriParams}`} location={props.location} />}

            {itemType === 'scenario' && currentVersion.scenario &&
            <LicenseScenarioView scenarioId={currentVersion.scenario.id}
                                 licenseId={null}    // add images from catalog
                                 eventType="competition"
                                 user={props.user}
                                 enableCatalogPrototypes
                                 enableBackLink     // return back to catalog version properties
                                 showBackLink       // show back link
                                 editConfirmationMessage={editConfirmationMessage}
                                 loading={loading} errors={errors}
                                 setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                 resetActionLock={props.resetActionLock}
                                 executeWithLock={props.executeWithLock}
                                 executeWithConfirmation={props.executeWithConfirmation}
                                 uri={`${props.uri}/${versionId}/${itemType}`} uriParams={props.uriParams} location={props.location} />}
        </>}

        </>);
});

export default CatalogEntryView;