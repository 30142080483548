import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Button} from 'reactstrap'
import useSmartNavigate from 'hooks/useSmartNavigate'

import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import Messages from 'components/elements/Messages'
import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryLicenseEvent, queryLicenseEvents, queryStudentGroups, queryStudentGroupAssignments, queryStudentTeams, queryStudentTeamAssignments} from '../../data/adminQueries'
import {updateEvent} from '../../data/adminMutations'

import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {breadcrumbsStore} from 'appBase/TopNav'
import {ADMIN_STUDENTS_VIEW, EVENT} from 'constants/constants'

const EventStudentsView = compose(
    withUriParams([['mode', String, {optional: true, values: ['edit']}]]),
    queryLicenseEvent, queryLicenseEvents, queryStudentGroups, queryStudentGroupAssignments, queryStudentTeams, queryStudentTeamAssignments,
    updateEvent,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['licenseEvent', 'licenseEvents', 'studentGroups', 'studentGroupAssignments', 'studentTeams', 'studentTeamAssignments'], true))
((props) => {

    const {eventId, licenseEvent, disabled, studentGroups, studentGroupAssignments, studentTeamAssignments, updateEvent, mode, dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate, uri, location} = props;
    const [errorMessages, setErrorMessages] = useState({});

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const licenseEvents = props.licenseEvents.map(e => ({
        fields: [e.name, e.isPublished ? t('appAdmin.views.studentViews.EventStudentsView.yes'): t('appAdmin.views.studentViews.EventStudentsView.no')],
        actions: {
            "select": () => {
                navigate(`${uri}/${e.id}${props.location.search}`);
            },
        }
    }));

    const studentIds = useMemo(() => (licenseEvent &&
          studentGroupAssignments.filter(a => a.studentGroup.id === licenseEvent.studentGroupId)
                                 .map(a => a.student.id)
    ), [licenseEvent, studentGroupAssignments]);

    const testerIds = useMemo(() => (licenseEvent &&
          studentGroupAssignments.filter(a => a.studentGroup.id === licenseEvent.testerGroupId)
                                 .map(a => a.student.id)
    ), [licenseEvent, studentGroupAssignments]);

    const studentTeams = useMemo(() => {
        let numberOfStudentsInTeams = 0;

        const teams = props.studentTeams
            .map(t => {
                const studentsInTeam = studentTeamAssignments.filter(a => a.studentTeam.id === t.id && studentIds && studentIds.includes(a.student.id));
                numberOfStudentsInTeams +=  studentsInTeam.length;
                return {
                    fields: [t.name, studentsInTeam.length],
                    actions: {
                      "select": () => {
                          navigate(`${uri}/${eventId}/team/${t.id}${location.search}`);
                       },
                    }
                };
            });
        teams.push({
            fields: [t('appAdmin.views.studentViews.EventStudentsView.notInTeams'), studentIds && studentIds.length - numberOfStudentsInTeams],
            notSelectable: true,
        });
        return teams;
    }, [eventId, props.studentTeams, studentTeamAssignments, t, location, navigate, uri, studentIds]);

    //links
    const containerId = ADMIN_STUDENTS_VIEW + "child";
    breadcrumbsStore.register(containerId);

    const links = [{name: t('appAdmin.views.studentViews.EventStudentsView.eventsAndTeams'), to: `${uri}${location.search}`}];
    if (licenseEvent) {
        links.push({name: licenseEvent.isArchived ? t('appAdmin.views.studentViews.EventStudentsView.archive') + licenseEvent.name: licenseEvent.name, to: `${uri}/${eventId}${props.location.search}`});
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });


    // setting dataSource
    useEffect(() => {
      setDataSource(
          licenseEvent,
          (data) => {
             data = entityUtils.filterFields(data, ['id', 'nameMl', 'startsAt', 'endsAt', 'isArchived', 'isPublished', 'studentGroupId', 'testerGroupId', 'teamMembersLimit']);
                updateEvent(data)
                  .then(({data: {updateEvent: {licenseEvent, error}}}) => {
                      setErrorMessages({...errorMessages, [EVENT]: error ? parseBackendMessage(error, t): null });
                      navigate(`${uri}/${licenseEvent ? licenseEvent.id: ""}${location.search}`);
                  });
          });
    }, [licenseEvent, dataSource, setDataSource, t, updateEvent, setErrorMessages, errorMessages, uri, location]);


    const onCancelEdit = () => {
        setDataSource(null);
        navigate(`${uri}/${eventId}${location.search}`);
    };

    return (<>
        <Messages errors={{...errorMessages, ...props.errors}} />
        <div className="scroll flex-grow d-flex flex-column workpanel-gray">
            {!eventId &&
            <NamedBlock name={t('appAdmin.views.studentViews.EventStudentsView.allEventsList')} className="bg-light">
                <ListWithSort headers={[t('appAdmin.views.studentViews.EventStudentsView.eventName'), t('appAdmin.views.studentViews.EventStudentsView.published')]}
                              sizes={["50*", "280*"]} rows={licenseEvents} disabled={disabled} />
            </NamedBlock>}

           {licenseEvent &&
            <div>
                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                                  onSave={saveAndReset} onCancel={onCancelEdit} />
                <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                items={[{
                                   name: t('appAdmin.views.studentViews.EventStudentsView.eventName2'),
                                   type: "string",
                                   field: "name",
                                   disabled: !mode
                               },
                               {
                                   name: t('appAdmin.views.studentViews.EventStudentsView.startDate'),
                                   type: "date",
                                   field: "startsAt",
                                   disabled: !mode
                               },
                               {
                                   name: t('appAdmin.views.studentViews.EventStudentsView.endDate'),
                                   type: "date",
                                   field: "endsAt",
                                   disabled: !mode
                               },
                               {
                                   name: t('appAdmin.views.studentViews.EventStudentsView.isArchived'),
                                   type: "enum",
                                   field: "isArchived",
                                   stuff: [
                                       {"value": true, "name": t('appAdmin.views.studentViews.EventStudentsView.yes')},
                                       {"value": false, "name": t('appAdmin.views.studentViews.EventStudentsView.no')}
                                   ],
                                   disabled: !mode
                               },
                               {
                                   name: t('appAdmin.views.studentViews.EventStudentsView.isPublished'),
                                   type: "enum",
                                   field: "isPublished",
                                   stuff: [
                                       {"value": true, "name": t('appAdmin.views.studentViews.EventStudentsView.yes')},
                                       {"value": false, "name": t('appAdmin.views.studentViews.EventStudentsView.no')}
                                   ],
                                   disabled: !mode
                               },
                               {
                                   name: t('appAdmin.views.studentViews.EventStudentsView.teamMembersLimit'),
                                   type: "int",
                                   field: "teamMembersLimit",
                                   disabled: !mode
                               },
                               {
                                   type: "select",
                                   nullOption: t('appAdmin.views.studentViews.EventStudentsView.notSelected'),
                                   name: t('appAdmin.views.studentViews.EventStudentsView.studentGroup'),
                                   field: "studentGroupId",
                                   options: studentGroups.map(g => ({name: g.name, value: g.id})),
                                   disabled: !mode
                               },
                               {
                                   type: "select",
                                   nullOption: t('appAdmin.views.studentViews.EventStudentsView.notSelected'),
                                   name: t('appAdmin.views.studentViews.EventStudentsView.testerGroup'),
                                   field: "testerGroupId",
                                   options: studentGroups.map(g => ({name: g.name, value: g.id})),
                                   disabled: !mode
                               }]} />
                <Button color="primary" onClick={() => {
                    navigate(`${uri}/${eventId}/edit${location.search}`)}} disabled={disabled || mode}>{t('appAdmin.views.studentViews.EventStudentsView.edit')}</Button>

                <p> {t('appAdmin.views.studentViews.EventStudentsView.numberOfStudents')} {studentIds && studentIds.length} </p>
                <p> {t('appAdmin.views.studentViews.EventStudentsView.numberOfTesters')} {testerIds && testerIds.length} </p>
                <NamedBlock name={t('appAdmin.views.studentViews.EventStudentsView.studentsDistribution')} className="bg-light">
                    <ListWithSort headers={[t('appAdmin.views.studentViews.EventStudentsView.teams'),
                                            t('appAdmin.views.studentViews.EventStudentsView.studentsNumber')]} sizes={["80*", "20*"]} rows={studentTeams} disabled={disabled} />
                </NamedBlock>
            </div>}
        </div>
    </>);
});

export default EventStudentsView;