import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Nav, NavItem, NavLink} from 'reactstrap'
import Dotdotdot from 'react-dotdotdot'
import ResizeAware from 'react-resize-aware'
import classnames from 'classnames'

import SimulationFieldSelector from './elements/SimulationFieldSelector'
import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'

import userDefinedGearsIcon from 'icons/user-defined-gears.svg'
import trueAnomalyIcon from 'icons/True_Anomaly.png'
import heattransferMLIIcon from 'icons/heat-transfer-preset-MLI.jpg'
import heatTransferSolarpanelsIcon from 'icons/heat-transfer-preset-solarpanels.jpg'
import heatTransferWhiteIcon from 'icons/heat-transfer-preset-white.jpg'
import orbitHubbleIcon from 'icons/Orbit-hubble.png'
import orbitEnvisatIcon from 'icons/Orbit-envisat.png'
import orbitMKSIcon from 'icons/Orbit-mks.png'
import orbitEchostarXVIIIcon from 'icons/Orbit-echostar-xvii.png'


const images = {
    "user-defined-gears": userDefinedGearsIcon,
    "true-anomaly": trueAnomalyIcon,
    "heat-transfer-preset-mli": heattransferMLIIcon,
    "heat-transfer-preset-solarpanels": heatTransferSolarpanelsIcon,
    "heat-transfer-preset-white": heatTransferWhiteIcon,
    "orbit-hubble": orbitHubbleIcon,
    "orbit-envisat": orbitEnvisatIcon,
    "orbit-mks": orbitMKSIcon,
    "orbit-echostar-xvii": orbitEchostarXVIIIcon
};


class EnumVal extends Component {
    static ensureEnumValue(props) {
        const {stuff, defaultValue, field, data} = props;
        const value = data && field && data[field];
        // in case we have non-null value not in stuff - set default value
        if (stuff && !stuff.find(x => x.value === value) && value) {
            // if not already equal to default value
            if (value !== defaultValue) {
                console.warn(`Unknown enum value ${value} for ${field}`);
                props.onChange(data, field, null, true);
            } else {
                console.error(`Default enum value ${value} is not present for ${field}!`);
            }
        }
    }

    constructor(props) {
        super(props);
        const {field, data} = props;
        this.state = {
            value: data && field && data[field],
            showAll: false
        };
    }

    componentWillMount() {
        EnumVal.ensureEnumValue(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const newValue = nextProps.data && nextProps.field && nextProps.data[nextProps.field];
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field || this.state.value !== newValue) {
            EnumVal.ensureEnumValue(nextProps);
            this.setState(prevState => ({
                value: newValue
            }));
        }
    }

    render() {
        const {noLabel, halfWidth, wide, name, info, disabled, data, field, size, hideOthersOnSelect, error, t} = this.props;
        let {stuff} = this.props;
        const {showAll} = this.state;

        const onClick = (value) => {
            this.props.onChange(data, field, value);
            this.setState(prevState => ({
                showAll: false
            }));
        };

        const onShowAll = (value) => {
            this.setState(prevState => ({
                showAll: true
            }));
        };

        let value = data && field ? data[field] : null;
        let contents;

        const hasImages = stuff.some(row => row.className);
        const hasInfoTiles = stuff.some(row => row.info || row.infoImage);

        const hideChoices = disabled || (hideOthersOnSelect && value && stuff.length > 1 && !showAll);
        const addShowAll = !disabled && (hideOthersOnSelect && value && stuff.length > 1 && !showAll);
        const showAllElement = <span className="controlelement" onClick={onShowAll}>{t('components.fields.EnumVal.showAll')}</span>;

        // nothing to render
        if (hideChoices && !stuff.find(x => x.value === value)) {
            stuff = stuff.slice();
            stuff.push({ value: value, name: t('components.fields.EnumVal.notChosen'), isInfo: true });
        }

        if (hasImages) {

            // className = className ? className: "bg-info";
            // contents =
            //     stuff
            //         .filter(row => !disabled || value === row.value)
            //         .map((row,i) => [
            //             <img className={`${value === row.value ? className : ""}`}
            //                  alt={row.value} key={row.value} src={row.image.src} width={row.image.size} height={row.image.size}
            //                  disabled={disabled}
            //                  onClick={onClick.bind(null,row.value)} />,
            //             i < stuff.length-1 &&
            //            <span key={row.value + '_'}>  </span>
            //         ]);

            contents =
                <Nav className={classnames("inner-nav-tabs", error && "border border-danger")}>
                    {stuff
                        .filter(row => !hideChoices || value === row.value)
                        .map((row, i) => [
                            <NavItem key={i}>
                                <NavLink className={classnames({active: value === row.value})}
                                         disabled={disabled}
                                         onClick={onClick.bind(null, row.value)}>
                                    <div className={classnames("tab-icon", row.className ? row.className : "placeholder-tab-icon")}/>
                                    {row.name ? row.name : row.value}
                                </NavLink>
                            </NavItem>
                        ])}
                </Nav>;

        } else if (hasInfoTiles) {

            const prefix = ['big', 'small'].indexOf(size) >= 0 ? size : "big";

            contents =
                <Nav className={classnames(`inner-nav-tabs ${prefix}-imgs-choice`, error && "border border-danger")}>
                    {stuff
                        .filter(row => !hideChoices || value === row.value)
                        .map((row,i) => [

                            <NavItem key={i}>
                                <NavLink className={classnames({active: value === row.value})}
                                         disabled={disabled}
                                         onClick={onClick.bind(null,row.value)}>
                                    {prefix === 'big' &&
                                    <div className={classnames("big-imgs-choice-elem d-flex flex-column", {active: value === row.value})}>
                                        <div className="option-name">
                                            <Dotdotdot clamp={2}>
                                                {row.name ? row.name : row.value}
                                            </Dotdotdot>
                                        </div>
                                        {row.infoImage &&
                                            <img className="big-imgs-choice-pic" src={images[row.infoImage]} alt={""} />}
                                        {row.info &&
                                        <div className="flex-grow" style={{overflowY: 'hidden'}}>
                                            <ResizeAware className="h-100" style={{ position: 'relative' }}>
                                                {({width, height}) => <Dotdotdot style={{height: height}} clamp="auto">
                                                    {row.info}
                                                </Dotdotdot>}
                                            </ResizeAware>
                                        </div>}
                                    </div>}
                                    {prefix === 'small' && !row.infoImage &&
                                    <div className={classnames("small-imgs-choice-elem", {active: value === row.value})}>
                                        <div className="option-name">
                                            <Dotdotdot clamp={2}>
                                                {row.name ? row.name : row.value}
                                            </Dotdotdot>
                                        </div>
                                        <div>{row.info}</div>
                                    </div>}
                                    {prefix === 'small' && row.infoImage &&
                                    <div className={classnames("small-imgs-choice-elem-centered", {active: value === row.value})}>
                                        <div className="option-name">
                                            <Dotdotdot clamp={2}>
                                                {row.name ? row.name : row.value}
                                            </Dotdotdot>
                                        </div>
                                        <img className={"small-imgs-choice-pic"} src={images[row.infoImage]} alt={""} />
                                    </div>}
                                </NavLink>
                            </NavItem>
                        ])}
                    {addShowAll &&
                    <NavItem className="d-flex ms-4" style={{alignItems: "center"}}>
                        {showAllElement}
                    </NavItem>}
                </Nav>;

        } else {
            contents =
                <span className={classnames("multibuttons",
                        error && "border border-danger")} role="group">
                    {stuff
                        .filter(row => !hideChoices || value === row.value)
                        .map(row =>
                            <button key={row.value || 'no-value'}
                                    className={classnames("btn", !row.isInfo && value === row.value ? "btn-primary active": "btn-light")}
                                    disabled={disabled}
                                    onClick={onClick.bind(null, row.value)}>
                                {row.name}
                            </button>
                        )}
                </span>;
        }

        contents = (
            <>
                {contents}
                <SimulationFieldSelector data={data} field={field + "_"} disabled={disabled} onChange={this.props.onChange} />
            </>);

        if (noLabel) {
            return (
                <div className={classnames("form-group row g-0", halfWidth && "col-sm-6 pe-2")}>
                    {contents}
                    {error &&
                    <Error id={field} error={error} />}
                </div>);
        } else if (hasInfoTiles) {
            return (
                <div className={classnames("form-group row g-0", halfWidth && "col-sm-6 pe-2")}>
                    <LabelWithInfo className="big-imgs-choice-label w-100" name={name} info={info}/>
                    {contents}
                    {error &&
                    <Error id={field} error={error} />}
                </div>);
        } else  {
            return (
                <div className={classnames("form-group row g-0", halfWidth && "col-sm-6")}>
                    <Col sm={halfWidth ? (wide ? 4 : 6) : (wide ? 2 : 3)}>
                        <LabelWithInfo name={name} info={info}/>
                    </Col>
                    <Col sm={halfWidth ? (wide ? 8 : 6) : (wide ? 10 : 9)}
                         className={classnames(halfWidth && "pe-2")}>
                        {contents}
                        {error &&
                        <Error id={field} error={error} />}
                    </Col>
                </div>);
        }
    }
}

export default withTranslation()(EnumVal);