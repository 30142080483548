import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import classnames from 'classnames'

import Tree from 'components/blocks/Tree'
import ListWithSort from 'components/blocks/ListWithSort'
import NamedBlock from 'components/blocks/NamedBlock'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import Messages from 'components/elements/Messages'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryLicenseEvents, queryScoreCorrections, queryStudentsWithUsers, queryStudentTeams} from '../data/adminQueries'
import {createScoreCorrection, updateScoreCorrection, deleteScoreCorrection} from '../data/adminMutations'

import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {breadcrumbsStore} from 'appBase/TopNav'
import {coloredIcon} from 'utils/visualHelpers'


const getStudentWithUserName = (student, user, t) => `${student.email} (${user ? user.name : t('appAdmin.views.ScoreCorrectionsView.notRegistered')})`;
const getStudentWithUserNameAndIcon = (student, user, t) => <span>{student.email} ({user ? user.name : <span>{coloredIcon('error')} {t('appAdmin.views.ScoreCorrectionsView.notRegistered')}</span>})</span>;


const ScoreCorrectionsView = compose(
    withUriParams([
        ['eventId', Number, {optional: true}],
        ['action', String, {optional: true, values: ['edit', 'newStudent', 'newTeam']}],
        ['scoreCorrectionId', Number, {optional: true}]]),
    queryLicenseEvents,
    queryScoreCorrections,
    queryStudentsWithUsers,
    queryStudentTeams,
    createScoreCorrection, updateScoreCorrection, deleteScoreCorrection,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['licenseEvents'])
)((props) => {

    const {eventId, scoreCorrectionId, action,
        licenseEvents, scoreCorrections, studentsWithUsers, studentTeams,
        createScoreCorrection, updateScoreCorrection, deleteScoreCorrection,
        disabled,
        dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;

    const [errorMessage, setErrorMessage] = useState(null);

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // links
    const containerId = 'scoreCorrections';
    breadcrumbsStore.register(containerId);

    const links = [{name: t('appAdmin.views.ScoreCorrectionsView.pointsCorrections'), to: `${props.uri}${props.location.search}`}];

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });


    // object tree nodes
    const eventNodes = licenseEvents.map(e => ({
        id: e.id,
        parentId: null,
        name: e.isArchived ? t('appAdmin.views.ScoreCorrectionsView.archived') + e.name: e.name,
        items: [],
        actions: {
            "select": () => navigate(`${props.uri}/${e.id}${props.location.search}`),
        }
    }));

    const makeColoredScoreSpan = (contents, score) => {
        return <span className={`text-${score < 0 ? 'danger' : 'success'}`}>{contents}</span>;
    };

    const studentRows = (scoreCorrections &&
        scoreCorrections
            .filter(sc => sc.student)
            .map(({id, score, achievement, student, user}, i) => ({
                fields: [
                    makeColoredScoreSpan(getStudentWithUserNameAndIcon(student, user, t), score),
                    makeColoredScoreSpan(score, score),
                    makeColoredScoreSpan(achievement, score),
                ],
                actions: {
                    "select": !disabled && (() => navigate(`${props.uri}/${eventId}/edit/${id}${props.location.search}`)),
                    "removeConfirmation": !disabled && (() => deleteScoreCorrection(id))
                }
            }))) || [];

    scoreCorrections && !disabled && studentRows.push({
        fields: [t('appAdmin.views.ScoreCorrectionsView.addStudentCorrection')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => navigate(`${props.uri}/${eventId}/newStudent${props.location.search}`)
        }
    });

    const teamRows = (scoreCorrections &&
        scoreCorrections
            .filter(sc => sc.studentTeam)
            .map(({id, score, achievement, studentTeam}, i) => ({
                fields: [
                    makeColoredScoreSpan(studentTeam.name, score),
                    makeColoredScoreSpan(score, score),
                    makeColoredScoreSpan(achievement, score),
                ],
                actions: {
                    "select": !disabled && (() => navigate(`${props.uri}/${eventId}/edit/${id}${props.location.search}`)),
                    "removeConfirmation": !disabled && (() => deleteScoreCorrection(id))
                }
            }))) || [];

    scoreCorrections && !disabled && teamRows.push({
        fields: [t('appAdmin.views.ScoreCorrectionsView.addTeamCorrection')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => navigate(`${props.uri}/${eventId}/newTeam${props.location.search}`)
        }
    });

    // object to edit

    const scoreCorrectionSource = useMemo(() => {
        if (action === 'edit' && scoreCorrectionId && scoreCorrections) {
            let scoreCorrection = scoreCorrections.find(x => x.id === scoreCorrectionId);
            if (scoreCorrection) {
                return {
                    ...scoreCorrection,
                    type: scoreCorrection.studentTeam ? 'team' : 'student'
                };
            }
        } else if (action === 'newStudent') {
            return {type: 'student', eventId: eventId};
        } else if (action === 'newTeam') {
            return {type: 'team', eventId: eventId};
        }
        return null;
    }, [action, scoreCorrectionId, scoreCorrections, eventId]);

    // setting dataSource
    useEffect(() => {
        setDataSource(
            scoreCorrectionSource,
            (data) => {
                //correction just in case, we remember the type
                if (data.type === 'student') {
                    delete data.studentTeamId;
                } else {
                    delete data.studentId;
                }
                data = entityUtils.filterFields(
                    data, [
                        ...['id',
                            'score',
                            'achievement'],
                        ...(!scoreCorrectionId ? [
                            'eventId',
                            'studentId',
                            'studentTeamId'] :
                            [])
                    ]);
                const mutationName = scoreCorrectionId > 0 ? "updateScoreCorrection" : "createScoreCorrection";
                const mutationFunc = scoreCorrectionId > 0 ? updateScoreCorrection : createScoreCorrection;
                mutationFunc(data)
                    .then(({data: {[mutationName]: {error}}}) => {
                        setErrorMessage(error ? parseBackendMessage(error, t): null);
                    });
            });
    }, [scoreCorrectionId, scoreCorrectionSource, dataSource, setDataSource,
        createScoreCorrection, updateScoreCorrection, setErrorMessage, t]);


    return (
        <div className="flex-grow d-flex flex-row scroll-parent">
            <div className="w-25 d-flex flex-column">
                <div className="scroll">
                    <Tree nodes={eventNodes} selectedId={eventId} executeWithLock={props.executeWithLock} />
                </div>
            </div>

            <div className="w-75 d-flex flex-column border border-dark border-top-0 border-end-0 border-bottom-0">
                <Messages error={errorMessage} />
                {eventId && // event is selected
                <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                    <NamedBlock name={t('appAdmin.views.ScoreCorrectionsView.teamCorrection')} className={classnames(disabled && "bg-light")}>
                        <ListWithSort headers={[t('appAdmin.views.ScoreCorrectionsView.team'), t('appAdmin.views.ScoreCorrectionsView.correction'), t('appAdmin.views.ScoreCorrectionsView.achievement')]}
                                      sizes={["30*", "10*", "60*"]}
                                      rows={teamRows} nameIndex="0"
                                      disabled={disabled} />
                    </NamedBlock>

                    <NamedBlock name={t('appAdmin.views.ScoreCorrectionsView.studentCorrection')} className={classnames(disabled && "bg-light")}>
                        <ListWithSort headers={[t('appAdmin.views.ScoreCorrectionsView.student'), t('appAdmin.views.ScoreCorrectionsView.correction'), t('appAdmin.views.ScoreCorrectionsView.achievement')]}
                                      sizes={["30*", "10*", "60*"]}
                                      rows={studentRows} nameIndex="0"
                                      disabled={disabled} />
                    </NamedBlock>
                </div>}

                {dataSource && studentsWithUsers && studentTeams &&
                <PanelModal show title={t('appAdmin.views.ScoreCorrectionsView.pointsCorrection')}
                            onClose={() => {
                                setDataSource(null);
                                navigate(`${props.uri}/${eventId}${props.location.search}`);
                            }}
                            noPadding scrollable>
                    <SaveButtonsBlock withEditMode
                                      isChanged={isChanged}
                                      hasErrors={hasErrors}
                                      onSave={(() => {
                                          saveAndReset();
                                          navigate(`${props.uri}/${eventId}${props.location.search}`);
                                      })}
                                      onCancel={(() => {
                                          setDataSource(null);
                                          navigate(`${props.uri}/${eventId}${props.location.search}`);
                                      })} />
                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource}
                                        items={[
                                            dataSource.type === 'student' ?
                                                {
                                                    name: t('appAdmin.views.ScoreCorrectionsView.student'),
                                                    type: "select",
                                                    field: "studentId",
                                                    options: studentsWithUsers.map(swu => ({name: getStudentWithUserName(swu.student, swu.user, t), value: swu.student.id})),
                                                    nullOption: t('appAdmin.views.ScoreCorrectionsView.notSelected2'),
                                                    required: true,
                                                    disabled: action === 'edit'
                                                } :
                                                {
                                                    name: t('appAdmin.views.ScoreCorrectionsView.team'),
                                                    type: "select",
                                                    field: "studentTeamId",
                                                    options: studentTeams.map(t => ({name: t.name, value: t.id})),
                                                    nullOption: t('appAdmin.views.ScoreCorrectionsView.notSelected2'),
                                                    required: true,
                                                    disabled: action === 'edit'
                                                },
                                            {
                                                name: t('appAdmin.views.ScoreCorrectionsView.pointsCorrection2'),
                                                type: "double",
                                                field: "score",
                                                required: true
                                            },
                                            {
                                                name: t('appAdmin.views.ScoreCorrectionsView.achievementName'),
                                                type: "string",
                                                field: "achievement"
                                            }
                                        ]}
                                        onChange={onChange} onValidate={onValidate}
                                        disabled={false}
                                        wide />
                    </div>
                </PanelModal>}
            </div>
        </div>);
});

export default ScoreCorrectionsView;