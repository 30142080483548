import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'

import StringVal from '../fields/StringVal'
import TextVal from '../fields/TextVal'
import HtmlVal from '../fields/HtmlVal'
import MarkdownVal from '../fields/MarkdownVal'
import SimpleMapVal from '../fields/SimpleMapVal'
import JSONTextVal from '../fields/JSONTextVal'
import DateVal from '../fields/DateVal'
import IntVal from '../fields/IntVal'
import EnumVal from '../fields/EnumVal'
import DoubleVal from '../fields/DoubleVal'
import DoubleArrayVal from '../fields/DoubleArrayVal'
import DoubleSquareMatrixVal from '../fields/DoubleSquareMatrixVal'
import ChartVal from '../fields/ChartVal'
import SimpleChartVal from '../fields/SimpleChartVal'
import SelectVal from '../fields/SelectVal'
import CheckVal from '../fields/CheckVal'
import OrbitMapVal from '../fields/OrbitMapVal'
import PythonEditorVal from '../fields/PythonEditorVal'
import JavaScriptEditorVal from '../fields/JavaScriptEditorVal'
import MapVal from '../fields/MapVal'
import RadioMapVal from '../fields/RadioMapVal'
import FileVal from '../fields/FileVal'
import ImageVal from '../fields/ImageVal'
import SerialNumberVal from '../fields/SerialNumberVal'
import PrototypeEditorVal from '../fields/PrototypeEditorVal'
import SimulationTaskEditorVal from '../fields/SimulationTaskEditorVal'

import ChoiceBlock from './ChoiceBlock'
import CollectionBlock from './CollectionBlock'
import Block from './Block'
import DynamicSectionBlock from './DynamicSectionBlock'
import PresetsBlock from './PresetsBlock'
import ExpressionsBlock from './ExpressionsBlock'
import ConversionsBlock from './ConversionsBlock'
import MultiLanguageTabBlock from './MultiLanguageTabBlock'
import MultiLanguageFieldBlock from './MultiLanguageFieldBlock'
import ValidatorBlock, {booleanProps, validationProps} from './ValidatorBlock'

import prototypeUtils from 'utils/prototypeUtils'
import {translateObjOrArray} from 'utils/translationUtils'


const containerPrefix = 'components.fieldBlocks.GeneratorBlock.';
class GeneratorBlock extends Component {

    static getComponentsTree(items, rootField, t) {
        const _t = (value) => t(containerPrefix + value);
        let hasMultilanguageFields = false;

        if (!items || !Array.isArray(items)) {
            return [];
        }
        // we will modify items prototypes in process, so make a copy (and translate it):
        items = JSON.parse(JSON.stringify(translateObjOrArray(items, t)));

        function f(items, sections, path) {

            const components = [];

            for (let i = 0; i < items.length; i++) {

                // setting absent field names to empty string ([null], [false] are normal field names)
                if (items[i].field === undefined) {
                    items[i].field = "";
                }

                let field = path + (path && items[i].field !== '' ? '.' : '') + items[i].field;

                // processing expressions separately:
                // TODO: Ugly, needs rewriting whole function normally.

                if (["initialValue", "defaultValue", "defaultExpression", "expression", "minExpression", "maxExpression"].some(f => items[i].hasOwnProperty(f))) {
                    const {initialValue, defaultValue, defaultExpression, expression, minExpression, maxExpression, ...rest} = items[i];
                    const isAllowNulls = items[i].stuff && items[i].stuff.some(x => x.value === null);
                    const expressionsItem = {
                        field: field,
                        type: "expressions",
                        initialValue: initialValue,
                        defaultValue: defaultValue,
                        defaultExpression: defaultExpression,
                        expression: expression,
                        minExpression: minExpression,
                        maxExpression: maxExpression,
                        isAllowNulls: isAllowNulls,
                        items: [
                            {...rest, field: ""}
                        ]
                    };
                    const childSections = [];
                    const children = f(expressionsItem.items, childSections, field);
                    const expressionsComponent = React.createElement(
                        ExpressionsBlock,
                        expressionsItem,
                        children.map((c, j) => React.cloneElement(c, {key: j})));
                    components.push(expressionsComponent);
                    continue;
                }

                // adding validation properties (if not exists and no 'validations')
                if (!items[i].validations) {
                    if (["json", "prototypeEditor"].includes(items[i].type) && !items[i].hasOwnProperty('checkJson')) {
                        items[i].checkJson = true;
                    }
                    if (items[i].type === "serialNumber" && items[i].hasOwnProperty('mask') && !items[i].hasOwnProperty('pattern')) {
                        items[i].pattern = {pattern: items[i].mask.replace(/-/g, '').replace(/X/g, '[A-Z0-9]')};
                    }
                }
                // removing false boolean validation properties
                booleanProps.forEach(bp => {
                    if (items[i].hasOwnProperty(bp) && !items[i][bp]) {
                        delete items[i][bp];
                    }
                });

                if (validationProps.some(f => items[i].hasOwnProperty(f))) {
                    // extracting validations
                    const validations = {};
                    validationProps
                        .filter(f => items[i].hasOwnProperty(f))
                        .forEach(vp => {
                            validations[vp] = items[i][vp];
                            delete items[i][vp];
                        });
                    const validationsItem = {
                        field: field,
                        type: "validations",
                        ...validations
                    };

                    const restItems = [
                        {...items[i], field: "", validations}
                    ];
                    const childSections = [];
                    const children = f(restItems, childSections, field);
                    const validationComponent = React.createElement(
                        ValidatorBlock,
                        validationsItem,
                        children.map((c, j) => React.cloneElement(c, {key: j})));
                    components.push(validationComponent);
                    continue;
                }

                if (items[i]["multilanguage"] === true) {
                    hasMultilanguageFields = true;
                    delete items[i]["multilanguage"];

                    const multiLanguageItem = {
                        field: field,
                        type: "multilanguage",
                    };
                    const restItems = [{...items[i], field: ""}];
                    const childSections = [];
                    const children = f(restItems, childSections, field);

                    const multiLanguageComponent = React.createElement(
                        MultiLanguageFieldBlock,
                        multiLanguageItem,
                        children.map((c, j) => React.cloneElement(c, {key: j})));
                    components.push(multiLanguageComponent);
                    continue;
                }

                function makeChildrenBlock(BlockType, additionalProps) {
                    //new sections subblock for new subblock (to add embedded sections in future)
                    const childSections = [];
                    //all children will put new sections in this subblock
                    const children = f(items[i].items, childSections, field);
                    return React.createElement(
                        BlockType,
                        {...items[i], ...additionalProps, field: field, sections: childSections},
                        children.map((c, j) => React.cloneElement(c, {key: j})));
                }

                let component;
                let collection, childField, spacecraftsCollection;

                switch (items[i].type) {
                    case "section":
                        // make section
                        const sectionKey = i;
                        sections.push({
                            name: items[i].name,
                            className: items[i].className,
                            title: items[i].title,
                            key: sectionKey
                        });
                        delete items[i].className;  // classname is not for Block, it should only go to section
                        // make recursive block out of children
                        component = makeChildrenBlock(Block, {section: sectionKey});
                        break;
                    case "dynamicSection":
                        childField =
                            field +
                            (field && items[i].sourceField ? '.' : '') +
                            items[i].sourceField +
                            '.id:$itemId';
                        let sectionItemComponents = f(items[i].dynamicItems ? items[i].dynamicItems : [], [], childField);
                        component = makeChildrenBlock(DynamicSectionBlock, {
                            childField: childField,
                            itemIdField: 'localId',
                            sectionItemComponents: sectionItemComponents
                        });
                        break;
                    case "choices":
                        // check children
                        if (items[i].items[0].field !== '_type') {
                            throw new Error("First item in choiceBlock should have field '_type'!");
                        }
                        if (items[i].items[0].type !== 'enum') {
                            throw new Error("First item in choiceBlock should have type 'enum'!");
                        }
                        if (items[i].items.slice(1).some(x => x.type !== 'choice')) {
                            throw new Error("Choice items in choiceBlock  should have type 'choice'!");
                        }
                        if (items[i].items.slice(1).some(x => x.field && x.field !== x.value)) {
                            throw new Error("Choice items in choiceBlock should either not have field 'field' or have it equal to field 'value'!");
                        }
                        if (items[i].items.slice(1).some(x => !x.hasOwnProperty('value'))) {
                            throw new Error("Choice items in choiceBlock  should have 'value'!");
                        }
                        // update with stuff
                        items[i].items[0].stuff = [
                            ...items[i].stuff
                        ];
                        // update children, set field = value
                        items[i].items.slice(1).forEach(x => {
                            const choiceStuffItem = items[i].stuff.find(cs => cs.value === x.value);
                            x.field = x.value;
                            x.choiceField = '_type';
                            x.choiceFieldName = items[i].items[0].name;
                            x.valueName = choiceStuffItem && choiceStuffItem.name ? choiceStuffItem.name : x.value;
                        });
                        component = makeChildrenBlock(Block);
                        break;
                    case "choice":
                        component = makeChildrenBlock(ChoiceBlock, {choiceField: path + (path ? '.' : '') + items[i].choiceField});
                        break;
                    case "presets":
                        // check children
                        if (items[i].items[0].field !== '_preset') {
                            throw new Error("First item in presetsBlock should have field '_preset'!");
                        }
                        if (items[i].items[0].type !== 'enum') {
                            throw new Error("First item in presetsBlock should be 'enum'!");
                        }
                        // update with values
                        // items[i].items[0].options = items[i].stuff.map(x => ({value: x.value, name: x.name}));
                        items[i].items[0].stuff = [
                            ...items[i].stuff
                        ];
                        if (items[i].userDefined) {
                            // items[i].items[0].options.push({value: "_user", name: "User-defined"});
                            // items[i].items[0].nullOption = "-- no choice --";
                            // items[i].items[0].hideNullOptionOnSelect = true;
                            items[i].items[0].stuff.push({
                                value: "_user",
                                name: _t('setParams'),
                                info: _t('setParamsBelow'),
                                infoImage: "user-defined-gears"
                            });
                        }
                        component = makeChildrenBlock(PresetsBlock, {presetField: field + (field !== '' ? '.' : '') + '_preset'});
                        break;
                    case "conversions":
                        component = makeChildrenBlock(ConversionsBlock);
                        break;
                    case "string":
                        component = React.createElement(StringVal, {...items[i], field: field});
                        break;
                    case "text":
                        component = React.createElement(TextVal, {...items[i], field: field});
                        break;
                    case "html":
                        component = React.createElement(HtmlVal, {...items[i], field: field});
                        break;
                    case "markdown":
                        component = React.createElement(MarkdownVal, {...items[i], field: field});
                        break;
                    case "json":
                        component = React.createElement(JSONTextVal, {...items[i], field: field});
                        break;
                    case "datetime":
                        component = React.createElement(DateVal, {...items[i], field: field, withTime: true});
                        break;
                    case "date":
                        component = React.createElement(DateVal, {...items[i], field: field});
                        break;
                    case "int":
                        component = React.createElement(IntVal, {...items[i], field: field});
                        break;
                    case "enum":
                        component = React.createElement(EnumVal, {...items[i], field: field});
                        break;
                    case "double":
                        component = React.createElement(DoubleVal, {...items[i], field: field});
                        break;
                    case "doubleArray":
                        component = React.createElement(DoubleArrayVal, {...items[i], field: field});
                        break;
                    case "doubleSquareMatrix":
                        component = React.createElement(DoubleSquareMatrixVal, {...items[i], field: field});
                        break;
                    case "orientation":
                        // component = React.createElement(OrientationVal, {...items[i], field: field});
                        if (items[i].orientationType === "direction") {
                            component = React.createElement(DoubleArrayVal, {...items[i], field: field, size: 3});
                        } else {
                            component = React.createElement(DoubleArrayVal, {...items[i], field: field, size: 4});
                        }
                        break;
                    case "map":
                        component = React.createElement(MapVal, {...items[i], field: field});
                        break;
                    case "collection":
                        let itemIdField = items[i].itemIdField || 'localId';
                        childField = field + (field !== '' ? '.' : '') + (itemIdField === '$index' ? 'index:$itemId' : 'id:$itemId');
                        collection = items[i].collection;
                        let collectionItemComponents = f(items[i].items, [], childField);
                        component = React.createElement(
                            CollectionBlock,
                            {
                                ...items[i],
                                field: field,
                                childField: childField,
                                collection: collection,
                                itemIdField: itemIdField,
                                collectionItemComponents: collectionItemComponents
                            });
                        break;
                    case "collectionSelect":
                        collection = items[i].collection;
                        component = React.createElement(SelectVal, {
                            itemNameField: 'name',
                            itemValueField: items[i].itemValueField || 'localId',
                            ...items[i],
                            field: field,
                            collection: collection,
                            nullOption: _t('noChoice')
                        });
                        break;
                    case "select":
                        component = React.createElement(SelectVal, {...items[i], field: field});
                        break;
                    case "block":
                        component = makeChildrenBlock(Block);
                        break;
                    case "chart":
                        spacecraftsCollection = 'spacecrafts';
                        const stationsCollection = 'stations';
                        const resultsDataCollection = 'resultsData';
                        const parametersCollection = 'parameters';
                        component = React.createElement(
                            ChartVal,
                            {
                                ...items[i], field: field,
                                spacecraftsCollection: spacecraftsCollection,
                                resultsDataCollection: resultsDataCollection,
                                stationsCollection: stationsCollection,
                                parametersCollection: parametersCollection
                            });
                        break;
                    case "simpleChart":
                        component = React.createElement(SimpleChartVal, {...items[i], field: field});
                        break;
                    case "simpleMap":
                        component = React.createElement(SimpleMapVal, {...items[i], field: field});
                        break;
                    case "orbitMap":
                        spacecraftsCollection = 'spacecrafts';
                        const orbitsCollection = 'orbits';
                        component = React.createElement(
                            OrbitMapVal,
                            {
                                ...items[i], field: field,
                                spacecraftsCollection: spacecraftsCollection,
                                orbitsCollection: orbitsCollection,
                            });
                        break;
                    case "pythonEditor":
                        component = React.createElement(PythonEditorVal, {...items[i], field: field});
                        break;
                    case "javaScriptEditor":
                        component = React.createElement(JavaScriptEditorVal, {...items[i], field: field});
                        break;
                    case "radioMap":
                        component = React.createElement(RadioMapVal, {...items[i], field: field});
                        break;
                    case "file":
                        component = React.createElement(FileVal, {...items[i], field: field});
                        break;
                    case "image":
                        component = React.createElement(ImageVal, {...items[i], field: field});
                        break;
                    case "serialNumber":
                        component = React.createElement(SerialNumberVal, {...items[i], field: field});
                        break;
                    case "prototypeEditor":
                        component = React.createElement(PrototypeEditorVal, {...items[i], field: field});
                        break;
                    case "simulationTaskEditor":
                        component = React.createElement(SimulationTaskEditorVal, {...items[i], field: field});
                        break;
                    case "check":
                        component = React.createElement(CheckVal, {...items[i], field: field});
                        break;
                    default:
                        throw new Error(`Unknown component type ${items[i].type}!`);
                }

                components.push(component);
            }
            return components;
        }

        // top-level components:
        let components = f(items, [], rootField || '');

        //adding MultiLanguageTabBlock if exists
        return hasMultilanguageFields
            ? [<MultiLanguageTabBlock>
                {components.map((c, i) => React.cloneElement(c, {key: i}))}
               </MultiLanguageTabBlock>]
            : components;
    }


    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            components: GeneratorBlock.getComponentsTree(props.items, props.field, props.t),
            fieldsInfo: prototypeUtils.getFieldsInfo(props.items, props.field)
        };
    }

    componentWillReceiveProps(nextProps) {
        // if changed items or rootField we reset
        if (nextProps.items !== this.props.items || nextProps.field !== this.props.field) {
            this.setState({
                components: GeneratorBlock.getComponentsTree(nextProps.items, nextProps.field, nextProps.t),
                fieldsInfo: prototypeUtils.getFieldsInfo(nextProps.items, nextProps.field)
            });
        }
    }

    onChange(data, field, value, noChanges, noRerender) {
        const {onChange} = this.props;
        //data[field] = value;
        if (onChange) {
            onChange(data, field, value, noChanges, noRerender);
        }
    }

    render() {
        const {className, disabled, data, collectionsDict, externalSections, onValidate, childrenBefore, childrenAfter, wide} = this.props;
        let {components, fieldsInfo} = this.state;

        components = components.map((component, i) =>
            React.cloneElement(component, {
                key: i,
                disabled: component.props.disabled || disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: this.onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                externalSections: externalSections,
                wide: component.props.wide || wide
            }));

        return (
            <Container fluid className={className}>
                {childrenBefore}
                {components}
                {childrenAfter}
            </Container>);
    }
}

export default withTranslation()(GeneratorBlock);