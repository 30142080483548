import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import {licenseTemplateFields, deviceFields} from './serviceQueries'

const AddRoleUser = gql`
  mutation addRoleUser($data: AddRoleUserMutationInput!) {
    addRoleUser(data: $data) {
      id
      error
    }
  }
`;

const DeleteRoleUser = gql`
  mutation deleteRoleUser($data: DeleteRoleUserMutationInput!) {
    deleteRoleUser(data: $data) {
      id
      error
    }
  }
`;

const CreateLicenseTemplate = gql`
  mutation createLicenseTemplate($data: CreateLicenseTemplateMutationInput!) {
    createLicenseTemplate(data: $data) {
      id
    }
  }
`;

const UpdateLicenseTemplate = gql`
  mutation updateLicenseTemplate($data: UpdateLicenseTemplateMutationInput!) {
    updateLicenseTemplate(data: $data) {
        licenseTemplate {
          ${licenseTemplateFields}
        }
        error
    }
  }
`;

const DeleteLicenseTemplate = gql`
  mutation deleteLicenseTemplate($id: ID!) {
    deleteLicenseTemplate(id: $id) {
      id
    }
  }
`;

const AddCatalogToLicenseTemplate = gql`
  mutation addCatalogToLicenseTemplate($data: AddCatalogToLicenseTemplateMutationInput!) {
    addCatalogToLicenseTemplate(data: $data) {
        error
    }
  }
`;

const DeleteCatalogFromLicenseTemplate = gql`
  mutation deleteCatalogFromLicenseTemplate($data: DeleteCatalogFromLicenseTemplateMutationInput!) {
    deleteCatalogFromLicenseTemplate(data: $data) {
      error
    }
  }
`;

const CreateLicensesFromLicenseTemplate = gql`
  mutation createLicensesFromLicenseTemplate($data: CreateLicensesFromLicenseTemplateMutationInput!) {
    createLicensesFromLicenseTemplate(data: $data) {
      error
    }
  }
`;

const CreateDevice = gql`
  mutation createDevice($data: CreateDeviceMutationInput!) {
    createDevice(data: $data) {
      id
    }
  }
`;

const UpdateDevice = gql`
  mutation updateDevice($data: UpdateDeviceMutationInput!) {
    updateDevice(data: $data) {
      serviceDevice {
        ${deviceFields}
      }
    }
  }
`;

const DeleteDevice = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      id
    }
  }
`;

const UploadUsers = gql`
  mutation uploadData($data: UploadUsersMutationInput!) {
    uploadUsers(data: $data) {
        result
        infos
        errors
    }
  }
`;


export const addRoleUser = graphql(AddRoleUser, {
    props: ({ mutate }) => ({ addRoleUser: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['RolesWithUsersQuery']}
});

export const deleteRoleUser = graphql(DeleteRoleUser, {
    props: ({ mutate }) => ({ deleteRoleUser: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['RolesWithUsersQuery']}
});

export const createLicenseTemplate = graphql(CreateLicenseTemplate, {
    props: ({ mutate }) => ({ createLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});

export const updateLicenseTemplate = graphql(UpdateLicenseTemplate, {
    props: ({ mutate }) => ({ updateLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});

export const deleteLicenseTemplate = graphql(DeleteLicenseTemplate, {
    props: ({ mutate }) => ({ deleteLicenseTemplate: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});

export const addCatalogToLicenseTemplate = graphql(AddCatalogToLicenseTemplate, {
    props: ({ mutate }) => ({ addCatalogToLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});

export const deleteCatalogFromLicenseTemplate = graphql(DeleteCatalogFromLicenseTemplate, {
    props: ({ mutate }) => ({ deleteCatalogFromLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});

export const createLicensesFromLicenseTemplate = graphql(CreateLicensesFromLicenseTemplate, {
    props: ({ mutate }) => ({ createLicensesFromLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});

export const createDevice = graphql(CreateDevice, {
    props: ({ mutate }) => ({ createDevice: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ServiceDevicesQuery']}
});

export const updateDevice = graphql(UpdateDevice, {
    props: ({ mutate }) => ({ updateDevice: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ServiceDevicesQuery']}
});

export const deleteDevice = graphql(DeleteDevice, {
    props: ({ mutate }) => ({ deleteDevice: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['ServiceDevicesQuery']}
});

export const uploadUsers = graphql(UploadUsers, {
    props: ({ mutate }) => ({ uploadUsers: (data) => mutate({ variables: { data } }) }),
});