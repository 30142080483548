import React from 'react'
import {Translation} from 'react-i18next'
import {Button, Col} from 'reactstrap'
import TextareaAutosize from 'react-textarea-autosize'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'

function JSONTextVal(props) {

    const {noLabel, wide, name, unit, info, infoImage, disabled, data, field, error} = props;
    let value = data && data[field] ? data[field]: "";

    const onChange = (value) => {
        props.onChange(data, field, value);
    };

    const contents =
        <JSONText value={value} disabled={disabled} onChange={onChange} error={error} />;

    if (noLabel) {
        return (
            <div className="form-group row g-0">
                {contents}
            </div>);
    } else {
        return (
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
                </Col>
                <Col sm={wide ? 10 : 9}>
                    {contents}
                </Col>
            </div>);
    }
}

export class JSONText extends React.Component {
    static formatValue(jsonString) {
        try {
            return jsonString
                ? JSON.stringify(JSON.parse(jsonString), null, 2)
                : "";
        } catch (e) {
            return jsonString;
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            value: JSONText.formatValue(props.value)
        };
        this.onChange = this.onChange.bind(this);
        this.onFormatValue = this.onFormatValue.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            this.setState((prevState) => ({
                value: JSONText.formatValue(nextProps.value)
            }));
        }
    }

    onChange(e) {
        const value = e.nativeEvent.target.value;
        this.setState(prevState => {
            this.props.onChange(value);
            return {
                value: value
            };
        });
    }

    onFormatValue() {
        const {value} = this.state;
        if (!value) {
            return;
        }
        const formattedValue = JSONText.formatValue(value);
        this.onChange({ nativeEvent: { target: { value: formattedValue } } });
    }

    render() {
        const maxLength = undefined; // 600;
        const maxRows = 10;

        const {value} = this.state;
        const {disabled, visualEditor} = this.props;
        const error = this.props.error;

        const textAreaComponent =
            !value || value.length < 10000
                ? <TextareaAutosize className={classnames("form-control", error && "is-invalid")} style={{resize: "none"}}
                                    value={value} disabled={disabled} onChange={this.onChange}
                                    maxLength={maxLength} maxRows={maxRows}/>
                : <textarea className={classnames("form-control", error && "is-invalid")} style={{resize: "none"}}
                            value={value} disabled={disabled} onChange={this.onChange}
                            rows={maxRows} />;

        return (
            <div>
                {textAreaComponent}
                <Translation>
                  {
                    t => <>
                            {visualEditor &&
                             <Button color="primary" onClick={visualEditor.onClick}>
                                {visualEditor.buttonName}
                             </Button>}
                             <Button color="primary" outline disabled={disabled || !!error} onClick={this.onFormatValue}>
                                {t('components.fields.JSONTextVal.format')}
                             </Button>
                         </>
                  }
                </Translation>
                {error &&  <Error error={error}/>}
            </div>
        );
    }
}

export default JSONTextVal;