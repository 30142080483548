import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useMatch} from "@reach/router";
import compose from 'lodash/flowRight'

import useThrottledEffect from 'hooks/useThrottledEffect'
import useSmartNavigate from 'hooks/useSmartNavigate'

import {Button, Form, FormGroup, Input, FormText, Alert, Label} from 'reactstrap'
import Recaptcha from 'react-recaptcha'
import Link from 'components/links/Link'
import OldLinkWithoutNavigation from 'components/links/OldLinkWithoutNavigation'
import PanelModal from 'components/modals/PanelModal'
import MarkdownOutput from 'components/elements/MarkdownOutput'

import {registerTalentId} from './data/baseMutations'

import {restApi} from 'utils/restApi'
import {addSearchParamToUrl} from 'utils/urlUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {changeLanguageTo} from "./TopNav";

import {faIcon, coloredIcon} from 'utils/visualHelpers'

import logoIcon from 'icons/logo/logo.png'
import talentLogoIcon from 'icons/logo/talent_logo_black.svg'
import {useCookies} from "react-cookie";


const loginManager = {
    resetApolloCache: (apolloClient) => {
        // Let's assume the user logs in.
        // First: do whatever is necessary to set the user's session.
        // Next: you absolutely must reset the store. This will clear the prior user's data from
        // memory and will cause all of the open queries to refetch using the new user's session.
        apolloClient.resetStore();
        // apolloClient.clearStore();

        // // Resume cache persistence. The cache storage provider will be empty right now,
        // // but it will written with the new user's data the next time the trigger fires.
        // this.props.apolloCachePersistor.resume();
    },

    logout: (apolloClient, navigate, uriMatch, t) => {
        // logout user
        restApi.getDataWithStatus('logout')
            .then(({status, data}) => {
                loginManager.resetApolloCache(apolloClient);
                navigate(`/${uriMatch.lang}/auth/login`);
            }, ({status, error}) => {
                navigate(`/${uriMatch.lang}/auth/login`, {state: {error: error && (t('appBase.Authorization.logout.error') + error)}});
            });
    },

    login: (apolloClient, email, password, goBackLinkData, navigate, uriMatch, t) => {
        restApi.getDataWithStatus('login', {email: email, password: password})
            .then(({status, data}) => {
                loginManager.resetApolloCache(apolloClient);
                const returnUrl = (goBackLinkData && goBackLinkData.to) || `/${uriMatch.lang}/`;
                const returnState = (goBackLinkData && goBackLinkData.state) || {};
                navigate(returnUrl, {state: {returnState}});
            }, ({status, error}) => {
                // special case: we need to reset form on navigation to login, but here is navigation from login to login :(
                navigate(`/${uriMatch.lang}/auth/relogin`, {state: {goBackLinkData,
                    error:
                        status === 404 ? t('appBase.Authorization.login.errors.error2') :
                            status === 401 ? t('appBase.Authorization.login.errors.error3') :
                                t('appBase.Authorization.login.errors.error1') + error},
                    goBackLinkData: goBackLinkData });
            });
    },

    setPassword: (password, resetToken, goBackLinkData, navigate, uriMatch, t) => {
        restApi.getDataWithStatus('set', {password: password, token: resetToken})
            .then(({status, data}) => {
                // user was logged in
                const returnUrl = (goBackLinkData && goBackLinkData.to) || `/${uriMatch.lang}/`;
                const returnState = (goBackLinkData && goBackLinkData.state) || {};
                navigate(returnUrl, {state: {...returnState, info: t('appBase.Authorization.set.info')}});
            }, ({status, error}) => {
                let returnUrl;
                let returnState;
                const isAuthorized = goBackLinkData && goBackLinkData.isAuthorized;
                if (isAuthorized) {
                    // returning back
                    returnUrl = (goBackLinkData && goBackLinkData.to) || `/${uriMatch.lang}/`;
                    returnState = (goBackLinkData && goBackLinkData.state) || {};
                } else {
                    // keeping goBackLinkData until better times
                    returnUrl = `/${uriMatch.lang}/auth/login`;
                    returnState = {goBackLinkData};
                }
                navigate(returnUrl, {state: {...returnState, error:
                    status === 400 ? t('appBase.Authorization.set.errors.error2') :
                        t('appBase.Authorization.set.errors.error1') + error
                }});
            });
    },

    resetPassword: (email, goBackLinkData, navigate, uriMatch, t) => {
        // logout user
        restApi.getDataWithStatus('reset', {email: email, return_url: goBackLinkData && goBackLinkData.to})
            .then(({status, data}) => {
                navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, info: t('appBase.Authorization.reset.info')}});
            }, ({status, error}) => {
                navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, error:
                    status === 404 ? t('appBase.Authorization.reset.errors.error2') :
                        t('appBase.Authorization.reset.errors.error1') + error}});
            });
    },

    registerUser: (apolloClient, email, name, surname, talentId, goBackLinkData, navigate, uriMatch, t) => {
        // logout user
        restApi.getDataWithStatus(
            'register',
            { email: email, name: name, surname: surname, talent_id: talentId, return_url: goBackLinkData && goBackLinkData.to })
            .then(({status, data}) => {
                let error = data['error'];
                let isUserExists = data['isUserExists'];
                let isActive = data['isActive'];
                if (error) {
                    navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, error: error}});
                } else if (isUserExists && !talentId) {
                    navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData,
                        info: t('appBase.Authorization.register.infos.info1')}});
                } else {
                    navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData,
                        info: isUserExists && talentId ? t('appBase.Authorization.register.infos.info2') :
                            !isUserExists && talentId && isActive ? t('appBase.Authorization.register.infos.info3') :
                            t('appBase.Authorization.register.infos.info4')}});
                }
            }, ({status, error}) => {
                navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, error:
                    status === 400 ? t('appBase.Authorization.register.errors.error2') :
                        t('appBase.Authorization.register.errors.error1') + error}});
            });
    },

    resetToken: (searchString, navigate, uriMatch, t) => {
        let token = '', returnUrl = null;
        searchString.split('&').forEach(param => {
            const [name, value] = param.split('=');
            if (name === 'token') {
                token = decodeURIComponent(value);
            }
            if (name === 'returnUrl') {
                returnUrl = decodeURIComponent(value);
            }
        });
        restApi.getDataWithStatus('check_reset_token', {token: token})
            .then(({status, data}) => {
                navigate(`/${uriMatch.lang}/auth/set?${token}`, {state: {goBackLinkData: {to: returnUrl},
                    info: data['first_time'] ? t('appBase.Authorization.resetToken.infos.info1') : t('appBase.Authorization.resetToken.infos.info2'),
                    error: ''}});
            }, ({status, error}) => {
                navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData: {to: returnUrl},
                    info: '',
                    error: status === 404 ? t('appBase.Authorization.resetToken.errors.error2') :
                        status === 408 ? t('appBase.Authorization.resetToken.errors.error3') :
                            t('appBase.Authorization.resetToken.errors.error1') + error}});
            });
    },

    initiateTalentOauth: (goBackLinkData, navigate, uriMatch, t) => {
        console.log('loginManager.initiateTalentOauth:');
        const returnUrl = (goBackLinkData && goBackLinkData.to) || `/${uriMatch.lang}/`;
        const returnState = (goBackLinkData && goBackLinkData.state) || {};
        const isAuthorized = goBackLinkData && goBackLinkData.isAuthorized;
        restApi.getDataWithStatus('get_talent_oauth_redirect', {return_url: returnUrl, is_authorized: isAuthorized})
            .then(({status, data}) => {
                console.log('       promise.ok');
                // navigate((data && data.url) || `/${uriMatch.lang}/`);
                window.location.replace((data && data.url) || `/${uriMatch.lang}/`);
            }, ({status, error}) => {
                console.log('       promise.error');
                navigate(returnUrl, {state: {...returnState, error: error && (t('appBase.Authorization.initiateTalentOauth.error') + error)}});
            });
    },

    completeTalentOauth: (searchString, navigate, apolloClient, uriMatch, t) => {
        let returnUrl = null, isAuthorized, error = null, talentData = {};
        searchString.split('&').forEach(param => {
            const [name, value] = param.split('=');
            if (name === 'returnUrl') {
                returnUrl = decodeURIComponent(value);
            }
            if (name === 'isAuthorized') {
                isAuthorized = (decodeURIComponent(value) || '').toLowerCase() === 'true';
            }
            if (name === 'error') {
                error = decodeURIComponent(value);
            }
            if (['id', 'email', 'firstName', 'lastName', 'userEmailWithTalentId', 'isTalentEmailExists'].includes(name)) {
                talentData[name] = decodeURIComponent(value);
                if (name === 'id' && talentData[name]) {
                    talentData[name] = Number(talentData[name])
                }
                if (name === 'isTalentEmailExists' && talentData[name]) {
                    talentData[name] = talentData[name].toLowerCase() === 'true'
                }
            }
        });
        returnUrl = returnUrl || `/${uriMatch.lang}/`;
        const goBackLinkData = {to: returnUrl, isAuthorized: isAuthorized};
        error = error || (!talentData.id && t('appBase.Authorization.completeTalentOauth.errors.error2'));
        if (error) {
            // return back if error
            if (isAuthorized) {
                navigate(returnUrl, {state: {error: error}});
            } else {
                navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, error: error}});
            }
        } else if (talentData.userEmailWithTalentId) {
            if (isAuthorized) {
                navigate(returnUrl, {state: {error: t('appBase.Authorization.completeTalentOauth.errors.error3')}});
            } else {
                // login if email found
                restApi.getDataWithStatus('login', {email: talentData.userEmailWithTalentId, talent_id: talentData.id})
                    .then(({status, data}) => {
                        const error = data['error'];
                        if (!error) {
                            loginManager.resetApolloCache(apolloClient);
                            navigate(returnUrl);
                        } else {
                            navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, error: error}});
                        }
                    }, ({status, error}) => {
                        navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData, error: t('appBase.Authorization.completeTalentOauth.errors.error1') + error}});
                    });
            }
        } else {
            // register new account
            navigate(
                `/${uriMatch.lang}/auth/registerTalentId`,
                {state: {goBackLinkData, talentData: talentData}});
        }
    }
};


const Authorization = compose(
    registerTalentId
)((props) => {

    const {
        mode, apolloClient,
        registerTalentId} = props;

    const [, setCookie] = useCookies([]);
    const uriMatch = useMatch('/:lang/*');
    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    // special case navigation: relogin -> login
    if (mode === 'relogin') {
        navigate(`/${uriMatch.lang}/auth/login`, {state: props.location.state});
    }


    const [formState, setFormState] = useState({
        email: "",
        name: "",
        surname: "",
        agree: false,
        captcha: props.location.hostname === "localhost",   // set if localhost
        password: "",
        password2: "",
        showAgreement: false,
        registerEmailType: null,
        registerEmailChecks: {},
        loading: false,
        info: "",
        error: ""
    });

    const info =
        mode === 'logout' ? t('appBase.Authorization.logout.exit') :
        (['resetToken', 'initiateTalentOauth', 'completeTalentOauth'].includes(mode) || formState.loading) ? t('appBase.Authorization.logout.wait') :
        props.location.state && props.location.state.info ? props.location.state && props.location.state.info :
        formState.info ? formState.info : "";

    const error =
        mode === 'logout' ? "" :
        props.location.state && props.location.state.error ? props.location.state && props.location.state.error :
        formState.error;

    const goBackLinkData = props.location.state && props.location.state.goBackLinkData;
    const isAuthorized = goBackLinkData && goBackLinkData.isAuthorized;

    const {talentData} = props.location.state || {};

    let registerEmailValue = null;
    if (mode === 'registerTalentId' && !isAuthorized) {
        if (formState.registerEmailType === 'talent') {
            registerEmailValue = talentData.email;
        } else if (formState.registerEmailType === 'new') {
            registerEmailValue = formState.email;
        }
    }

    const registerEmailChecksHasChanges =
        useThrottledEffect(() => {
            if (registerEmailValue) {
                restApi.getDataWithStatus('get_talent_registration_email_check', {email: registerEmailValue})
                    .then(({status, data}) => {
                        setFormState({
                            ...formState,
                            registerEmailChecks: {
                                isExists: data.isExists,
                                hasTalentId: data.hasTalentId
                            }
                        });
                    });
            }
        }, [registerEmailValue], 1000);


    // if we visit 'login' link then reset
    useEffect(() => {
        if (mode === 'login') {
            setFormState({...formState, loading: false});
        }
    }, [mode]);


    useEffect(() => {
        const locationSearch = props.location.search && props.location.search.substring(1);// location.search starts with '?'
        if (mode === 'logout') {
            loginManager[mode](apolloClient, navigate, uriMatch, t);
        } else if (mode === 'resetToken') {
            loginManager[mode](locationSearch, navigate, uriMatch, t);
        } else if (mode === 'initiateTalentOauth') {
            loginManager[mode](goBackLinkData, navigate, uriMatch, t);
        } else if (mode === 'completeTalentOauth') {
            loginManager[mode](locationSearch, navigate, apolloClient, uriMatch, t);
        }
    }, [mode, props.location.search, goBackLinkData, navigate, apolloClient, uriMatch, t]);


    const onChange = (e) => {
        switch (e.nativeEvent.target.name) {
            case "email":
            case "name":
            case "surname":
            case "password":
            case "password2":
            case "registerEmailType":
                setFormState({...formState, [e.nativeEvent.target.name]: e.nativeEvent.target.value});
                break;
            case "agree":
                setFormState({...formState, [e.nativeEvent.target.name]: e.nativeEvent.target.checked});
                break;
            case "captcha":
                setFormState({...formState, [e.nativeEvent.target.name]: true});
                break;
            default:
                throw new Error("Unknown field!");
        }
    };

    const onClick = (e) => {
        const {email, name, surname, agree, captcha, password, password2} = formState;
        e.stopPropagation();

        if (mode === "register") {
            if (email && surname && name && agree && captcha) {
                loginManager['registerUser'](apolloClient, email, name, surname, null, goBackLinkData, navigate, uriMatch, t);
                setFormState({...formState, loading: true, name: "", surname: ""});
            } else {
                setFormState({...formState, error: t('appBase.Authorization.register.errors.error3')})
            }
        }
        else if (mode === "set") {
            if (password === password2) {
                const resetToken = props.location.search && props.location.search.substring(1);    // location.search starts with '?'
                setFormState({...formState, loading: true, password: "", password2: "", error: ""});
                loginManager['setPassword'](password, resetToken, goBackLinkData, navigate, uriMatch, t);
            } else {
                setFormState({...formState, password: "", password2: "", error: t('appBase.Authorization.set.errors.error3')})
            }
        }
        else if (mode === "reset") {
            if (email) {
                loginManager['resetPassword'](email, goBackLinkData, navigate, uriMatch, t);
                setFormState({...formState, loading: true});
            } else {
                setFormState({...formState, error: t('appBase.Authorization.reset.errors.error3')})
            }
        }
        else if (mode === "login") {
            if (email && password) {
                loginManager['login'](apolloClient, email, password, goBackLinkData, navigate, uriMatch, t);
                setFormState({...formState, loading: true});
            } else {
                setFormState({...formState, error: t('appBase.Authorization.login.errors.error4')})
            }
        }
        else if (mode === "registerTalentId") {
            const talentId = talentData.id;
            if (isAuthorized) {
                // with graphqlQuery - user is logged in, graphql is accessible, and will refetch UserQuery
                registerTalentId(talentId)
                    .then(({data: {registerTalentId: {error}}}) => {
                        if (error) {
                            navigate(goBackLinkData.to, {state: {error: parseBackendMessage(error, t)}});
                        } else {
                            navigate(goBackLinkData.to, {state: {info: t('appBase.Authorization.registerTalentId.info')}});
                        }
                    });
                setFormState({...formState, loading: true});
            } else {
                const name = talentData.firstName;
                const surname = talentData.lastName;
                loginManager['registerUser'](apolloClient, registerEmailValue, name, surname, talentId, goBackLinkData, navigate, uriMatch, t);
                setFormState({...formState, loading: true, email: ""});
            }
        }
    };

    const setShowAgreement = (show) => {
        setFormState({...formState, showAgreement: show});
    };


    // rendering
    const {email, name, surname, agree, captcha, password, password2, showAgreement,
        registerEmailType, registerEmailChecks, loading} = formState;

    const isValidRegisterEmail =
        !registerEmailChecksHasChanges && registerEmailValue &&
        ((formState.registerEmailType === 'talent' && !registerEmailChecks.hasTalentId) ||
         (formState.registerEmailType === 'new' && !registerEmailChecks.isExists));

    return (
        <div className="container pt-5 pb-5">
            <div className="row justify-content-center">
                <div className="col-9 col-sm-8 col-md-6 col-lg-5 col-xl-4 p-5 bg-light border border-secondary rounded">

                    <div className="text-center">
                        <Button className="m-1 p-1" color={i18n.resolvedLanguage === "ru" ? "outline-secondary" : "outline-primary"}
                                disabled={i18n.resolvedLanguage === "ru"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeLanguageTo("ru", uriMatch, location, navigate, setCookie, apolloClient, i18n);
                        }}>RU</Button>

                        <Button className="m-1 p-1" color={i18n.resolvedLanguage === "en" ? "outline-secondary" : "outline-primary"}
                                disabled={i18n.resolvedLanguage === "en"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeLanguageTo("en", uriMatch, location, navigate, setCookie, apolloClient, i18n);
                        }}>EN</Button>
                    </div>

                    <div className="form-group">
                        <img className="img-fluid" src={logoIcon} alt="" />
                    </div>
                    {info &&
                    <Alert color="success">{info}</Alert>}
                    {error &&
                    <Alert color="danger">{error}</Alert>}

                    {mode === "register" &&
                    <Form>
                        <FormGroup>
                            <Input type="email" name="email" value={email} placeholder={t('appBase.Authorization.register.email')}
                                   onChange={onChange} disabled={loading}/>
                        </FormGroup>
                        <FormGroup>
                            <Input type="text" name="name" value={name} placeholder={t('appBase.Authorization.register.name')}
                                   onChange={onChange} disabled={loading}/>
                        </FormGroup>
                        <FormGroup>
                            <Input type="text" name="surname" value={surname} placeholder={t('appBase.Authorization.register.surname')}
                                   onChange={onChange} disabled={loading}/>
                        </FormGroup>
                        <FormGroup>
                            <FormGroup check>
                                <Label check>{' '}
                                    <Input type="checkbox" name="agree" checked={agree}
                                           onChange={onChange} disabled={loading}/>
                                           {t('appBase.Authorization.register.giveAgreement')}
                                    (<span className="controlelement" onClick={() => setShowAgreement(true)}>{t('appBase.Authorization.register.readAgreement')}</span>)
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        {!captcha &&
                        <FormGroup className="text-center">
                            <div style={{display: "inline-block"}}>
                                <Recaptcha
                                    sitekey="6LfrzcMUAAAAAMG9ILDeR6wtuTVRfrS9zU-o45uo"
                                    size="compact"
                                    render="explicit"
                                    onloadCallback={() => console.log('rendered')}
                                    verifyCallback={(e) => {
                                        onChange({nativeEvent: {target: {name: "captcha", value: true}}});
                                    }}
                                />
                            </div>
                        </FormGroup>}
                        <FormGroup className="text-center">
                            <Button color="primary"
                                    disabled={!email.length || !name.length || !surname.length || loading ||
                                    !agree || !captcha}
                                    onClick={onClick}>{t('appBase.Authorization.register.register')}</Button>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                {t('appBase.Authorization.register.areRegistered')} <Link to={`/${uriMatch.lang}/auth/login`} state={{goBackLinkData}}>{t('appBase.Authorization.register.logIn')}</Link>
                            </FormText>
                        </FormGroup>
                        <PanelModal show={showAgreement} title={t('appBase.Authorization.register.agreementTitle')}
                                    onClose={() => setShowAgreement(false)}>
                            <MarkdownOutput value={t('appBase.Authorization.register.agreement')}/>
                        </PanelModal>
                    </Form>}

                    {mode === "login" &&
                    <Form>
                        <FormGroup>
                            <Input type="email" name="email" value={email} placeholder={t('appBase.Authorization.login.email')}
                                   onChange={onChange} disabled={loading}/>
                        </FormGroup>
                        <FormGroup>
                            <Input type="password" name="password" value={password} placeholder={t('appBase.Authorization.login.password')}
                                   onChange={onChange} disabled={loading}/>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Button color="primary" disabled={!email.length || !password.length || loading}
                                    onClick={onClick}> {t('appBase.Authorization.login.logIn')} </Button>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                {t('appBase.Authorization.login.forgetPassword')} <Link to={`/${uriMatch.lang}/auth/reset`} state={{goBackLinkData}}>{t('appBase.Authorization.login.resetPassWord')}</Link>
                            </FormText>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                {t('appBase.Authorization.login.notRegistered')} <Link to={`/${uriMatch.lang}/auth/register`} state={{goBackLinkData}}>{t('appBase.Authorization.login.register')}</Link>
                            </FormText>
                            <FormText>
                                 <Link to={`/${uriMatch.lang}/events`} state={{goBackLinkData}}>
                                    {t('appBase.Authorization.login.logInWithoutRegistration')}
                                 </Link>
                            </FormText>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <hr style={{height: "1px"}} />
                            <FormText>
                                {t('appBase.Authorization.login.talent')}
                            </FormText>
                            <Button className="mt-3" color="outline-secondary"
                                    onClick={() => navigate(`/${uriMatch.lang}/auth/initiateTalentOauth`, {state: {goBackLinkData}})}>
                                <img className="img-fluid" src={talentLogoIcon} alt="" style={{height: '30px'}}/>
                            </Button>
                        </FormGroup>
                    </Form>}

                    {mode === "reset" &&
                    <Form>
                        <FormGroup>
                            <Input type="email" name="email" value={email} placeholder={t('appBase.Authorization.reset.email')}
                                   onChange={onChange} disabled={loading} />
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Button color="primary" disabled={!email.length || loading} onClick={onClick}>
                                {t('appBase.Authorization.reset.sendToMail')}</Button>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                <Link to={`/${uriMatch.lang}/auth/login`} state={{goBackLinkData}}>{t('appBase.Authorization.reset.goBack')}</Link>
                            </FormText>
                        </FormGroup>
                    </Form>}

                    {mode === "set" &&
                    <Form>
                        <FormGroup>
                            <Input type="password" name="password" value={password} placeholder={t('appBase.Authorization.set.password')}
                                   onChange={onChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Input type="password" name="password2" value={password2}
                                   placeholder={t('appBase.Authorization.set.password2')} onChange={onChange}/>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Button color="primary" disabled={!password.length || !password2.length}
                                    onClick={onClick}>
                                {t('appBase.Authorization.set.changePassword')}
                            </Button>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                {isAuthorized &&
                                <Link to={goBackLinkData.to} state={goBackLinkData.state}>{t('appBase.Authorization.set.goBack')}</Link>}
                                {!isAuthorized &&
                                <OldLinkWithoutNavigation onClick={() => {
                                        setFormState({...formState, info: "", error: ""});
                                        navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData}});
                                    }}>{t('appBase.Authorization.set.cancel')}</OldLinkWithoutNavigation>}
                            </FormText>
                        </FormGroup>
                    </Form>}

                    {mode === 'registerTalentId' &&
                    <>
                    <Alert color="success">
                        <p>{t('appBase.Authorization.registerTalentId.talentAccount')}</p>
                        <p>{t('appBase.Authorization.registerTalentId.id')}<span className="fw-bold">{talentData.id}</span></p>
                        <p>{t('appBase.Authorization.registerTalentId.email')}<span className="fw-bold">{talentData.email}</span></p>
                        <p>{t('appBase.Authorization.registerTalentId.name')}<span className="fw-bold">{talentData.firstName} {talentData.lastName}</span></p>
                    </Alert>
                    <Form>
                        {/*<FormGroup>*/}
                            {/*<Input type="text" name="id" value={talentData.id} disabled />*/}
                        {/*</FormGroup>*/}
                        {/*<FormGroup>*/}
                            {/*<Input type="email" name="email" value={talentData.email} disabled />*/}
                        {/*</FormGroup>*/}
                        {/*<FormGroup>*/}
                            {/*<Input type="text" name="name" value={talentData.firstName} disabled />*/}
                        {/*</FormGroup>*/}
                        {/*<FormGroup>*/}
                            {/*<Input type="text" name="surname" value={talentData.lastName} disabled />*/}
                        {/*</FormGroup>*/}

                        {isAuthorized &&     // ask for confirmation
                        <>
                        <FormGroup className="text-center pt-3">
                            <Button color="primary"
                                    onClick={onClick}
                                    disabled={loading}>
                                    {t('appBase.Authorization.registerTalentId.addTalent')}
                            </Button>
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                <Link to={goBackLinkData.to} state={goBackLinkData.state}>{t('appBase.Authorization.registerTalentId.cancel')}</Link>
                            </FormText>
                        </FormGroup>
                        </>}

                        {!isAuthorized &&     // register
                        <>
                        {/*<FormGroup tag="radioChoice">*/}
                        <FormGroup className="pt-2">
                            <FormText tag="span">{t('appBase.Authorization.registerTalentId.areRegisteredInOrbita.part1')}</FormText>
                        </FormGroup>
                        <FormGroup className="pt-2" check>
                            <Label check>
                                <Input type="radio" name="registerEmailType" value="talent" onChange={onChange} />{' '}
                                {talentData.isTalentEmailExists &&
                                <span>
                                    {t('appBase.Authorization.registerTalentId.areRegisteredInOrbita.part2')} <span className="fw-bold">{talentData.email}</span>.
                                    {t('appBase.Authorization.registerTalentId.areRegisteredInOrbita.part3')}
                                </span>}
                                {!talentData.isTalentEmailExists &&
                                <span>
                                    {t('appBase.Authorization.registerTalentId.areRegisteredInOrbita.part4')}<span className="fw-bold">{talentData.email}</span>
                                </span>}
                            </Label>
                        </FormGroup>
                        <FormGroup className="pt-2" check>
                            <Label check>
                                <Input type="radio" name="registerEmailType" value="existing" onChange={onChange} />{' '}
                                    {t('appBase.Authorization.registerTalentId.areRegisteredInOrbita.part5')}
                            </Label>
                        </FormGroup>
                        <FormGroup className="pt-2" check>
                            <Label check>
                                <Input type="radio" name="registerEmailType" value="new" onChange={onChange} />{' '}
                                {t('appBase.Authorization.registerTalentId.areRegisteredInOrbita.part6')}
                            </Label>
                        </FormGroup>
                        {/*</FormGroup>*/}
                        {/*<FormGroup>*/}
                        <FormGroup className="pt-3">
                            {registerEmailType === 'talent' &&
                            <Input type="email" name="email"
                                   value={talentData.email}
                                   disabled />}
                            {registerEmailType === 'new' &&
                            <Input type="email" name="email"
                                   value={email}
                                   placeholder={t('appBase.Authorization.registerTalentId.inputEmail')}
                                   onChange={onChange}
                                   disabled={loading} />}
                            <Label>
                                {registerEmailChecksHasChanges && registerEmailValue &&
                                <span>{t('appBase.Authorization.registerTalentId.check')} {faIcon('fa-spinner', 'fa-pulse fa-fw')}</span>}

                                {!registerEmailChecksHasChanges && registerEmailValue &&
                                <>
                                {registerEmailType === 'talent' && registerEmailChecks.hasTalentId &&
                                <span className="text-danger">{t('appBase.Authorization.registerTalentId.errors.error1')} {coloredIcon('blocked')}</span>}
                                {formState.registerEmailType === 'new' && registerEmailChecks.isExists &&
                                <span className="text-danger">{t('appBase.Authorization.registerTalentId.errors.error2')}{coloredIcon('blocked')}</span>}

                                {((formState.registerEmailType === 'talent' && !registerEmailChecks.hasTalentId) ||
                                  (formState.registerEmailType === 'new' && !registerEmailChecks.isExists)) &&
                                <span className="text-success">{t('appBase.Authorization.registerTalentId.emailValid')} {coloredIcon('yes')}</span>}
                                </>}
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            {registerEmailType === 'talent' &&
                            <FormText tag="span" className="text-success">
                                {talentData.isTalentEmailExists &&
                                <span>
                                    {t('appBase.Authorization.registerTalentId.registerEmailType.part1')}
                                </span>}
                                {!talentData.isTalentEmailExists &&
                                <span>
                                    {t('appBase.Authorization.registerTalentId.registerEmailType.part2')}
                                </span>}
                            </FormText>}

                            {registerEmailType === 'existing' &&
                            <FormText tag="span">
                                {t('appBase.Authorization.registerTalentId.registerEmailType.part3')}
                            </FormText>}

                            {registerEmailType === 'new' &&
                            <FormText tag="span">
                                {t('appBase.Authorization.registerTalentId.registerEmailType.part4')}
                                <span className="fw-bold">{email}</span>.
                                {t('appBase.Authorization.registerTalentId.registerEmailType.part5')}</FormText>}
                        </FormGroup>
                        {/*</FormGroup>*/}
                        <FormGroup className="text-center">
                            {((registerEmailType === 'talent' && !talentData.isTalentEmailExists) || registerEmailType === 'new') &&
                            <Button color="primary"
                                    onClick={onClick}
                                    disabled={!isValidRegisterEmail || loading}>
                                <span>{t('appBase.Authorization.registerTalentId.create')}</span>
                            </Button>}
                            {registerEmailType === 'talent' && talentData.isTalentEmailExists &&
                            <Button color="primary"
                                    onClick={onClick}
                                    disabled={!isValidRegisterEmail || loading}>
                                <span>{t('appBase.Authorization.registerTalentId.add')}</span>
                            </Button>}
                            {registerEmailType === 'existing' &&
                            <Link to={`/${uriMatch.lang}/auth/login`}
                                  state={{
                                      goBackLinkData: {to:
                                          addSearchParamToUrl(
                                              addSearchParamToUrl(
                                                  (goBackLinkData && goBackLinkData.to) || 'events',
                                                  'profile'),
                                              'addTalent')},
                                      info: t('appBase.Authorization.registerTalentId.login.part1')
                                  }}>{t('appBase.Authorization.registerTalentId.login.part2')}</Link>}
                        </FormGroup>
                        <FormGroup className="text-center">
                            <FormText>
                                <OldLinkWithoutNavigation onClick={() => {
                                    setFormState({...formState, email: "", info: "", error: ""});
                                    navigate(`/${uriMatch.lang}/auth/login`, {state: {goBackLinkData}});
                                }}>{t('appBase.Authorization.registerTalentId.cancel')}</OldLinkWithoutNavigation>
                            </FormText>
                        </FormGroup>
                        </>}
                    </Form>
                    </>}
                </div>
            </div>
        </div>);
});


export default Authorization;