import React, {Component} from 'react';
import {Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SimulationResultsBlock from "../fieldTemplates/SimulationResultsBlock";


export default class Block extends Component {
    constructor(props) {
        super(props);
        const {activeTab, sections} = props;
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: activeTab ? activeTab : sections && sections.length > 0 ? sections[0].key : null,
            lastSectionsLength: sections && sections.length
        };
    }

    componentWillReceiveProps(nextProps) {
        // if changed data we reset
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field) {
            this.setState((prevState) => ({
                activeTab:
                    !nextProps.sections || nextProps.sections.length === 0 ? null :
                    nextProps.sections.map(s => s.key).indexOf(prevState.activeTab) >= 0 ? prevState.activeTab :
                        nextProps.sections[0].key,
                lastSectionsLength: nextProps.sections && nextProps.sections.length
            }));
        } else {
            // should we react on sections change?
            const {shouldOpenNewSection, sections} = nextProps;
            if (sections) {
                const {activeTab, lastSectionsLength} = this.state;
                let newActiveTab = activeTab;
                // if new section we open it
                if (shouldOpenNewSection && lastSectionsLength < sections.length) {
                    if (sections[sections.length - 1].key !== '$new') {
                        newActiveTab = sections[sections.length - 1].key;
                    } else if (sections.length > 1) {
                        newActiveTab = sections[sections.length - 2].key;
                    }
                }
                // if section is removed we open first section
                if (sections.map(s => s.key).indexOf(activeTab) === -1) {
                    if (sections.length > 0 && sections[0].key !== '$new') {
                        newActiveTab = sections[0].key;
                    } else {
                        newActiveTab = null;
                    }
                }
                // saving new params
                this.setState((prevState) => ({
                    activeTab: newActiveTab,
                    lastSectionsLength: sections && sections.length
                }));
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render () {
        const {
            template, sections, children, dynamicSectionName, disabled, data, fieldsInfo, onChange,
            onValidate, collectionsDict, editLanguage, externalSections, name, wide, noLabel
        } = this.props;
        let {className} = this.props;

        let {activeTab} = this.state;

        const activeSection = sections && sections.find(s => s.key === activeTab);
        const activeSectionIndex = sections && sections.findIndex(s => s.key === activeTab);
        const resultingSections = sections ? sections.slice() : [];

        let components =
            children && React.Children
                .map(
                    children,
                    (child, i) =>
                        child && typeof(child.type) !== 'string'
                            ? React.cloneElement(child, {
                                key: i,
                                dynamicSectionName: (child.props && child.props.dynamicSectionName) || dynamicSectionName,
                                disabled: (child.props && child.props.disabled) || disabled,
                                data: (child.props && child.props.data) || data,
                                fieldsInfo: (child.props && child.props.fieldsInfo) || fieldsInfo,
                                onChange: (child.props && child.props.onChange) || onChange,
                                onValidate: (child.props && child.props.onValidate) || onValidate,
                                collectionsDict: (child.props && child.props.collectionsDict) || collectionsDict,
                                editLanguage: (child.props && child.props.editLanguage) || editLanguage,
                                wide: (child.props && child.props.wide) || wide,
                                noLabel: (child.props && child.props.noLabel) || noLabel})
                            : child)
                .filter(child => child);

        if (externalSections) {
            externalSections.forEach(s => {
                resultingSections.push({...s, external: true})
            });
        }

        const haveTabs = resultingSections && resultingSections.length > 0;

        let templateComponent = null;
        if (template) {
            let templateClass;
            switch (template) {
                case "simulationResults":
                    templateClass = SimulationResultsBlock;
                    templateComponent = React.createElement(templateClass, {}, components);
                    break;
                case "twoColumn":
                    className = classnames(className, "d-flex flex-row flex-wrap");
                    templateComponent = components;
                    break;
                default:
                    throw new Error(`Unknown template ${template}`);
            }
        } else {
            className = classnames(className, "d-flex flex-column");
        }

        const hasImages = resultingSections.some(section => section.className);

        return (
            <>
                {name &&
                    // TODO: заменить на configpageblock-name (а его переименовать)
                    <h6>{name}</h6>}

                {template &&
                <div className={className}>
                    {templateComponent && templateComponent}
                </div>}

                {!templateComponent && components && components.map(c =>
                        (c.props.section === undefined) && c)}

                {haveTabs &&
                <div className="flex-grow d-flex flex-column">
                    <div className="border border-dark border-top-0 border-end-0 border-start-0"
                         style={{position: "relative"}}>
                        <Nav tabs className="mb-3">
                            {resultingSections.map((section, i) =>
                                <NavItem key={i} style={{
                                    // only first external section should have this flag
                                    marginLeft: section.external && resultingSections.find(s => s.external) === section && 'auto'}}>
                                    <NavLink className={classnames({active: activeTab === section.key, disabled: section.disabled})}
                                             onClick={
                                                 section.disabled ? () => {} :
                                                 section.onClick ? () => section.onClick(section.key) : () => this.toggle(section.key)}>
                                        {hasImages
                                            ? [<div key="img" className={classnames("tab-icon", section.className ? section.className : "placeholder-tab-icon")}>
                                                {/*<img src={section.image} alt={section.name}/>*/}
                                               </div>,
                                                <span key="name">{section.name}</span>]
                                            : section.name}
                                    </NavLink>
                                </NavItem>)}
                        </Nav>
                        {activeSectionIndex >= 0 &&
                        <div style={{
                            content: "",
                            position: "absolute",
                            left: (100*(activeSectionIndex+0.5) + 7) + "px",
                            bottom: "-1px",
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderWidth: "0 10px 10px 10px",
                            borderColor: "transparent transparent #F7F5F7 transparent",
                            zIndex: 99
                        }} />}
                        {activeSectionIndex >= 0 &&
                        <div style={{
                            content: "",
                            position: "absolute",
                            left: (100*(activeSectionIndex+0.5) + 5) + "px",
                            bottom: "-1px",
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderWidth: "0 12px 12px 12px",
                            borderColor: "transparent transparent #C0CBDB transparent",
                            zIndex: 98
                        }} />}
                    </div>

                    <div className="workpanel-gray flex-grow">
                        {activeSection && activeSection.onRemoveSection &&
                        <div key="remove" className="float-end">
                            <span className="controlelement" onClick={activeSection.onRemoveSection}>
                                <i className="fa fa-times"/>
                                <span> remove</span>
                            </span>
                        </div>}
                        {activeSection &&
                        <h6>{activeSection.title}</h6>}
                        {components && components.filter(c => c.props.section === activeTab)}
                    </div>
                </div>}
                {!haveTabs && components && components.filter(c => c.props.section !== undefined)}

            </>);
    }
}