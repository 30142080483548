import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import classnames from 'classnames'

import DialogModal from 'components/modals/DialogModal'
import PanelModal from 'components/modals/PanelModal'

const containerPrefix = 'components.blocks.ContainersWithActions.';

class ActionConfirmationModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmationModal: false,
            showDeclinedModal: false,
            showProperties: false,
            modalNode: null
        };
        this.onAction = this.onAction.bind(this);
    }

    onAction(action, node) {
        // TODO: функция дублируется. надо создать только один раз
        const _t = (value) => this.props.t(containerPrefix + value);
        this.setState((prevState, props) =>    {
            prevState.modalNode = node;
            switch (action) {
                case "showConfirmation":
                    prevState.showConfirmationModal = true;
                    break;
                case "showDeclined":
                    prevState.showDeclinedModal = true;
                    break;
                case "showProperties":
                    prevState.showPropertiesModal = true;
                    break;
                case "hide":
                    prevState.showConfirmationModal = false;
                    prevState.showDeclinedModal = false;
                    prevState.showPropertiesModal = false;
                    break;
                default:
                    throw new Error(`${_t('unknown')}${action}!`);
            }
            return prevState;
        });
    }

    render() {
        const {t} = this.props;
        const _t = (value) => t(containerPrefix + value);

        const {showConfirmationModal, showDeclinedModal, showPropertiesModal, modalNode} = this.state;

        const childrenWithOnAction = React.Children.map(this.props.children, c => React.cloneElement(c, {onAction: this.onAction}));

        const removeConfirmation = () => {
            return <DialogModal show={showConfirmationModal} title={_t('actionConfirmation')} actions={[
                {
                    key: "remove",
                    name: _t('remove'),
                    color: "primary",
                    action: () => {
                        modalNode.actions.removeConfirmation();
                        this.onAction('hide');
                    }
                },
                {
                    key: "close",
                    name: _t('cancel'),
                    color: "secondary",
                    action: () => {this.onAction('hide');},
                }
            ]}>
                {_t('removeConfirmation.part1')}
                {modalNode.name}
                {_t('removeConfirmation.part2')}
            </DialogModal>
        };

        const removeDeclined = () => {
            return (
                <DialogModal show={showDeclinedModal} title={_t('removeDecline')} actions={[
                    {
                        key: "close",
                        name: "Cancel",
                        action: () => {this.onAction('hide');}
                    }
                ]}>
                    {modalNode.notRemovableInfo}
                </DialogModal>
            )
        };

        const showProperties = () => {
            return (
                <PanelModal show={showPropertiesModal} title={_t('details')} onClose={() => this.onAction('hide')}>
                    {modalNode.actions.showProperties()}
                </PanelModal>);
        };

        return (
            <div>
                {childrenWithOnAction}
                {showConfirmationModal && removeConfirmation()}
                {showDeclinedModal && removeDeclined()}
                {showPropertiesModal && showProperties()}
            </div>);
    }
}

export default withTranslation()(ActionConfirmationModalContainer);

const onNodeClick = (props) => {
    const {data, onAction} = props;
    const {actions, notSelectable} = data;
    const executeWithLock = props.executeWithLock ? props.executeWithLock : f => f();

    // select
    if (notSelectable) {
        // not selectable
    } else {
        if (actions && actions.hasOwnProperty('select') && actions.select) {
            executeWithLock(() => actions.select());
        }
    }
    // click
    if (actions && actions.hasOwnProperty('click') && actions.click) {
        executeWithLock(() => actions.click());
    }
    // showProperties
    if (actions && actions.hasOwnProperty('showProperties') && actions.showProperties) {
        executeWithLock(() => onAction('showProperties', data));
    }
};


const getActions = (props) => {
    const {data, onAction} = props;
    const executeWithLock = props.executeWithLock ? props.executeWithLock : f => f();

    let actions = [
        data.actions['create'] &&
        <i key="create" className="fa fa-plus" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.create();
            })
        }}/>,
        data.actions['view'] &&
        <i key="view" className="fa fa-eye" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.view();
            })
        }}/>,
        data.actions['moveFrom'] &&
        <i key="moveFrom" className="fa fa-arrow-circle-left me-2" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.moveFrom();
            })
        }}/>,
        data.actions['moveTo'] &&
        <i key="moveTo" className="fa fa-arrow-circle-right me-2" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.moveTo();
            })
        }}/>,
        data.actions['moveUp'] &&
        <i key="moveUp" className="fa fa-arrow-up me-2" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.moveUp();
            })
        }}/>,
        data.actions['moveDown'] &&
        <i key="moveDown" className="fa fa-arrow-down me-2" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.moveDown();
            })
        }}/>,
        data.actions['clone'] &&
        <i key="clone" className="fa fa-clone me-2" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.clone();
            })
        }}/>,
        data.actions['add'] &&
        <i key="add" className="fa fa-plus" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.add();
            })
        }}/>,
        data.actions['clean'] &&
        <i key="clean" className="fa fa-eraser" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.clean();
            })
        }}/>,
        data.actions['remove'] &&
        <i key="remove" className="fa fa-remove" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                data.actions.remove();
            })
        }}/>,
        data.actions['removeConfirmation'] &&
        <i key="removeConfirmation" className="fa fa-remove" onClick={(e) => {
            e.stopPropagation();
            executeWithLock(() => {
                if (!data.removable) {
                    onAction('showConfirmation', data);
                } else {
                    onAction('showDeclined', data);
                }
            });
        }}/>,
    ];
    return actions.filter(a => a);
};


export const DivWithActions = (props) => {
    const {className, data, children} = props;
    const hasActions = data.actions && Object.keys(data.actions).length > 0;
    return (
        <div className={classnames(className, hasActions && "hover-actions-container")} onClick={() => onNodeClick(props)}>
            {hasActions &&
            <div className="actions">{getActions(props)}</div>}
            {children}
        </div>);
};


export const LiWithActions = (props) => {
    const {className, data, children} = props;
    const hasActions = data.actions && Object.keys(data.actions).length > 0;
    return (
        <li className={classnames(className, hasActions && "hover-actions-container")} onClick={() => onNodeClick(props)}>
            {hasActions &&
            <div className="actions">{getActions(props)}</div>}
            {children}
        </li>);
};


export const TrWithActions = (props) => {
    const {className, data, children} = props;
    const hasActions = data.actions && Object.keys(data.actions).length > 0;

    const count = hasActions ? React.Children.count(children) : 0;
    const childrenWithActionsContainer = React.Children.map(
        children,
        (c, i) => {
            if (i === count - 1) {
                const tdChildren = React.Children.map(c.props.children, cc => cc);
                return (
                    <td>
                        <div className="hover-actions-container">
                            <div className="actions">{getActions(props)}</div>
                            {tdChildren}
                        </div>
                    </td>);
            } else {
                return c;
            }
        });
    return (
        <tr className={className} onClick={() => onNodeClick(props)}>
            {childrenWithActionsContainer}
        </tr>);
};