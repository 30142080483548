import {FALLBACK_PRIMARY_LANGUAGE} from 'i18n'

export const MAX_DATE = 4102444800000;      // Date.parse("2100-01-01")


const dateUtils = {

    getNowUTCDate: () => {
        const now = new Date();
        return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    },

    getLocaleStringTimeSpanBetween: (d1, d2, t, isDisplayAllMinorParts = true) => {
        // new.Date converts any date type (string/Date/Number of millis) to Date
        const milliSeconds = (new Date(d2)).getTime() - (new Date(d1)).getTime();
        let secondsLeft = Math.floor(milliSeconds / 1000);

        const divisors = [24 * 3600, 3600, 60];
        const results = [];

        for (let i = 0; i < divisors.length; i++) {
            results[i] = Math.floor(secondsLeft / divisors[i]);
            secondsLeft = secondsLeft % divisors[i];
        }
        results.push(secondsLeft);

        let result = '';
        let shouldDisplayMinorParts = false;
        if (results[0] > 0) {
            result += results[0] + t('utils.dateUtils.days') + " ";
            shouldDisplayMinorParts = isDisplayAllMinorParts;
        }
        if (results[1] > 0 || shouldDisplayMinorParts) {
            result += results[1] + t('utils.dateUtils.hours') + " ";
            shouldDisplayMinorParts = isDisplayAllMinorParts;
        }
        if (results[2] > 0 || shouldDisplayMinorParts) {
            result += results[2] + t('utils.dateUtils.minutes') + " ";
            shouldDisplayMinorParts = isDisplayAllMinorParts;
        }
        if (results[3] > 0 || shouldDisplayMinorParts) {
            result += results[3] + t('utils.dateUtils.seconds');
        }
        return result;
    },

    //is not using now
    getShortLocaleStringTimeSpanBetween: (d1, d2, t) => {
        // new.Date converts any date type (string/Date/Number of millis) to Date
        const milliSeconds = (new Date(d2)).getTime() - (new Date(d1)).getTime();
        let secondsLeft = Math.floor(milliSeconds / 1000);

        const divisors = [24 * 3600, 3600, 60];
        const results = [];

        for (let i = 0; i < divisors.length; i++) {
            results[i] = Math.floor(secondsLeft / divisors[i]);
            secondsLeft = secondsLeft % divisors[i];
        }
        results.push(secondsLeft);

        if (results[0] > 0) {
            return results[0] + t('utils.dateUtils.days');
        }
        if (results[1] > 0) {
            return results[1] + t('utils.dateUtils.hours');
        }
        if (results[2] > 0) {
            return results[2] + t('utils.dateUtils.minutes');
        }
        if (results[3] >= 0) {
            return results[3] + t('utils.dateUtils.seconds');
        }
        return t('utils.dateUtils.noData');
    },

    dateToLocaleDateString: (date, i18n) => {
        // new.Date converts any date type (string/Date/Number of millis) to Date
        return (new Date(date)).toLocaleString(i18n.resolvedLanguage || FALLBACK_PRIMARY_LANGUAGE, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    },

    dateToLocaleDateTimeString: (date, i18n) => {
        // new.Date converts any date type (string/Date/Number of millis) to Date
        return (new Date(date)).toLocaleString(i18n.resolvedLanguage || FALLBACK_PRIMARY_LANGUAGE, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            // seconds: withSeconds ? 'numeric' : undefined
        });
    },

    dateToIsoString: (date, withT) => {
        // new.Date converts any date type (string/Date/Number of millis) to Date
        const isoString = (new Date(date)).toISOString();
        return withT ? isoString : isoString.replace('T', ' ');
    },

    dateToFilenameDateString: (date) => {
        return dateUtils.dateToIsoString(date).split(":").filter((_, i, arr) => i < arr.length - 1).join("").replace(" ", "-");
    },

    dateToPrintDocumentDateString: (date) => {
        return `«${String(date.getDate()).padStart(2, "0")}» ${String(date.getMonth() + 1).padStart(2, "0")} ${date.getFullYear()}`;
    },

    getMinOfExistingDates: (d1, d2) => {
        if (!d1 && !d2) {
            return null;
        } else if (d1 && !d2) {
            return d1;
        } else if (!d1 && d2) {
            return d2;
        } else {
            // new.Date converts any date type (string/Date/Number of millis) to Date
            return (new Date(d1)) < (new Date(d2)) ? d1 : d2;
        }
    },

    getMaxOfExistingDates: (d1, d2) => {
        if (!d1 && !d2) {
            return null;
        } else if (d1 && !d2) {
            return d1;
        } else if (!d1 && d2) {
            return d2;
        } else {
            // new.Date converts any date type (string/Date/Number of millis) to Date
            return (new Date(d1)) > (new Date(d2)) ? d1 : d2;
        }
    }
};

export default dateUtils;