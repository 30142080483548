import React from 'react'
import {useTranslation} from 'react-i18next'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useUTCTimeout from 'hooks/useUTCTimeout'
import useForceRender from 'hooks/useForceRender'
import classnames from 'classnames'

import {Badge} from 'reactstrap'
import MarkdownRender from 'components/elements/MarkdownOutput'
import NamedBlock from 'components/blocks/NamedBlock'
import UTCTimerSpan from 'components/elements/UTCTimerSpan'

import dateUtils, {MAX_DATE} from 'utils/dateUtils'


export const calculateEventCompetitionModeDates = (event, eventUserInfo) => {
    const UTCDateTimestamp = dateUtils.getNowUTCDate().getTime();
    let isCompetitionSessionMode = false;
    let competitionRegistrationEndsAtUTCTimestamp = null;
    let competitionSessionStartsAtUTCTimestamp = null;
    let competitionSessionEndsAtUTCTimestamp = null;
    if (event) {
        const eventEndsAtUTCTimestamp = Date.parse(event.endsAt) || MAX_DATE;    // MAX_DATE if undefined
        // do we have competition mode with limited session time?
        isCompetitionSessionMode = event.isCompetitionMode && event.competitionRules && !!event.competitionRules.allowedSolutionTimeMinutes;
        if (isCompetitionSessionMode) {
            // setting competitionRegistrationEndsAtUTCTimestamp
            competitionRegistrationEndsAtUTCTimestamp = eventEndsAtUTCTimestamp;
            if (competitionRegistrationEndsAtUTCTimestamp !== MAX_DATE) {
                competitionRegistrationEndsAtUTCTimestamp -= event.competitionRules.allowedSolutionTimeMinutes * 60 * 1000;
            }
            // do we have a started competition session?
            if (eventUserInfo && eventUserInfo.competitionSessionStartedAt) {
                // yes, we have a started competition session
                competitionSessionStartsAtUTCTimestamp = Date.parse(eventUserInfo.competitionSessionStartedAt) || null;
                if (competitionSessionStartsAtUTCTimestamp) {
                    competitionSessionEndsAtUTCTimestamp =
                        competitionSessionStartsAtUTCTimestamp + event.competitionRules.allowedSolutionTimeMinutes * 60 * 1000;
                }
            }
        }
    }
    const isCompetitionRegistrationEnded = isCompetitionSessionMode &&
        competitionRegistrationEndsAtUTCTimestamp && competitionRegistrationEndsAtUTCTimestamp <= UTCDateTimestamp;
    const isCompetitionSessionStarted = isCompetitionSessionMode &&
        competitionSessionStartsAtUTCTimestamp && competitionSessionStartsAtUTCTimestamp <= UTCDateTimestamp;
    const isCompetitionSessionEnded = isCompetitionSessionMode &&
        competitionSessionEndsAtUTCTimestamp && competitionSessionEndsAtUTCTimestamp <= UTCDateTimestamp;
    return {isCompetitionSessionMode,
        competitionRegistrationEndsAtUTCTimestamp,
        competitionSessionStartsAtUTCTimestamp,
        competitionSessionEndsAtUTCTimestamp,
        isCompetitionRegistrationEnded,
        isCompetitionSessionStarted,
        isCompetitionSessionEnded};
};


const EventCard = (props) => {

    const {event, isLarge, allowUnpublished} = props;

    const navigate = useSmartNavigate();
    const forceRender = useForceRender();
    const {t} = useTranslation();

    // force render if time

    const UTCDateTimestamp = dateUtils.getNowUTCDate().getTime();
    const eventStartsAtUTCTimestamp = Date.parse(event.startsAt) || 0;       // 0 if undefined
    const eventEndsAtUTCTimestamp = Date.parse(event.endsAt) || MAX_DATE;    // MAX_DATE if undefined
    const isEventStarted = eventStartsAtUTCTimestamp <= UTCDateTimestamp;
    const isEventEnded = eventEndsAtUTCTimestamp <= UTCDateTimestamp;
    const {
        isCompetitionSessionMode,
        competitionRegistrationEndsAtUTCTimestamp,
        competitionSessionStartsAtUTCTimestamp,
        competitionSessionEndsAtUTCTimestamp,
        isCompetitionRegistrationEnded,
        isCompetitionSessionStarted,
        isCompetitionSessionEnded} = calculateEventCompetitionModeDates(event, event.eventUserInfo);

    // event timeout moments
    useUTCTimeout(
        forceRender,
        !isEventStarted && eventStartsAtUTCTimestamp ? eventStartsAtUTCTimestamp :
            !isCompetitionRegistrationEnded && competitionRegistrationEndsAtUTCTimestamp ? competitionRegistrationEndsAtUTCTimestamp :
                !isEventEnded && eventEndsAtUTCTimestamp ? eventEndsAtUTCTimestamp :
                    null
    );
    // competition session timeout moments
    useUTCTimeout(
        forceRender,
        !isCompetitionSessionStarted && competitionSessionStartsAtUTCTimestamp ? competitionSessionStartsAtUTCTimestamp :
            !isCompetitionSessionEnded && competitionSessionEndsAtUTCTimestamp ? competitionSessionEndsAtUTCTimestamp :
                null
    );

    const onClick = props.onClick ||
        ((e) => {
            navigate(`${props.uri}/${event.id}`);
        });

    // rendering

    const publishedButNoAccess = event.isPublished && !event.isTester && !event.isStudent && (!event.isInfo || event.visibilityType !== 'public');

    const notActive = (!event.isPublished && !allowUnpublished ) || publishedButNoAccess ||
        !isEventStarted || isEventEnded || (isCompetitionSessionMode && isCompetitionSessionEnded);
    const canBeClicked = ((event.isPublished && !publishedButNoAccess) || allowUnpublished) && isEventStarted;
    const textClassName = classnames(
        notActive && "text-light"
    );

    // cases
    let timerLabel = null;
    let timerEndsAtUTC = null;
    if (event.type !== "course" && !isEventEnded) {
        if (!isEventStarted && eventStartsAtUTCTimestamp) {
            // event not started
            timerLabel = t('appEvent.views.components.EventCard.notStartedEvent');
            timerEndsAtUTC = eventStartsAtUTCTimestamp;
        } else {
            // event started
            if (competitionSessionStartsAtUTCTimestamp) {
                // competition session exists
                if (!isCompetitionSessionStarted) {
                    timerLabel = t('appEvent.views.components.EventCard.notStartedCompetitionSession');
                    timerEndsAtUTC = competitionSessionStartsAtUTCTimestamp;
                } else {
                    // competition session started
                    if (!isCompetitionSessionEnded && competitionSessionEndsAtUTCTimestamp) {
                        timerLabel = t('appEvent.views.components.EventCard.notEndedCompetitionSession');
                        timerEndsAtUTC = competitionSessionEndsAtUTCTimestamp;
                    } else {
                        // competition session ended
                        timerLabel = t('appEvent.views.components.EventCard.notEndedEvent');
                        timerEndsAtUTC = eventEndsAtUTCTimestamp;
                    }
                }
            } else {
                // no competition session exists
                if (!isCompetitionRegistrationEnded && competitionRegistrationEndsAtUTCTimestamp) {
                    // competition registration exists
                    timerLabel = t('appEvent.views.components.EventCard.notEndedCompetitionRegistration');
                    timerEndsAtUTC = competitionRegistrationEndsAtUTCTimestamp;
                } else {
                    // no competition registration exists
                    timerLabel = t('appEvent.views.components.EventCard.notEndedEvent');
                    timerEndsAtUTC = eventEndsAtUTCTimestamp;
                }
            }
        }
    }

    return (<div className={classnames("col-12 mb-2", isLarge ? "col-md-12": "col-md-6")}>

        <NamedBlock className={classnames("p-3 h-100", notActive && "bg-secondary")}
                    name={<span>
                        <>
                            {event.isAdmin &&
                                <><Badge color="warning">{t('appEvent.views.components.EventCard.admin')}</Badge><br/></>}
                            {event.isAdmin && event.isStudent && event.type !== 'course' &&
                                <><Badge color="danger">{t('appEvent.views.components.EventCard.youAreStudent')}</Badge><br/></>}
                            {event.isTester &&
                                <><Badge color="secondary">{t('appEvent.views.components.EventCard.testMode')}</Badge><br/></>}

                            {!event.isPublished && !allowUnpublished &&
                                <><Badge color="danger">{t('appEvent.views.components.EventCard.notPublished')}</Badge><br/></>}
                            {publishedButNoAccess && !event.visibilityType &&
                                <><Badge color="warning">{t('appEvent.views.components.EventCard.publishedButNoAccess')}</Badge><br/></>}
                            {publishedButNoAccess && event.visibilityType === 'hidden' &&
                                <><Badge color="warning">{t('appEvent.views.components.EventCard.hidden')}</Badge><br/></>}
                            {event.isInfo && !event.isRegistrationAllowed &&
                                <><Badge color="danger">{t('appEvent.views.components.EventCard.noAccess')}</Badge><br/></>}
                            {event.isInfo && event.visibilityType === 'public' && event.isRegistrationAllowed && !event.isVisibleWithoutRegistration &&
                                <><Badge color="success">{t('appEvent.views.components.EventCard.register')}</Badge><br/></>}
                        </>
                        {event.name}
                        </span>}
                    onClick={canBeClicked && onClick}
                    nameClassName={textClassName}>
            {timerLabel && timerEndsAtUTC > 0 && timerEndsAtUTC < MAX_DATE &&
            <div className="bg-light p-3 mb-3 border border-warning text-danger">
                {timerLabel}
                <UTCTimerSpan endsAtUTC={timerEndsAtUTC} />
            </div>}
            {event.imageUrl &&
            <img className="float-end mb-3" src={event.imageUrl} alt={event.name} />}
            <MarkdownRender value={event.description} className={textClassName} />
        </NamedBlock>
    </div>);
};

export default EventCard;