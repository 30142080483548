import {graphql} from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import queryUtils from 'utils/queryUtils'
import {licenseFields} from 'appLicense/data/licenseQueries'
import {MSEC_10_MINUTES, MSEC_10_SECONDS} from 'constants/constants'

const LicenseQuery = gql`
  query LicenseQuery($id: ID!) {
    license(id: $id) {
        ${licenseFields}
        disabled
    }
  }
`;


const UserLicensesQuery = gql`
  query UserLicensesQuery {
    userLicenses {
      id
      name
      disabled
    }
  }
`;


const licenseEventsFields = `
      id
      name
      type
      startsAt
      endsAt
      isPublished
      isArchived
      studentGroupId
      testerGroupId
      isCompetitionMode`;


const licenseEventsWithScenariosFields = `
      ${licenseEventsFields}
      scenarios {
        id
        eventId
        parentId
        order
        name
        type
        startsAt
        endsAt
        scenarioRequirements {
          id
          minimumScore
          dependsOnScenario {
            name
            }
          }
        }`;


const LicenseEventsWithScenariosQuery = gql`
  query LicenseEventsWithScenariosQuery($licenseId: ID!, $showArchived: Boolean) {
    licenseEventsWithScenarios(licenseId: $licenseId, showArchived: $showArchived) {
        ${licenseEventsWithScenariosFields}
    }
  }
`;


export const licenseEventFields =`
      id
      licenseId
      name
      nameMl
      type
      description
      descriptionMl
      imageUrl
      startsAt
      endsAt
      welcomeTitle
      welcomeTitleMl
      welcomeMessage
      welcomeMessageMl
      studentGroupId
      testerGroupId
      isArchived
      isPublished
      isCompetitionMode
      competitionRules
      isUserTeams
      visibilityType
      isRegistrationAllowed
      isVisibleWithoutRegistration
      isTournamentTableVisible
      teamMembersLimit
      isOntiMode
      ontiParameters
      participantsUrl
      selfregistrationUrl
      isSuperlicense
      studentSolutionsNumber
      hasScenarioSimOrConstellationOrProg
      hasScenarioSimOrConstellationOrProgOrTest
      hasSuccessfullyCommittedSimulations
`;


const LicenseEventQuery = gql`
  query LicenseEventQuery($id: ID!) {
    licenseEvent(id: $id) {
      ${licenseEventFields}
    }
  }
`;


export const licenseScenarioFields =`
      id
      eventId
      parentId
      order
      name
      nameMl
      type
      imageUrl
      description
      descriptionMl
      descriptionShort
      descriptionShortMl
      descriptionParameters
      descriptionParametersMl
      descriptionScore
      descriptionScoreMl
      startsAt
      endsAt
      initialParameters
      prototypeParameters
      prototypeResults
      simulationTemplate
      scoringRules
      isEvaluation
      parameters
      isIndividual
      teamMaxSubmitsDifference
      show3dResults
      resultsDecimation
      maxSolutionsNumber
      studentSolutionsNumber
      hasSuccessfullyCommittedSolutions
      hasSuccessfullyCommittedSimulations
      scenarioUserCustomizationStatus
`;


const LicenseScenarioQuery = gql`
  query LicenseScenarioQuery($id: ID!) {
    licenseScenario(id: $id) {
      ${licenseScenarioFields}
    }
  }
`;


const StudentsWithUsersQuery = gql`
  query StudentsWithUsersQuery($licenseId: ID!, $eventId: ID) {
    studentsWithUsers(licenseId: $licenseId, eventId: $eventId) {
      student {
        id
        email
      }
      user {
        id
        name
        active
        parameters
      }
    }
  }
`;


const StudentGroupsQuery = gql`
  query StudentGroupsQuery($licenseId: ID!) {
    studentGroups(licenseId: $licenseId) {
      id
      name
    }
  }
`;


export const studentGroupAssignmentsFields = `
      id
      student {
        id
        email
      }
      studentGroup {
        id
        name
      }`;


const StudentGroupAssignmentsQuery = gql`
  query StudentGroupAssignmentsQuery($licenseId: ID!, $groupId: ID) {
    studentGroupAssignments(licenseId: $licenseId, groupId: $groupId) {
      ${studentGroupAssignmentsFields}
    }
  }
`;


const StudentTeamsQuery = gql`
  query StudentTeamsQuery($licenseId: ID!, $eventId: ID) {
    studentTeams(licenseId: $licenseId, eventId: $eventId) {
      id
      name
      event {
        id
        name
        studentGroupId
        testerGroupId
      }
    }
  }
`;


export const studentTeamAssignmentsFields = `
      id
      isInStudentGroup
      student {
        id
        email
      }
      studentTeam {
        id
        name
      }
      event {
        id
        name
      }`;


const StudentTeamAssignmentsQuery = gql`
  query StudentTeamAssignmentsQuery($licenseId: ID!, $eventId: ID, $teamId: ID) {
    studentTeamAssignments(licenseId: $licenseId, eventId: $eventId, teamId: $teamId) {
        ${studentTeamAssignmentsFields}
    }
  }
`;


const ImagesQuery = gql`
  query ImagesQuery($licenseId: ID, $showCatalogImages: Boolean) {
    images(licenseId: $licenseId, showCatalogImages: $showCatalogImages) {
      id
      name
      filename
      isVector
      format
      width
      height
      sizeKb
      thumbnail
    }
  }
`;


const LicenseEventsQuery = gql`
  query LicenseEventsQuery($licenseId: ID!, $showArchived: Boolean) {
    licenseEvents(licenseId: $licenseId, showArchived: $showArchived) {
        ${licenseEventsFields}
    }
  }
`;


export const LicenseEventSolutionsWithTeamsQuery = gql`
  query LicenseEventSolutionsWithTeamsQuery($eventId: ID!) {
    licenseEventSolutionsWithTeams(eventId: $eventId) {
      solution {
        id
        status
        number
        score
        createdAt
        modifiedAt
        user {
          id
          name
          email
          talentId
        }
        scenario {
          id
          name
          event {
            id
            name
          }
        }
      }
      studentTeam {
        id
        name
      }
    }
  }
`;


const LicenseStatisticsQuery = gql`
  query LicenseStatisticsQuery($licenseId: ID!) {
    licenseStatistics(licenseId: $licenseId) {
      isSuperlicense
      activeStudentsCount
      activeEventsCount
      studentsLimit
      eventsLimit
      dataSize
      dataLimit
    }
  }
`;


const EventStatisticsQuery = gql`
  query EventStatisticsQuery($eventId: ID!) {
    eventStatistics(eventId: $eventId) {
      dataSize
    }
  }
`;


const UsersRegistrationsQuery = gql`
  query UsersRegistrationsQuery($licenseId: ID!) {
    usersRegistrations(licenseId: $licenseId) {
      id
      eventId
      subject
      event {
        id
        name
      }
      usersCount
    }
  }
`;


const ScoreCorrectionsQuery = gql`
  query ScoreCorrectionsQuery($eventId: ID!) {
    scoreCorrections(eventId: $eventId) {
      id
      studentId
      studentTeamId
      score
      achievement
      student {
        id
        email
      }
      user {
        id
        name
        active
        parameters
      }
      studentTeam {
        id
        name
      }
    }
  }
`;


const EventUserInfosQuery = gql`
  query EventUserInfosQuery($eventId: ID!) {
    eventUserInfos(eventId: $eventId) {
      id
      eventId
      competitionSessionStartedAt
      user {
        id
        name
        email
      }
      userSolutionsNumber
    }
  }
`;


export const queryLicense =
    graphql(LicenseQuery, {
        options: ({licenseId}) => ({
            variables: {id: licenseId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {license: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {license: data.error && data.error.message}),
            license: data.license
        })
    });


export const queryUserLicenses =
    graphql(UserLicensesQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {userLicenses: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {userLicenses: data.error && data.error.message}),
            userLicenses: data.userLicenses
        })
    });


export const queryLicenseEventsWithScenarios =
    graphql(LicenseEventsWithScenariosQuery, {
        options: ({licenseId, showArchived}) => ({
            variables: {licenseId: licenseId, showArchived: showArchived},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseEventsWithScenarios: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseEventsWithScenarios: data.error && data.error.message}),
            licenseEventsWithScenarios: data.licenseEventsWithScenarios
        })
    });


export const queryLicenseEvent =
    graphql(LicenseEventQuery, {
        options: ({eventId}) => ({
            variables: { id: eventId },
            fetchPolicy: 'network-only'
        }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseEvent: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseEvent: data.error && data.error.message}),
            licenseEvent: data.licenseEvent
        })
    });


export const queryLicenseScenario =
    graphql(LicenseScenarioQuery, {
        options: ({scenarioId}) => ({
            variables: { id: scenarioId },
            fetchPolicy: 'network-only'
        }),
        skip: ({scenarioId}) => !scenarioId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseScenario: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseScenario: data.error && data.error.message}),
            licenseScenario: data.licenseScenario,
            // optional, because refetch does not exists everywhere
            refetch: queryUtils.mergeWith(ownProps.refetch, {licenseScenario: data.refetch}, true)
        })
    });


export const queryStudentsWithUsers =
    graphql(StudentsWithUsersQuery, {
        options: ({licenseId, eventId}) => ({
            variables: {licenseId: licenseId, eventId: eventId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {studentsWithUsers: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {studentsWithUsers: data.error && data.error.message}),
            studentsWithUsers: data.studentsWithUsers
        })
    });


export const queryStudentGroups =
    graphql(StudentGroupsQuery, {
        options: ({licenseId}) => ({
            variables: {licenseId: licenseId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {studentGroups: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {studentGroups: data.error && data.error.message}),
            studentGroups: data.studentGroups
        })
    });


export const queryStudentGroupAssignments =
    graphql(StudentGroupAssignmentsQuery, {
        options: ({licenseId, groupId}) => ({
            variables: {licenseId: licenseId, groupId: groupId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {studentGroupAssignments: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {studentGroupAssignments: data.error && data.error.message}),
            studentGroupAssignments: data.studentGroupAssignments
        })
    });


export const queryStudentTeams =
    graphql(StudentTeamsQuery, {
        options: ({licenseId, eventId}) => ({
            variables: {licenseId: licenseId, eventId: eventId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {studentTeams: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {studentTeams: data.error && data.error.message}),
            studentTeams: data.studentTeams
        })
    });


export const queryStudentTeamAssignments =
    graphql(StudentTeamAssignmentsQuery, {
        options: ({licenseId, eventId, teamId}) => ({
            variables: {licenseId: licenseId, eventId: eventId, teamId: teamId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {studentTeamAssignments: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {studentTeamAssignments: data.error && data.error.message}),
            studentTeamAssignments: data.studentTeamAssignments
        })
    });


export const queryImages =
    graphql(ImagesQuery, {
        options: ({licenseId, queryShowCatalogImages}) => ({
            variables: {
                licenseId: licenseId,
                showCatalogImages: queryShowCatalogImages
            },
            fetchPolicy: 'network-only' }),
        skip: ({licenseId, queryShowCatalogImages}) => licenseId === undefined,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {images: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {images: data.error && data.error.message}),
            images: data.images
        })
    });


export const queryLicenseEvents =
    graphql(LicenseEventsQuery, {
        options: ({licenseId, showArchived}) => ({
            variables: {licenseId: licenseId, showArchived: showArchived},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseEvents: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseEvents: data.error && data.error.message}),
            licenseEvents: data.licenseEvents
        })
    });


export const queryLicenseEventSolutionsWithTeams =
    graphql(LicenseEventSolutionsWithTeamsQuery, {
        options: ({eventId}) => ({
            variables: {eventId: eventId},
            fetchPolicy: 'network-only' }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseEventSolutionsWithTeams: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseEventSolutionsWithTeams: data.error && data.error.message}),
            licenseEventSolutionsWithTeams: data.licenseEventSolutionsWithTeams
        })
    });


export const queryLicenseStatistics =
    graphql(LicenseStatisticsQuery, {
        options: ({licenseId}) => ({
            variables: {licenseId: licenseId},
            fetchPolicy: 'network-only',
            pollInterval: MSEC_10_MINUTES }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseStatistics: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseStatistics: data.error && data.error.message}),
            licenseStatistics: data.licenseStatistics
        })
    });


export const queryEventStatistics =
    graphql(EventStatisticsQuery, {
        options: ({eventId}) => ({
            variables: {eventId: eventId},
            fetchPolicy: 'network-only',
            pollInterval: MSEC_10_MINUTES }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {eventStatistics: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {eventStatistics: data.error && data.error.message}),
            eventStatistics: data.eventStatistics
        })
    });


export const queryUsersRegistrations =
    graphql(UsersRegistrationsQuery, {
        options: ({licenseId}) => ({
            variables: {licenseId: licenseId},
            fetchPolicy: 'network-only',
            pollInterval: MSEC_10_SECONDS }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {usersRegistrations: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {usersRegistrations: data.error && data.error.message}),
            usersRegistrations: data.usersRegistrations
        })
    });


export const queryScoreCorrections =
    graphql(ScoreCorrectionsQuery, {
        options: ({eventId}) => ({
            variables: {eventId: eventId},
            fetchPolicy: 'network-only' }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {scoreCorrections: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {scoreCorrections: data.error && data.error.message}),
            scoreCorrections: data.scoreCorrections
        })
    });


export const queryEventUserInfos =
    graphql(EventUserInfosQuery, {
        options: ({eventId}) => ({
            variables: {eventId: eventId},
            fetchPolicy: 'network-only' }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {eventUserInfos: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {eventUserInfos: data.error && data.error.message}),
            eventUserInfos: data.eventUserInfos
        })
    });