import React from 'react'
import {useTranslation} from 'react-i18next'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useUTCTimeout from 'hooks/useUTCTimeout'
import useForceRender from 'hooks/useForceRender'
import classnames from 'classnames'

import MarkdownRender from 'components/elements/MarkdownOutput'
import NamedBlock from 'components/blocks/NamedBlock'
import UTCTimerSpan from 'components/elements/UTCTimerSpan'

import dateUtils, {MAX_DATE} from 'utils/dateUtils'
import {faIcon} from 'utils/visualHelpers'
import {calculateEventCompetitionModeDates} from "./EventCard";


const ScenarioCard = (props) => {

    const {event, eventUserInfo, scenario} = props;

    const navigate = useSmartNavigate();
    const forceRender = useForceRender(); // force render if time
    const {t} = useTranslation();

    const UTCDateTimestamp = dateUtils.getNowUTCDate().getTime();
    const eventStartsAtUTCTimestamp = Date.parse(event.startsAt) || 0 ;         // 0 if undefined
    const eventEndsAtUTCTimestamp = Date.parse(event.endsAt) || MAX_DATE;       // MAX_DATE if undefined
    const scenarioStartsAtUTCTimestamp = Date.parse(scenario.startsAt) || 0;    // 0 if undefined
    const scenarioEndsAtUTCTimestamp = Date.parse(scenario.endsAt) || MAX_DATE; // MAX_DATE if undefined
    const {
        isCompetitionSessionMode,
        competitionSessionStartsAtUTCTimestamp,
        competitionSessionEndsAtUTCTimestamp,
        isCompetitionSessionStarted,
        isCompetitionSessionEnded} = calculateEventCompetitionModeDates(event, eventUserInfo);
    const startsAtUTCTimestamp = isCompetitionSessionMode
        ? competitionSessionStartsAtUTCTimestamp || MAX_DATE
        : dateUtils.getMaxOfExistingDates(eventStartsAtUTCTimestamp, scenarioStartsAtUTCTimestamp);
    const endsAtUTCTimestamp = isCompetitionSessionMode
        ? competitionSessionEndsAtUTCTimestamp || MAX_DATE
        : dateUtils.getMinOfExistingDates(eventEndsAtUTCTimestamp, scenarioEndsAtUTCTimestamp);
    const isStarted = startsAtUTCTimestamp <= UTCDateTimestamp;
    const isEnded = endsAtUTCTimestamp <= UTCDateTimestamp;
    useUTCTimeout(
        forceRender,
        !isStarted ? startsAtUTCTimestamp : !isEnded ? endsAtUTCTimestamp : null
    );

    const onClick = (e) => {
        navigate(`${props.uri}/${scenario.id}`);
    };

    // requirements

    const getRequirements = () => {
        const {user} = props;
        const requirements = [];

        if (!isStarted && isCompetitionSessionMode) {
            requirements.push(
                <div key={1 + requirements.length}>
                    {t('appEvent.views.components.ScenarioCard.notStartedCompetitionSession')}
                </div>);
        }
        if (isEnded && isCompetitionSessionMode) {
            requirements.push(
                <div key={1 + requirements.length}>
                    {t('appEvent.views.components.ScenarioCard.endedCompetitionSession')}
                </div>);
        }
        if (!isStarted && startsAtUTCTimestamp < MAX_DATE) {
            requirements.push(
                <div key={1 + requirements.length}>
                    {t('appEvent.views.components.ScenarioCard.notStarted')}
                    <UTCTimerSpan endsAtUTC={startsAtUTCTimestamp} />
                </div>);
        }

        if (scenario.reguiresScenarios && scenario.reguiresScenarios.length) {
            scenario.reguiresScenarios.forEach((e) => {
                const currentResults = user &&
                    user.scores &&
                    user.scores.find(s => s.id === e.id);

                if (!currentResults ||
                    !currentResults.score ||
                    currentResults.score < e.score) {
                    requirements.push(<div key={1 + requirements.length}>
                        {t('appEvent.views.components.ScenarioCard.requirements.part1') +
                        event.scenarios.find(s => s.id === e.id).name +
                        t('appEvent.views.components.ScenarioCard.requirements.part2') + e.score +
                        t('appEvent.views.components.ScenarioCard.requirements.part3')}</div>);
                }});
        }

        return requirements;
    };

    const requirements = getRequirements();

    const isDisableAnyAccessToScenarioInCompetitionMode = isCompetitionSessionMode && (!isCompetitionSessionStarted || isCompetitionSessionEnded);

    // rendering

    const bgClassName = classnames(
        "col-10 col-md-5 p-3 m-4",
        (requirements.length || !isStarted || isEnded) && "bg-secondary"
    );
    const textClassName = classnames(
        (requirements.length || !isStarted || isEnded) && "text-light"
    );

    return (
        <NamedBlock className={bgClassName}
                    name={scenario.name}
                    onClick={isStarted && !isDisableAnyAccessToScenarioInCompetitionMode && onClick}
                    nameClassName={textClassName}>
            {requirements.length > 0 &&
            <div className="bg-light p-3 mb-3 border border-warning text-danger">{requirements}</div>}
            {scenario.imageUrl &&
            <img className="float-end" src={scenario.imageUrl} alt={scenario.name} />}
            {requirements.length === 0 && isStarted && !isEnded && endsAtUTCTimestamp < MAX_DATE &&
            <span className={textClassName}>
                {t('appEvent.views.components.ScenarioCard.timeLeft')}<br/>
                <UTCTimerSpan endsAtUTC={endsAtUTCTimestamp} />
            </span>}
            {isEnded &&
            <span className={textClassName}>{t('appEvent.views.components.ScenarioCard.isEnded')}</span>}
            <MarkdownRender value={scenario.descriptionShort} className={textClassName} />
            {(scenario.hasUserSolution || scenario.hasTeamSolution) &&
            <span className="text-success">
                {faIcon('fa-check')} {scenario.hasUserSolution ? t('appEvent.views.components.ScenarioCard.solutionSend') : t('appEvent.views.components.ScenarioCard.teamSolutionSend')}
            </span>}
        </NamedBlock>);
};

export default ScenarioCard;