import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Col} from 'reactstrap'
import classnames from 'classnames'
import compose from 'lodash/flowRight'

import PanelModal from 'components/modals/PanelModal'
import Tree from 'components/blocks/Tree'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import {JSONText} from 'components/fields/JSONTextVal'
import LabelWithInfo from './elements/LabelWithInfo'
import Messages from 'components/elements/Messages'

import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import entityUtils from 'utils/entityUtils'
import {translateObjOrArray} from 'utils/translationUtils'

import fieldPrototypes from './data/fieldPrototypes'

const existingElements = [];

for (const type in fieldPrototypes) {
    existingElements.push({
        type: type,
        name: fieldPrototypes[type].name,
        fields: fieldPrototypes[type].fields.map(f => f.field)
    });
}

// transform from json to PrototypeEditor inner structure
const transformFromJSON = (jsonElements, canvases, currentCanvasId) => {

    jsonElements && jsonElements.forEach((element, i) => {
        //looking for current element in existing elements
        const existingElement = existingElements.find(e => e.type === element.type);

        //checking for unknown fields of current element
        const unknownFields = existingElement && Object.keys(element).filter(field => !existingElement.fields.includes(field));
        if (!existingElement) {
            element.isUnknown = true;
        } else if (unknownFields.length > 0) {
            element.unknownFields = unknownFields;
        }

        switch (element.type) {
            /*
            case "block": {
                const {items, ...updatedElement} = element;
                updatedElement.canvasId = canvases[canvases.length - 1].headers.id + 1;

                //json may contain `items` field while prototype does not
                updatedElement.unknownFields = updatedElement.unknownFields.filter(field => field !== "items");

                canvases.push({
                    headers: {
                        id: updatedElement.canvasId,
                        parentId: currentCanvasId,
                        name: updatedElement.name ? updatedElement.name: `Canvas ${updatedElement.canvasId}`
                    },
                    elements: []
                });

                transformFromJSON(items, canvases, updatedElement.canvasId);
                canvases[currentCanvasId].elements.push({id: i, ...updatedElement});
                break;
            }
            case "choices": {
                const {stuff, items, ...updatedElement} = element;

                //json may contain `stuff` field while prototype does not
                updatedElement.unknownFields = updatedElement.unknownFields.filter(field => field !== "stuff");

                updatedElement.items =
                    items.filter(item => item.type === "choice")
                         .map((item, j) => {
                             const newId = canvases[canvases.length - 1].headers.id + j + 1;
                             const stuffMatched = stuff.find(stuffElement => stuffElement.value === item.value);
                             return {
                                 choice: newId,
                                 value: item.value,
                                 name: stuffMatched ? stuffMatched.name: "",
                                 items: item.items
                             };
                         });

                updatedElement.items.forEach(item => canvases.push({
                    headers: {
                        id: item.choice,
                        parentId: currentCanvasId,
                        name: item.name ? item.name: `Canvas ${item.id}`
                    },
                    elements: []
                }));

                updatedElement.items.forEach(item => {
                    transformFromJSON(item.items, canvases, item.choice);
                    delete(item.items);
                });

                canvases[currentCanvasId].elements.push({id: i, ...updatedElement});
                break;
            }
            case "collection": {
                const {items, minItems, maxItems, ...updatedElement} = element;
                updatedElement.canvasId = canvases[canvases.length - 1].headers.id + 1;

                //json may contain `items` field while prototype does not, mitItems and maxItems are deprecated, itemIdField is hidden
                updatedElement.unknownFields = updatedElement.unknownFields.filter(field =>
                    !["items", "minItems", "maxItems", "itemIdField"].includes(field));

                //replacing deprecated fields with valid synonyms
                if (minItems) {
                    updatedElement.min = minItems;
                }
                if (maxItems) {
                    updatedElement.max = maxItems;
                }

                canvases.push({
                    headers: {
                        id: updatedElement.canvasId,
                        parentId: currentCanvasId,
                        name: updatedElement.name ? updatedElement.name: `Canvas ${updatedElement.canvasId}`
                    },
                    elements: []
                });

                transformFromJSON(items, canvases, updatedElement.canvasId);
                canvases[currentCanvasId].elements.push({id: i, ...updatedElement});
                break;
            }
            case "markdown": {
                const {type, ...updatedElement} = element;
                updatedElement.type = updatedElement.value ? "markdown-static": "markdown";
                updatedElement.unknownFields = updatedElement.unknownFields.filter(field => field !== "value");
                canvases[currentCanvasId].elements.push({id: i, ...updatedElement});
                break;
            }
            case "presets": {
                const {items, stuff, ...updatedElement} = element;
                updatedElement.canvasId = canvases[canvases.length - 1].headers.id + 1;

                updatedElement.unknownFields = updatedElement.unknownFields.filter(field =>
                    !["items"].includes(field));

                if (stuff) { // {name: "John", value: "American", values: {age: 30, married: true}} —> {name: "John", value: "American", age: 30, married: true}
                    const transformedStuff = [];
                    stuff.forEach(element => {
                        const transformedElement = {name: element.name, value: element.value};
                        for(const field in element.values) {
                            transformedElement[`_` + field] = element.values[field];//adding `_` to avoid // {name: "John", value: "American", values: {age: 30, name: "Nick"}}
                        }
                        transformedStuff.push(transformedElement);
                    });
                    updatedElement.stuff = transformedStuff;
                }

                canvases.push({
                    headers: {
                        id: updatedElement.canvasId,
                        parentId: currentCanvasId,
                        name: updatedElement.name ? updatedElement.name: `Canvas ${updatedElement.canvasId}`
                    },
                    elements: []
                });

                transformFromJSON(items, canvases, updatedElement.canvasId);
                canvases[currentCanvasId].elements.push({id: i, ...updatedElement});
                break;
            }
            */
            default:
                canvases[currentCanvasId].elements.push({id: i, ...element});
                break;
        }
    });
    return canvases;
};


// transform from PrototypeEditor inner structure to json
const transformToJSON = (canvases, elements, t) => elements.map(element => {
    switch (element.type) {
        /*
        case "choices": {
            const {id, _updateIterationNumber, unknownFields, items, ...updatedElement} = element;
            updatedElement.stuff = [];
            updatedElement.items = [];

            items && items.forEach(item => {
                const canvas = canvases.find(c => c.headers.id === item.choice);
                updatedElement.stuff.push({name: item.name, value: item.value});
                updatedElement.items.push({
                    type: "choice",
                    value: item.value,
                    items: canvas && canvas.elements ? transformToJSON(canvases, canvas.elements, t): []
                });
            });

            updatedElement.items.unshift({
                field: "_type",
                name: t('components.fields.PrototypeEditorVal.type'),
                type: "enum",
                required: true
            });
            return updatedElement;
        }
        case "block":
        case "collection": {
            const {id, canvasId, _updateIterationNumber, unknownFields, ...updatedElement} = element;
            const canvas = canvases.find(c => c.headers.id === canvasId);
            updatedElement.items = canvas && canvas.elements ? transformToJSON(canvases, canvas.elements, t): [];
            return updatedElement;
        }
        case "presets": {
            const {id, canvasId, stuff, _updateIterationNumber, unknownFields, ...updatedElement} = element;
            if (stuff) { // {name: "John", value: "American", age: 30, married: true} —> {name: "John", value: "American", values: {age: 30, married: true}}
                const transformedStuff = [];
                stuff.forEach(element => {
                    const {name, value, ...values} = element;
                    const modifiedValues = {};
                    for(const field in values) {
                        modifiedValues[field.slice(1)] = values[field]; //removing prefix `_`
                    }
                    transformedStuff.push({
                        name: name,
                        value: value,
                        values: modifiedValues
                    });
                });
                updatedElement.stuff = transformedStuff;
            }
            const canvas = canvases.find(c => c.headers.id === canvasId);
            updatedElement.items = canvas && canvas.elements ? transformToJSON(canvases, canvas.elements, t): [];
            return updatedElement;
        }
        case "markdown-static": {
            const {id, name, field, type, _updateIterationNumber, isUnknown, unknownFields, ...updatedElement} = element;
            updatedElement.type = "markdown";
            return updatedElement;
        }
        */
        default: {
            const {id, _updateIterationNumber, isUnknown, unknownFields, ...updatedElement} = element;
            return updatedElement;
        }
    }
});


// main component
const PrototypeEditorVal = (props) => {

    const {noLabel, wide, name, unit, info, infoImage, disabled, data, field, error} = props;
    const value = data[field] ? data[field]: "";
    const [showEditor, setShowEditor] = useState(false);
    const {t} = useTranslation();

    const onChange = (value) => {
        props.onChange(data, field, value);
    };

    const onSaveAndClose = (value) => {
        onChange(value);
        setShowEditor(false);
    };

    const contents =
        <>
            {showEditor &&
            <>
                {!error &&
                <PrototypeEditorModal title={name} initialJsonString={value} onSave={onSaveAndClose} onCancel={() => setShowEditor(false)}
                                      disabled={disabled} />}
                {error &&
                <div>{t('components.fields.PrototypeEditorVal.displayError')} {error}</div>}
            </>}

            <div className="flex-grow">
                <JSONText visualEditor={{
                            buttonName: t('components.fields.PrototypeEditorVal.visualEditor'),
                            onClick: () => setShowEditor(true)
                          }}
                          value={value} disabled={disabled} onChange={onChange} error={error} />
            </div>
        </>;

    if (noLabel) {
        return (
            <div className="form-group row g-0 d-flex align-items-start">
                {contents}
            </div>);
    } else {
        return (
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
                </Col>
                <Col sm={wide ? 10 : 9} className="d-flex align-items-start">
                    {contents}
                </Col>
            </div>);
    }
};


//modal component
const PrototypeEditorModal = compose(
    withGeneratorDataSource)
((props) => {
    const {initialJsonString, title, onSave, onCancel, disabled,
        onChange, dataSource, setDataSource, saveAndReset} = props;

    const {t} = useTranslation();

    const initialJson = useMemo(() =>
        entityUtils.safeJSONParse(initialJsonString), [initialJsonString]);

    const initialParentCanvas = useMemo(() => ({
        headers: {
            id: 0,
            parentId: null,
            name: "Canvas 0"
        },
        elements: []
    }), []);

    const initialCanvases = useMemo(() => (
        initialJson ? transformFromJSON(initialJson, [initialParentCanvas], 0): [initialParentCanvas]),
        [initialJson, initialParentCanvas]);

    const [canvases, setCanvases] = useState(initialCanvases);
    const [errorMessages, setErrorMessages] = useState({});
    const [selectedButton, setSelectedButton] = useState("");

    useMemo(() => {
        const errorMessages = {};
        canvases.forEach((canvas, i) =>
            canvas.elements.forEach((element, j) => {
                if (element.isUnknown) {
                    errorMessages[`${i}_${j}`] = `${t('components.fields.PrototypeEditorVal.unknownElement')} ${element.type} (${canvas.headers.name}, element ${j})`;
                } else if (element.unknownFields && element.unknownFields.length) {
                    errorMessages[`${i}_${j}`] = `${t('components.fields.PrototypeEditorVal.unknownField.part1')}
                                                  ${element.unknownFields.join(', ')}
                                                  ${t('components.fields.PrototypeEditorVal.unknownField.part2')}
                                                  ${element.type} (${canvas.headers.name}, element ${j})`;
                }
            })
        );
        setErrorMessages(errorMessages);
    }, [canvases, t]);


    const [currentCanvasId, setCurrentCanvasId] = useState(0);
    const [currentElementId, setCurrentElementId] = useState(null);
    const currentCanvas = useMemo(() =>
        canvases.find(c => c.headers.id === currentCanvasId),
        [canvases, currentCanvasId]);

    const currentElement = currentElementId !== null && currentCanvas.elements.find(e => e.id === currentElementId);

    const currentElementPrototype = useMemo(() => {
        if (!currentElement ||
            !currentElement.type ||
            !existingElements.map(e => e.type).includes(currentElement.type)) {

            return null;
        }

        /*else if (currentElement.type === "presets") {
            const presetsPrototype = JSON.parse(JSON.stringify(fieldPrototypes.presets));

            if (!currentElement.canvasId) { //child canvas does not exist
                return presetsPrototype;
            }

            const stuff = presetsPrototype.find(e => e.field === "stuff");

            const childElements =  canvases[currentElement.canvasId].elements.filter(e => e.field !== "_preset");
            childElements.forEach(e => {
                stuff.items.push({...e, "field": `_` + e.field, disabled: false});
            });

            return presetsPrototype;
        } */ else {
            return translateObjOrArray(fieldPrototypes[currentElement.type].fields, t);
        }
    }, [canvases, currentElement]);

    useEffect(() =>
        setDataSource(currentCanvas.elements.find(e => e.id === currentElementId), undefined),
        [currentCanvas, currentElementId, dataSource, setDataSource]);


    const isDescendentOfArray = (descendentId, ancestorIds) => {

        const isDescendent = (descendentId, ancestorId) => {
            const descendentCanvas = canvases.find(c => c.headers.id === descendentId);

            if (!descendentCanvas) { //invalid id
                return false;
            } else if (descendentId === 0) { //zero canvas do not have an ancestor
                return false;
            } else if (descendentCanvas.headers.parentId === ancestorId) { //is a child
                return true;
            } else {
                return isDescendent(descendentCanvas.headers.parentId, ancestorId);
            }
        };

        if (!ancestorIds.length) {
            return false;
        }
        const [firstAncestorId, ...otherAncestorIds] = ancestorIds;

        if (descendentId === firstAncestorId || isDescendent(descendentId, firstAncestorId)) {
            return true;
        } else {
            return isDescendentOfArray(descendentId, otherAncestorIds);
        }
    };

    const updateCanvas = (canvasId, newCanvasBody) => {
        const newCanvases = [];
        canvases.forEach(canvas => {
            (canvas.headers.id === canvasId) ?
                newCanvases.push({headers: canvas.headers, elements: newCanvasBody}):
                newCanvases.push(canvas);
        });
        setCanvases(newCanvases);
    };

    const cleanCanvas = canvasId => {
        const newCanvases = [];
        canvases.forEach(canvas => {
            if (canvas.headers.id === canvasId) {
                newCanvases.push({headers: canvas.headers, elements: []});
            } else if (!isDescendentOfArray(canvas.headers.id, [canvasId])) {
                newCanvases.push(canvas);
            }
        });

        if (!newCanvases.find(canvas => canvas.headers.id === currentCanvasId)) { //current canvas was deleted
            setCurrentCanvasId(0);
        }

        setCanvases(newCanvases);
    };

    //clone element
    const cloneElement = (element, maxId) => {

        //splitting field to fieldRoot and fieldIndex, f.e. "field23" -> "field" and "23"
        const fieldIndex = element.field.match(/\d+$/);
        const fieldRoot = fieldIndex ?
            element.field.substring(0, element.field.lastIndexOf(fieldIndex[0])):
            element.field;

        const fieldPattern = new RegExp(fieldRoot + "(\\d+)$");
        const newFieldIndex = 1 + Math.max(...currentCanvas.elements.map(e => {
            const index = e.field.match(fieldPattern);
            return index ? Number(index[1]): 0;
        }));

        const newElement = JSON.parse(JSON.stringify(element));
        newElement.field = fieldRoot + newFieldIndex;
        newElement.id = maxId + 1;

        return newElement;
    }

    const drawElement = (element) => {
        switch (element.type) {
            case "choices":
                if (!element.items) {
                    return element.name;
                }
                return [element.name,
                        ...element.items.map((item, i) => {
                            const canvas = item.choice && canvases.find(canvas => canvas.headers.id === item.choice);
                            return (<div key={i} className="row mt-2 p-1 bg-white text-secondary border">
                                <div className="col-8">
                                    {canvas && canvas.headers.name}
                                </div>
                                <div className="col-4">
                                    {i > 0 && !disabled &&
                                    <i className="fa fa-arrow-up" onClick={(event) => {
                                        event.stopPropagation();
                                        element.items = [...element.items.slice(0, i - 1),
                                                         item, element.items[i - 1],
                                                         ...element.items.slice(i + 1)];
                                        updateCanvas(currentCanvas.headers.id, currentCanvas.elements.map(e => e.id === element.id ? element: e));
                                    }}/>}
                                    {i < element.items.length - 1 && !disabled &&
                                    <i className="fa fa-arrow-down" onClick={(event) => {
                                        event.stopPropagation();
                                        element.items = [...element.items.slice(0, i),
                                                         element.items[i + 1], item,
                                                         ...element.items.slice(i + 2)];
                                        updateCanvas(currentCanvas.headers.id, currentCanvas.elements.map(e => e.id === element.id ? element: e));
                                    }}/>}
                                </div>
                            </div>)})];
            default:
                return element.name;
        }
    };

    const canvasNodes = [];
    canvases.forEach(canvas =>
        canvasNodes.push({
            id: canvas.headers.id,
            parentId: canvas.headers.parentId,
            name: canvas.headers.name,
            actions: {
                select: () => currentCanvasId !== canvas.headers.id && setCurrentCanvasId(canvas.headers.id),
                clean: !disabled ? () => cleanCanvas(canvas.headers.id): undefined
            }
        })
    );

    const buttons = [];
    existingElements.forEach(e =>
        buttons.push(
            <button key={e.type} className={classnames("btn", selectedButton === e.type ? "btn-primary active": "btn-light")}  onClick={() => {
                const newElementId = currentCanvas.elements.length ?
                    currentCanvas.elements[currentCanvas.elements.length - 1].id + 1: 0;
                updateCanvas(currentCanvas.headers.id, [...currentCanvas.elements, {
                    id: newElementId,
                    field: `new${e.type[0].toUpperCase() + e.type.substring(1) + currentCanvas.headers.id + newElementId}`,
                    name: `New ${e.type} ${"(" + currentCanvas.headers.id + newElementId + ")"}`,
                    type: e.type
                }]);
            }}>{translateObjOrArray(e.name, t)}
            </button>
        )
    );

    const currentCanvasElements = currentCanvas.elements.map((element, i) =>

        <div key={i} className={classnames("row mb-3 p-3 border",
                                           element.isUnknown && "bg-danger text-white",
                                           element.unknownFields && element.unknownFields.length && "bg-warning text-dark",
                                           !element.isUnknown && (!element.unknownFields || !element.unknownFields.length) && "bg-light")}
                      onClick={() => setCurrentElementId(element.id)}
                      onMouseEnter={() => setSelectedButton(element.type)}
                      onMouseLeave={() => setSelectedButton("")}>
            <div className="col-9">
                {drawElement(element)}
            </div>

            <div className="col-3">
                {i > 0 && !disabled &&
                <i className="fa fa-arrow-up" onClick={(event) => {
                    event.stopPropagation();
                    setSelectedButton("");
                    updateCanvas(currentCanvas.headers.id,
                                [...currentCanvas.elements.slice(0, i - 1),
                                 element, currentCanvas.elements[i - 1],
                                 ...currentCanvas.elements.slice(i + 1)]);
                }}/>}
                {i < currentCanvas.elements.length - 1 && !disabled &&
                <i className="fa fa-arrow-down" onClick={(event) => {
                    event.stopPropagation();
                    setSelectedButton("");
                    updateCanvas(currentCanvas.headers.id,
                                [...currentCanvas.elements.slice(0, i),
                                 currentCanvas.elements[i + 1], element,
                                 ...currentCanvas.elements.slice(i + 2)]);
                }}/>}
                {!disabled && !["choices", "block", "collection"].includes(element.type) &&
                <i className="fa fa-clone" onClick={(event) => {
                    event.stopPropagation();
                    setSelectedButton("");
                    switch (element.type) {
                        case "choices": {
                            //add your code here
                            break;
                        }
                        case "block":
                        case "collection": {
                            //add your code here
                            break;
                        }
                        default:
                        updateCanvas(currentCanvas.headers.id,
                                    [...currentCanvas.elements.slice(0, i + 1),
                                     cloneElement(element, Math.max(...currentCanvas.elements.map(e => e.id))),
                                     ...currentCanvas.elements.slice(i + 1)]);
                    }
                }}/>}
                {!disabled &&
                <i className="fa fa-remove float-end" onClick={(event) => {
                    event.stopPropagation();
                    setSelectedButton("");
                    switch (element.type) {
                        case "choices": {
                            const newCanvases = [];
                            canvases.forEach(canvas => {
                                newCanvases.push(canvas.headers.id === currentCanvas.headers.id ? {
                                        headers: currentCanvas.headers,
                                        elements: currentCanvas.elements.filter((_, j) => j !== i)
                                    }:
                                    canvas);
                            });

                            const deletedIds = element.items &&
                                element.items.filter(item => item.choice).map(item => item.choice);

                            setCanvases(deletedIds ?
                                            newCanvases.filter(canvas => !isDescendentOfArray(canvas.headers.id, deletedIds)):
                                            newCanvases);
                            break;
                        }
                        case "block":
                        case "collection": {
                            const newCanvases = [];
                            canvases.forEach(canvas => {
                                newCanvases.push(canvas.headers.id === currentCanvas.headers.id ? {
                                        headers: currentCanvas.headers,
                                        elements: currentCanvas.elements.filter((_, j) => j !== i)
                                    }:
                                    canvas);
                            });
                            setCanvases(element.canvasId ?
                                            newCanvases.filter(canvas => !isDescendentOfArray(canvas.headers.id, [element.canvasId])):
                                            newCanvases);
                            break;
                        }
                        default:
                            updateCanvas(currentCanvas.headers.id,
                                         currentCanvas.elements.filter((_, j) => j !== i));
                    }
                }}/>}
            </div>
        </div>);

    const outputJSON = JSON.stringify({[currentCanvas.headers.name]: transformToJSON(canvases, currentCanvas.elements, t)}, undefined, 2);

    const onClose = () => {
        setCurrentElementId(null);
        const newCanvasElements = [];
        const data = saveAndReset();

        currentCanvas.elements.forEach(e =>
            newCanvasElements.push(e.id === data.id ? data: e)
        );

        switch (data.type) {
            case "choices": {
                const newCanvasNames = {};
                const newCanvases = [];

                data.items && data.items.filter(item => item.choice)
                                        .forEach(item => newCanvasNames[item.choice] = item.name ? item.name: `Canvas ${item.choice}`);

                //replacing the existing canvas names
                canvases.forEach(canvas => {
                    if (canvas.headers.id === currentCanvas.headers.id) {
                        newCanvases.push({headers: canvas.headers, elements: newCanvasElements})
                    } else if (newCanvasNames[canvas.headers.id]) {
                        newCanvases.push({headers:{...canvas.headers, name: newCanvasNames[canvas.headers.id]}, elements: canvas.elements})
                    } else {
                        newCanvases.push(canvas);
                    }
                });

                //creating new canvases for new elements
                data.items && data.items.length && data.items.forEach(item => {
                    if (!item.choice) {
                        const newId = newCanvases[newCanvases.length - 1].headers.id + 1;
                        newCanvases.push({
                            headers: {
                                id: newId,
                                parentId: currentCanvas.headers.id,
                                name: item.name ? item.name: "Canvas " + newId
                            },
                            elements: []
                        });

                        item.choice = newId;
                    }
                });

                //deleting canvases which corresponding elements were deleted
                const choiceBlockBeforeEditing =  currentCanvas.elements.find(e => e.id === data.id);
                const oldIds = choiceBlockBeforeEditing.items && choiceBlockBeforeEditing.items.map(item => item.choice);
                const newIds = data.items && data.items.map(item => item.choice);
                const deletedIds = oldIds &&
                        oldIds.filter(id => !newIds || !newIds.includes(id));

                setCanvases(deletedIds ? newCanvases.filter(canvas => !isDescendentOfArray(canvas.headers.id, deletedIds)):
                                         newCanvases);
                break;
            }
            case "block":
            case "collection":
            case "presets": {
                const newCanvases = [];

                //replacing the existing canvas name
                canvases.forEach(canvas => {
                    if (canvas.headers.id === currentCanvas.headers.id) {
                        newCanvases.push({headers: canvas.headers, elements: newCanvasElements})
                    } else if (data.canvasId && canvas.headers.id === data.canvasId) {
                        newCanvases.push({headers:{...canvas.headers, name: data.name ? data.name: `Canvas ${data.canvasId}`}, elements: canvas.elements})
                    } else {
                        newCanvases.push(canvas);
                    }
                });

                if (!data.canvasId) { //create canvas if not exist
                    const newId = newCanvases[newCanvases.length - 1].headers.id + 1;
                    newCanvases.push({
                        headers: {
                            id: newId,
                            parentId: currentCanvas.headers.id,
                            name: data.name ? data.name: "Canvas " + newId
                        },
                        elements: []
                    });

                    data.canvasId = newId;
                }
                setCanvases(newCanvases);
                break;
            }
            default:
                updateCanvas(currentCanvas.headers.id, newCanvasElements);
        }
    };


    return (
        <PanelModal show title={title} onClose={onCancel}>
            {!disabled &&
            <SaveButtonsBlock isChanged
                              onSave={() => onSave(JSON.stringify(transformToJSON(canvases, canvases[0].elements, t)))}
                              onCancel={onCancel} />}
            <Messages errors={errorMessages} />
            {!disabled &&
            <div className="multibuttons pt-2">
                {buttons}
            </div>}
            <div className="row">
                <div className="d-none col-3">
                    <Tree nodes={canvasNodes} selectedId={currentCanvas.headers.id} executeWithLock={props.executeWithLock} />
                </div>

                <div className="col-7 m-3">
                    {currentCanvasElements}
                </div>

                <div className="col-4">
                    <pre>{outputJSON}</pre>
                </div>
            </div>

            {currentElementPrototype && !disabled &&
            <PanelModal show title={t('components.fields.PrototypeEditorVal.edit')} onClose={onClose} noPadding scrollable>
                <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                    <GeneratorBlock wide data={dataSource} onChange={onChange}
                                    collectionsDict={{canvases: canvases.filter(canvas => canvas.headers.parentId === currentCanvasId)
                                                                        .map(canvas => ({localId: canvas.headers.id, name: canvas.headers.name}))}}
                                    items={currentElementPrototype} />
                </div>
            </PanelModal>}
        </PanelModal>);
});

export default PrototypeEditorVal;