import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import {catalogFields, catalogEntryFields, catalogEntryVersionFields} from './catalogQueries'


// Catalog

const CreateCatalog = gql`
  mutation createCatalog($data: CreateCatalogMutationInput!) {
    createCatalog(data: $data) {
      id
    }
  }
`;

const UpdateCatalog = gql`
  mutation updateCatalog($data: UpdateCatalogMutationInput!) {
    updateCatalog(data: $data) {
      catalog {
        ${catalogFields}
      }
    }
  }
`;

const DeleteCatalog = gql`
  mutation deleteCatalog($id: ID!) {
    deleteCatalog(id: $id) {
      id
    }
  }
`;


// CatalogEntry

const CreateCatalogEntry = gql`
  mutation createCatalogEntry($data: CreateCatalogEntryMutationInput!) {
    createCatalogEntry(data: $data) {
      id
    }
  }
`;

const UpdateCatalogEntry = gql`
  mutation updateCatalogEntry($data: UpdateCatalogEntryMutationInput!) {
    updateCatalogEntry(data: $data) {
      catalogEntries {
        ${catalogEntryFields}
      }
    }
  }
`;

const DeleteCatalogEntry = gql`
  mutation deleteCatalogEntry($id: ID!) {
    deleteCatalogEntry(id: $id) {
      id
    }
  }
`;

const CloneCatalogEntry = gql`
  mutation cloneCatalogEntry($id: ID!) {
    cloneCatalogEntry(id: $id) {
      id
      error
    }
  }
`;


// CatalogEntryVersion

const UpdateCatalogEntryVersion = gql`
  mutation updateCatalogEntryVersion($data: UpdateCatalogEntryVersionMutationInput!) {
    updateCatalogEntryVersion(data: $data) {
      catalogEntryVersion {
        ${catalogEntryVersionFields}
      }
      error
    }
  }
`;

const DeleteCatalogEntryVersion = gql`
  mutation deleteCatalogEntryVersion($id: ID!) {
    deleteCatalogEntry(id: $id) {
      id
      error
    }
  }
`;

const CloneCatalogEntryVersion = gql`
  mutation cloneCatalogEntryVersion($id: ID!) {
    cloneCatalogEntryVersion(id: $id) {
      id
      error
    }
  }
`;

// others

const AddFromCatalog = gql`
  mutation addFromCatalog($addToType: String!, $addToId: ID!, $catalogEntryVersionId: ID!) {
    addFromCatalog(addToType: $addToType, addToId: $addToId, catalogEntryVersionId: $catalogEntryVersionId) {
      eventId
      scenarioId
      error
    }
  }
`;

const AddItemToCatalog = gql`
  mutation addItemToCatalog($itemId: ID!, $itemType: String!, $catalogId: ID!) {
    addItemToCatalog(itemId: $itemId, itemType: $itemType, catalogId: $catalogId) {
      id
      error
    }
  }
`;

const ExportCatalog = gql`
  mutation exportCatalog($id: ID!) {
    exportCatalog(id: $id) {
      json
    }
  }
`;

const ImportCatalog = gql`
  mutation importCatalog($data: ImportCatalogMutationInput!) {
    importCatalog(data: $data) {
      result
      infos
      errors
    }
  }
`;


export const createCatalog = graphql(CreateCatalog, {
        props: ({ mutate }) => ({ createCatalog: (data) => mutate({ variables: { data } }) }),
        options: { refetchQueries: ['FlatCatalogsAndEntriesQuery']}
    });
export const updateCatalog = graphql(UpdateCatalog, {
        props: ({ mutate }) => ({ updateCatalog: (data) => mutate({ variables: { data } }) })
    });
export const deleteCatalog = graphql(DeleteCatalog, {
        props: ({ mutate }) => ({ deleteCatalog: (id) => mutate({ variables: { id } }) }),
        options: { refetchQueries: ['FlatCatalogsAndEntriesQuery', 'CatalogQuery']}
    });


export const createCatalogEntry = graphql(CreateCatalogEntry, {
        props: ({ mutate }) => ({ createCatalogEntry: (data) => mutate({ variables: { data } }) }),
        options: { refetchQueries: ['FlatCatalogsAndEntriesQuery']}
    });
export const updateCatalogEntry = graphql(UpdateCatalogEntry, {
        props: ({ mutate }) => ({ updateCatalogEntry: (data) => mutate({ variables: { data } }) })
    });
export const deleteCatalogEntry = graphql(DeleteCatalogEntry, {
        props: ({ mutate }) => ({ deleteCatalogEntry: (id) => mutate({ variables: { id } }) }),
        options: { refetchQueries: ['FlatCatalogsAndEntriesQuery','CatalogEntryWithVersionsQuery']}
    });
export const cloneCatalogEntry = graphql(CloneCatalogEntry, {
    props: ({ mutate }) => ({ cloneCatalogEntry: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['FlatCatalogsAndEntriesQuery']}
});


export const updateCatalogEntryVersion = graphql(UpdateCatalogEntryVersion, {
    props: ({ mutate }) => ({ updateCatalogEntryVersion: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['CatalogEntryWithVersionsQuery']}    // can publish, this affects all catalogEntry
});
export const deleteCatalogEntryVersion = graphql(DeleteCatalogEntryVersion, {
    props: ({ mutate }) => ({ deleteCatalogEntryVersion: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['CatalogEntryWithVersionsQuery']}
});
export const cloneCatalogEntryVersion = graphql(CloneCatalogEntryVersion, {
    props: ({ mutate }) => ({ cloneCatalogEntryVersion: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['CatalogEntryWithVersionsQuery']}
});


export const addFromCatalog = graphql(AddFromCatalog, {
    props: ({ mutate }) => ({ addFromCatalog: (addToType, addToId, catalogEntryVersionId) => mutate({ variables: { addToType, addToId, catalogEntryVersionId } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery']}
});
export const addItemToCatalog = graphql(AddItemToCatalog, {
    props: ({ mutate }) => ({ addItemToCatalog: (itemId, itemType, catalogId) => mutate({ variables: { itemId, itemType, catalogId } }) }),
    options: { refetchQueries: ['CatalogEntryWithVersionsQuery']}
});


export const exportCatalog = graphql(ExportCatalog, {
    props: ({ mutate }) => ({ exportCatalog: (id) => mutate({ variables: { id } }) }),
});

export const importCatalog = graphql(ImportCatalog, {
    props: ({ mutate }) => ({ importCatalog: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['FlatCatalogsAndEntriesQuery', 'CatalogQuery']}
});