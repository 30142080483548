import React, {useEffect, useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Alert, Button} from 'reactstrap'
import useSimpleGeneratorDataSource from 'hooks/useSimpleGeneratorDataSource'
import classnames from 'classnames'

import TableBlockWithSort from 'components/blocks/TableBlockWithSort'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import withUriParams from 'hocs/withUriParams'
import withUriSearchParam from 'hocs/withUriSearchParam'
import withLocationStateParam from 'hocs/withLocationStateParam'
import ConstellationSolutionView from 'appEvent/views/solutionViews/ConstellationSolutionView'
import SimpleSolutionView from 'appEvent/views/solutionViews/SimpleSolutionView'
import TournamentTableModal from 'appEvent/modals/TournamentTableModal'

import {queryLicenseScenario, queryLicenseEvents, queryLicenseEventSolutionsWithTeams} from '../data/adminQueries'
import {querySolution} from 'appEvent/data/eventQueries'
import {updateSolution, recalcSolution, deleteSolution} from 'appEvent/data/eventMutations'

import {convertOldJSONPrototypeParameters, convertOldJSONPrototypeResults} from 'appEvent/data/eventObjects'
import {breadcrumbsStore, linksStore} from 'appBase/TopNav'
import {prepareSolutionObject} from 'appEvent/data/eventObjects'
import dateUtils from 'utils/dateUtils'
import entityUtils from 'utils/entityUtils'
import {setSearchParam} from 'utils/urlUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {ADMIN_SOLUTIONS_VIEW, TOURNAMENT_TABLE_VIEW, FULL_SCREEN} from 'constants/constants'
import LabelWithInfo from '../../components/fields/elements/LabelWithInfo'

const AdminSolutionsView = compose(
    withUriParams([
        ['eventId', Number],
        ['scenarioId', Number],
        ['solutionId', Number]]),
    withUriSearchParam(TOURNAMENT_TABLE_VIEW, 'showTournamentTable'),
    withLocationStateParam(FULL_SCREEN, 'isFullScreen'),
    queryLicenseEvents,
    queryLicenseEventSolutionsWithTeams,
    queryLicenseScenario,
    querySolution,
    updateSolution,
    recalcSolution,
    deleteSolution,
    withInitialDataLoadWaiting(['licenseEvents'])
)((props) => {

    const {licenseEvents, eventId, scenarioId, licenseEventSolutionsWithTeams,
        solutionId, queryAsRole, loading, showTournamentTable, isFullScreen,
        disabled, executeWithConfirmation, updateSolution, recalcSolution, deleteSolution} = props;

    const [selectedScenarioId, setSelectedScenarioId] = useState(null);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [error, setError] = useState(null);
    const {t, i18n} = useTranslation();

    // parse JSON strings

    const solution = useMemo(() => prepareSolutionObject(props.solution), [props.solution]);

    const licenseScenario = useMemo(() => (
        props.licenseScenario && {
            ...props.licenseScenario,
            prototypeParameters: entityUtils.safeJSONParse(convertOldJSONPrototypeParameters(props.licenseScenario.prototypeParameters)),
            prototypeResults: entityUtils.safeJSONParse(convertOldJSONPrototypeResults(props.licenseScenario.prototypeResults)),
            parameters: entityUtils.safeJSONParse(props.licenseScenario.parameters)
        }),
        [props.licenseScenario]);

    // evaluation data source

    // TODO: discard JSON.stringify hack and use scores and achievements arrays as is
    const evaluationDataMemoized = useMemo(
        () => {
            if (!solution) {
                return null;
            }
            const scores = (solution.results && Array.isArray(solution.results.scores) && solution.results.scores) || [];
            const achievements = (solution.results && Array.isArray(solution.results.achievements) && solution.results.achievements) || [];
            return {
                scores: scores.length <= 1 ? JSON.stringify(scores[0]) || '' : JSON.stringify(scores),
                achievements: achievements.length <= 1 ? achievements[0] || '' : JSON.stringify(achievements),
                scoresParseError: false,
                achievementsParseError: false
            }},
        [solution]);
    const [evaluationDataSource, onEvaluationChange] = useSimpleGeneratorDataSource(evaluationDataMemoized);

    // solution data source

    const [solutionDataSource, onSolutionChange] = useSimpleGeneratorDataSource(solution && solution.parameters);

    // preparing links

    const containerId = ADMIN_SOLUTIONS_VIEW;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const links = [];
    links.push({name: t('appAdmin.views.AdminSolutionsView.solutionsView'), to: `${props.uri}`});
    const event = licenseEvents.find(e => e.id === eventId);
    if (event) {
        links.push({name: event.name, to: `${props.uri}/${eventId}`});
    }

    const sideLinks = [{
        name: t('appAdmin.views.AdminSolutionsView.tournamentTable'),
        to: `${props.location.pathname}${setSearchParam(props.location.search, TOURNAMENT_TABLE_VIEW)}`,
        state: props.location.state,
        nonBlocking: true
    }];

    useEffect(() => {
        const isTerminal = !solutionId;
        breadcrumbsStore.set(containerId, links, isTerminal);
        linksStore.set(containerId, sideLinks, isTerminal);
    });


    // rendering

    const isSolutionsLoading = loading['licenseEventSolutionsWithTeams'];

    const headers = [t('appAdmin.views.AdminSolutionsView.event'), t('appAdmin.views.AdminSolutionsView.team'),
        t('appAdmin.views.AdminSolutionsView.task'), t('appAdmin.views.AdminSolutionsView.student'),
        t('appAdmin.views.AdminSolutionsView.creationTime'), t('appAdmin.views.AdminSolutionsView.sendTime'),
        t('appAdmin.views.AdminSolutionsView.status'), t('appAdmin.views.AdminSolutionsView.solutionNumber'),
        t('appAdmin.views.AdminSolutionsView.score')];


    const licenseEventSolutionsWithTeamsFiltered = licenseEventSolutionsWithTeams &&
        licenseEventSolutionsWithTeams.filter(s => {
            if (selectedScenarioId && s.solution.scenario.id !== selectedScenarioId) {
                return false;
            }
            if (selectedTeamId && (!s.studentTeam || s.studentTeam.id !== selectedTeamId)) {
                return false;
            }
            if (selectedUserId && s.solution.user.id !== selectedUserId) {
                return false;
            }
            return true;
        });

    const report = licenseEventSolutionsWithTeamsFiltered && licenseEventSolutionsWithTeamsFiltered.map((s, i) => ({
            fields: [ s.solution.scenario.event.name,
                s.studentTeam ? s.studentTeam.name : '',
                s.solution.scenario.name,
                s.solution.user.name + "\n" + s.solution.user.email + (s.solution.user.talentId ? ("\n" + s.solution.user.talentId):""),
                dateUtils.dateToLocaleDateTimeString(s.solution.createdAt, i18n),
                dateUtils.dateToLocaleDateTimeString(s.solution.modifiedAt, i18n),
                s.solution.status,
                s.solution.number ? s.solution.number : '',
                s.solution.score ? s.solution.score : ''],
            actions: {
                "click":  () => props.navigate(`./${eventId}/${s.solution.scenario.id}/${s.solution.id}`)
            }
        }));

    const scenarios = useMemo(() => (
        licenseEventSolutionsWithTeams &&
        entityUtils.removeDuplicatesById(
            licenseEventSolutionsWithTeams.map(s => ({
                id: s.solution.scenario.id,
                name: s.solution.scenario.name
            }))
        )
    ), [licenseEventSolutionsWithTeams]);

    const teams = useMemo(() => (
        licenseEventSolutionsWithTeams &&
        entityUtils.removeDuplicatesById(
            licenseEventSolutionsWithTeams.filter(s => s.studentTeam)
                                          .map(s => ({
                id: s.studentTeam.id,
                name: s.studentTeam.name
            }))
        )
    ), [licenseEventSolutionsWithTeams]);

    const users = useMemo(() => (
        licenseEventSolutionsWithTeams &&
        entityUtils.removeDuplicatesById(
            licenseEventSolutionsWithTeams.filter(s => selectedTeamId ? s.studentTeam && s.studentTeam.id === selectedTeamId: true)
                                          .map(s => ({
                id: s.solution.user.id,
                name: s.solution.user.name
            }))
        )
    ), [licenseEventSolutionsWithTeams, selectedTeamId]);

    const isSolutionDataLoading = loading['solution'] || loading['scenario'];
    let selectedIndex = -1;

    if (solution && licenseEventSolutionsWithTeamsFiltered) {
        selectedIndex = licenseEventSolutionsWithTeamsFiltered.indexOf(licenseEventSolutionsWithTeamsFiltered.find(s => s.solution.id === solution.id));
    }

    let progress = "";
    if (solution && solution.status === "running" && solution.resultNumber && solution.simulation && solution.simulation.resultTotal) {
        progress = (solution.resultNumber / solution.simulation.resultTotal) * 100;
        progress = Math.round(progress * 10) / 10;
        progress = "" + progress + "%";
    }

    return (
        <div className={!isFullScreen ? "flex-grow d-flex flex-row scroll-parent" : "flex-grow d-flex scroll-parent"}>
            {!isFullScreen &&
            <div className="w-50 d-flex flex-column scroll">
                {error &&
                <Alert className="mb-0" color="danger">{error}</Alert>}
                <div>
                    <select style={{width: "100%"}} value={eventId || ''} onChange={(e) => {
                        setSelectedScenarioId(null);
                        setSelectedTeamId(null);
                        setSelectedUserId(null);
                        props.navigate(`./${e.nativeEvent.target.value}`);
                    }}>
                        <option value="">{t('appAdmin.views.AdminSolutionsView.chooseEvent')}</option>
                        {licenseEvents.map(e => (<option key={e.id} value={e.id}>{e.name}</option>))}
                    </select>

                    {scenarios &&
                    <select style={{width: "100%"}} value={selectedScenarioId || ''} onChange={(e) => {
                        setSelectedScenarioId(Number(e.nativeEvent.target.value));
                        props.navigate(`./${eventId}/${e.nativeEvent.target.value}`);//drop right block
                    }}>
                        <option value="">{t('appAdmin.views.AdminSolutionsView.allScenarios')}</option>
                        {scenarios.map(s => (<option key={s.id} value={s.id}>{s.name}</option>))}
                    </select>}

                    {teams &&
                    <select style={{width: "100%"}} value={selectedTeamId || ''} onChange={(e) => {
                        setSelectedUserId(null);
                        setSelectedTeamId(Number(e.nativeEvent.target.value));
                        props.navigate(`./${eventId}${selectedScenarioId ? "/" + selectedScenarioId: ""}`);
                    }}>
                        <option value="">{t('appAdmin.views.AdminSolutionsView.allTeams')}</option>
                        {teams.map(t => (<option key={t.id} value={t.id}>{t.name}</option>))}
                    </select>}

                    {users &&
                    <select style={{width: "100%"}} value={selectedUserId || ''} onChange={(e) => {
                        setSelectedUserId(Number(e.nativeEvent.target.value));
                        props.navigate(`./${eventId}${selectedScenarioId ? "/" + selectedScenarioId: ""}`);
                    }}>
                        <option value="">{t('appAdmin.views.AdminSolutionsView.allStudents')}</option>
                        {users.map(u => (<option key={u.id} value={u.id}>{u.name}</option>))}
                    </select>}
                </div>
                {isSolutionsLoading &&
                <p className="text-info">{t('appAdmin.views.AdminSolutionsView.loading')}</p>}
                {report &&
                <TableBlockWithSort headers={headers}
                                    rows={report}
                                    saveable
                                    printable
                                    defaultSortDirections={[false,false,false,false,false,true,false]}
                                    showProperties={(fields) => {
                                        return headers.map((h, i) =>
                                            <div key={i}>
                                                <b>{h}:</b> {fields[i]}
                                            </div>)}
                                    }
                                    selectedIndex={selectedIndex} />}
            </div>}

            {solution &&
            <div className={`d-flex flex-column ${!isFullScreen ? 'w-50' : 'w-100'} scroll border border-dark border-top-0 border-end-0 border-bottom-0`}>
                <SaveButtonsBlock executeWithLock={props.executeWithLock}
                                  actions={[
                                      !disabled && licenseScenario && licenseScenario.isEvaluation && solution.status === 'finished' && {
                                          name: t('appAdmin.views.AdminSolutionsView.withdrawGrade'),
                                          action: () => executeWithConfirmation(
                                              () => updateSolution({id: solution.id, status: 'evaluating'}, queryAsRole)
                                                        .then(({data: {updateSolution: {solution, errors}}}) =>
                                                            setError(errors.map(e => parseBackendMessage(e, t)).join('\n'))))
                                       }, !disabled && ['running', 'finished', 'failed', 'evaluating'].includes(solution.status) && {
                                           name: t('appAdmin.views.AdminSolutionsView.deleteSolution'),
                                           color: 'danger',
                                           action: () => executeWithConfirmation(
                                              () => deleteSolution(solution.id, queryAsRole)
                                                        .then(({data: {deleteSolution: {solution, error}}}) =>
                                                            setError(error ? parseBackendMessage(error, t): null)))
                                       }, !disabled && ['finished', 'failed', 'evaluating'].includes(solution.status) && {
                                           name: t('appAdmin.views.AdminSolutionsView.recalcSolution'),
                                           action: () => executeWithConfirmation(
                                              () => recalcSolution(solution.id, queryAsRole)
                                                        .then(({data: {recalcSolution: {solution, error}}}) =>
                                                            setError(error ? parseBackendMessage(error, t): null)))
                                       }, !disabled && solution.status === "running" && {
                                          name: t('appAdmin.views.AdminSolutionsView.cancelCalucaltion'),
                                          action: () => executeWithConfirmation(
                                              () => updateSolution({id: solution.id, status: 'failed'}, queryAsRole)
                                                        .then(({data: {updateSolution: {solution, errors}}}) =>
                                                            setError(errors.map(e => parseBackendMessage(e, t)).join('\n'))))
                                       }
                                  ]} />
                {isSolutionDataLoading &&
                <p className="text-info">{t('appAdmin.views.AdminSolutionsView.loading')}</p>}
                {!isSolutionDataLoading && licenseScenario && licenseScenario.type === 'constellation' && solutionDataSource &&
                <>
                <SaveButtonsBlock withBack
                                  executeWithLock={props.executeWithLock} />
                <ConstellationSolutionView disabled
                                           dataSource={solutionDataSource}  // parameters
                                           scenario={licenseScenario}
                                           solution={solution}
                                           progress={progress}
                                           queryAsRole={queryAsRole}
                                           showSolution showResults
                                           onChange={onSolutionChange}
                                           loading={loading} errors={props.errors}
                                           uri={`${props.uri}/${eventId}/${scenarioId}/${solutionId}`}
                                           uriParams={props.uriParams} location={props.location} />
                </>}
                {!isSolutionDataLoading && licenseScenario && licenseScenario.type !== 'constellation' && solutionDataSource &&
                <SimpleSolutionView disabled
                                    dataSource={solutionDataSource} // parameters
                                    scenario={licenseScenario}
                                    solution={solution}
                                    progress={progress}
                                    queryAsRole={queryAsRole}
                                    showSolution showResults
                                    onChange={onSolutionChange}
                                    loading={loading} errors={props.errors}
                                    uri={`${props.uri}/${eventId}/${scenarioId}/${solutionId}`}
                                    uriParams={props.uriParams} location={props.location} />}
                {!disabled && licenseScenario && solution && licenseScenario.isEvaluation && solution.status === 'evaluating' &&
                <div className="p-3">
                    {/* TODO: normal UI (collections to add many rows and validations) */}
                    <h6>{t('appAdmin.views.AdminSolutionsView.gradeSolution')}</h6>
                    <LabelWithInfo name={t('appAdmin.views.AdminSolutionsView.points')} info={<span>{t('appAdmin.views.AdminSolutionsView.separator')}<br/>{t('appAdmin.views.AdminSolutionsView.arrayOrNumber')}</span>} />
                    <input className={classnames("form-control", evaluationDataSource.scoresParseError && "is-invalid")} type="text"
                           value={evaluationDataSource.scores} onChange={(e) => onEvaluationChange(evaluationDataSource, 'scores', e.nativeEvent.target.value )} />
                    <LabelWithInfo name={t('appAdmin.views.AdminSolutionsView.achievments')} info={t('appAdmin.views.AdminSolutionsView.arrayOrString')} />
                    <input className={classnames("form-control", evaluationDataSource.achievementsParseError && "is-invalid")} type="text"
                           value={evaluationDataSource.achievements} onChange={(e) => onEvaluationChange(evaluationDataSource, 'achievements', e.nativeEvent.target.value)} />
                    <Button onClick={() => {
                        let scores, achievements;
                        try {
                            scores = JSON.parse(evaluationDataSource.scores);
                        } catch (e) {
                            onEvaluationChange(evaluationDataSource, 'scoresParseError', true);
                            return;
                        }
                        if (evaluationDataSource.scoresParseError) {
                            onEvaluationChange(evaluationDataSource, 'scoresParseError', false);
                        }
                        if (evaluationDataSource.scores.trim().startsWith('[')) {
                            try {
                                achievements = JSON.parse(evaluationDataSource.achievements);
                            } catch (e) {
                                onEvaluationChange(evaluationDataSource, 'achievementsParseError', true);
                                return;
                            }
                        } else {
                            achievements = evaluationDataSource.achievements;
                        }
                        if (evaluationDataSource.achievementsParseError) {
                            onEvaluationChange(evaluationDataSource, 'achievementsParseError', false);
                        }
                        updateSolution(
                            { id: solution.id, status: 'finished', results: JSON.stringify({
                                    scores: Array.isArray(scores) ? scores :
                                        scores ? [scores] : [],
                                    achievements: Array.isArray(achievements) ? achievements :
                                        achievements ? [achievements] : []})
                            }, queryAsRole).then(({data: {updateSolution: {solution, errors}}}) =>
                                setError(errors.map(e => parseBackendMessage(e, t)).join('\n')))
                            }}
                            disabled={!evaluationDataSource.scores} color="primary">
                        <span>{t('appAdmin.views.AdminSolutionsView.saveGrade')}</span>
                    </Button>
                </div>}
            </div>}

            {showTournamentTable &&
            <TournamentTableModal withExportResults eventId={eventId} user={props.user} errors={props.errors} loading={props.loading} />}
        </div>);
});

export default AdminSolutionsView;