/*
 * Created by Gerald on 22.02.2019
 * Part of frontend
 */

import React from "react";

// This function takes a component...
export default function withSort(WrappedComponent) {
// ...and returns another component:

    class WithSort extends React.Component {

        static sort(rows, index, sortDirection) {
            function compare(row1, row2) {
                if (row1.notSelectable) {
                    return 1;
                } else if (row2.notSelectable) {
                    return -1;
                }

                function getField(row) {
                    //primitive
                    if (!React.isValidElement(row.fields[index])) {
                        return row.fields[index];
                    }
                    //react element f.e. <span> text </span>
                    else {
                        return row.fields[index].props.children;
                    }
                }

                const field1 = getField(row1);
                const field2 = getField(row2);

                return sortDirection ?
                    (field1 <= field2) ? 1 : -1 :
                    (field1 >= field2) ? 1 : -1;
            }

            return rows && rows.sort(compare);
        }

        constructor(props) {
            super(props);
            this.state = {
                rows: props.rows && props.rows.map((r, i) => ({...r, _originalIndex: i})),
                selectedIndex: props.selectedIndex,
                sortIndex: props.sortIndex,
                sortDirection: props.sortDirection
            };
            this.onSort = this.onSort.bind(this);
        }

        componentWillReceiveProps(nextProps) {
            let rows = nextProps.rows && nextProps.rows.map((r, i) => ({...r, _originalIndex: i}));
            let selectedIndex = nextProps.selectedIndex;
            const {sortIndex, sortDirection} = this.state;

            if (sortIndex >= 0) {
                rows = WithSort.sort(rows, sortIndex, sortDirection);
                selectedIndex = rows.findIndex(r => r._originalIndex === selectedIndex);
            }
            this.setState((prevState) => ({ rows, selectedIndex }));
        }

        onSort(sortIndex) {
            const defaultSortDirection = this.props.defaultSortDirections ? this.props.defaultSortDirections[sortIndex] : false;
            const sortDirection = (this.state.sortIndex === sortIndex) ? !this.state.sortDirection : defaultSortDirection;

            let rows = this.state.rows;
            let selectedIndex = this.props.selectedIndex;

            rows = WithSort.sort(rows, sortIndex, sortDirection);
            selectedIndex = rows.findIndex(r => r._originalIndex === selectedIndex);

            this.setState((prevState) => ({
                rows,
                selectedIndex,
                sortDirection,
                sortIndex }));

        }

        render() {
            return <WrappedComponent {...this.props} {...this.state} onSort={this.onSort}/>;
        }
    }

    WithSort.displayName = `WithSort(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithSort;
};