// backend message: 'this is a {key1} string and this is {key2} of json {"a": 12, "b": 5}'
// if translation is not found, the {key} is taken as it is. Result will be:
//                  'this is a translated string and this is example of json {"a": 12, "b": 5}'
//                             ^^^--- key1                   ^^^--- key2     ^^^--- no translation found
export const parseBackendMessage = (backendMessage, t) => {
    const regex = /{([^{}]+)}/g;

    const parsedMessage = (backendMessage || '').replace(regex, (match, group) => {
        const tgroup = t(group);
        return tgroup !== group ? tgroup : '{' + group + '}';
    });
    return parsedMessage;
};

// if value contains #key# it would be replaced with translation
export const translateObjOrArray = (obj, t) => {
    if ([undefined, null].includes(obj)) {
        return obj;
    }
    const stringifiedObj = JSON.stringify(obj);
    return JSON.parse(stringifiedObj.replace(/#.+?#/g, key => t(key.slice(1, -1))));
};

export const createMlJSONStringWithCurrentLanguage = (string, i18n) => {
  return JSON.stringify({[i18n.resolvedLanguage]: string});
};


const translationUtils = {
    parseBackendMessage,
    translateObjOrArray,
    createMlJSONStringWithCurrentLanguage
};

export default translationUtils;