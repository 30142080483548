/**
 * Based on library from here: https://github.com/naisutech/react-tree
 *
 * TODO: build on: https://github.com/frontend-collective/react-sortable-tree
 *                 https://github.com/frontend-collective/react-sortable-tree-theme-file-explorer
 */
import React, {useMemo, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import useForceRender from 'hooks/useForceRender'
import {useDrag, useDrop} from 'react-dnd'
import classnames from 'classnames'

import ActionConfirmationModalContainer, {DivWithActions} from 'components/blocks/ContainersWithActions'
import {faIcon} from "utils/visualHelpers";


const getAllChildrenOfImmediateChildren = (nodes, immediateChidren) => {
    const addChildrenRecursivelyByParentId = (currentParentId, allChildren) => {
        nodes.forEach(n => {
            if (
                !allChildren.includes(n) &&
                (n.parentId === currentParentId || (
                    currentParentId === null &&
                    n.parentId === undefined)) &&
                n.id !== currentParentId
            ) {
                allChildren.push(n);
                addChildrenRecursivelyByParentId(n.id, allChildren);
            }
        })
    };

    const allChildrenByParentId = {};
    immediateChidren.forEach(parent => {
        const allChildren = [];
        addChildrenRecursivelyByParentId(parent.id, allChildren);
        allChildrenByParentId[parent.id] = allChildren;
    });
    return allChildrenByParentId
};

const getImmediateChildrenWithParentId = (nodes, parentId) => {
    return nodes ? nodes.filter(
        n => n.parentId === parentId || (
            parentId === null &&
            n.parentId === undefined)
    ): [];
};


const Tree = (props) => {
    return (
        <ActionConfirmationModalContainer>
            <Container {...props} parent={null} level={0} />
        </ActionConfirmationModalContainer>);
};


const getOpenNodeIdsBySelectedIdSet = (nodes, selectedId) => {
    if (!selectedId) {
        return new Set();
    }

    const finallyNode =
        nodes.find(n => n.id === selectedId) ||
        nodes.find(n => n.items && n.items.map(i => i.id).includes(selectedId));

    if (!finallyNode) {
        return new Set();
    }

    const nodesIx = {};
    nodes.forEach(n => {
        nodesIx[n.id] = n;
    });

    const initiallyOpenNodeIds = new Set([finallyNode.id]);
    let currentId = finallyNode.parentId;

    while(currentId) {
        initiallyOpenNodeIds.add(currentId);
        const node = nodesIx[currentId];
        currentId = node && node.parentId;
    }

    return initiallyOpenNodeIds;
};



const Container = (props) => {
    const {nodes, parent, selectedId} = props;

    const forceRender = useForceRender();

    // immediate children of this container
    const immediateChildren = useMemo(
        () => getImmediateChildrenWithParentId(nodes, parent ? parent.id : null),
        [nodes, parent]
    );

    // get container nodes for this level and children for next container
    const allChildrenByImmediateChildrenIds = useMemo(
        () => getAllChildrenOfImmediateChildren(nodes, immediateChildren),
        [nodes, immediateChildren]);
    // const [allChildrenByImmediateChildrenIds, allChildren] = useMemo(
    //     () => {
    //         const allChildrenByImmediateChildrenIds = getAllChildrenOfImmediateChildren(nodes, immediateChildren);
    //         const allChildren = [...immediateChildren];
    //         allChildrenByImmediateChildrenIds.forEach(id => allChildren.concat(allChildrenByImmediateChildrenIds[id]));
    //         return [allChildrenByImmediateChildrenIds, allChildren];
    //     },
    //     [nodes, immediateChildren]);

    // node states by id as ref
    // changes to it do not cause rerender, it is only used as an info about nodes
    // includes:
    //  - isOpen
    const nodeStatesByIdRef = useRef({});

    // effect to change isOpen
    useEffect(
        () => {
            let shouldRerender = false;
            // include selectedId changes
            const newOpenNodeIdsSet = getOpenNodeIdsBySelectedIdSet(nodes, selectedId);
            // include initialOpen for new nodes
            immediateChildren.forEach((n) => {
                //new node?
                if (!nodeStatesByIdRef.current[n.id]) {
                    nodeStatesByIdRef.current[n.id] = {isOpen: n.isInitiallyOpen};
                    shouldRerender = shouldRerender || n.isInitiallyOpen;
                }
                // was not open before?
                if (newOpenNodeIdsSet.has(n.id) && !nodeStatesByIdRef.current[n.id].isOpen) {
                    nodeStatesByIdRef.current[n.id].isOpen = true;
                    shouldRerender = true;
                }
            });
            shouldRerender && forceRender();
        },
        [nodes, selectedId, immediateChildren, nodeStatesByIdRef, forceRender]);

    // rendering

    const {level, showEmptyNodes, dragAndDrop, onDrop, onAction, executeWithLock} = props;

    return (
        <div className="tree-container">
            {!!immediateChildren.length &&
            immediateChildren.map(node =>
                <ContainerItemWithDropZones key={node.id}
                                            node={node}
                                            parent={parent}
                                            toggle={() => {
                                                nodeStatesByIdRef.current[node.id] &&
                                                (nodeStatesByIdRef.current[node.id].isOpen =
                                                    !nodeStatesByIdRef.current[node.id].isOpen);
                                                // ref was changed, need to rerender
                                                forceRender();
                                            }}
                                            isOpen={
                                                nodeStatesByIdRef.current[node.id] &&
                                                nodeStatesByIdRef.current[node.id].isOpen}
                                            isRoot={!parent}
                                            allChildren={allChildrenByImmediateChildrenIds[node.id]}
                                            level={level}
                                            selectedId={selectedId}
                                            showEmptyNodes={showEmptyNodes}
                                            dragAndDrop={dragAndDrop}
                                            onDrop={onDrop}
                                            onAction={onAction}
                                            executeWithLock={executeWithLock} />)}
        </div>);
};


const ContainerItemWithDropZones = (props) => {
    const {node, parent, toggle, isOpen, isRoot, allChildren, level, selectedId,
           showEmptyNodes, dragAndDrop, onDrop, onAction, executeWithLock} = props;

    const {t} = useTranslation();

    const [{ isOverContainerItemCurrent, isOverContainerItem, canDrop, isDraggedItemLeaf, isSelfDrop }, dropContainerItem] = useDrop({
        accept: "node",
        canDrop: (item, monitor) => {
            const isNodeInsideDraggedItem = item.allChildren && item.allChildren.includes(node);
            return !node.notSelectable && !isNodeInsideDraggedItem;
        },
        collect: monitor => ({
            isOverContainerItemCurrent: !!monitor.isOver({shallow: true}),
            isOverContainerItem: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            isDraggedItemLeaf: monitor.getItem() && !monitor.getItem().hasToggle,
            isSelfDrop: monitor.getItem() && monitor.getItem().node === node
        })
    });

    const [{ isOverContainerHeader }, dropContainerHeader] = useDrop({
        accept: "node",
        drop: (item, monitor) => item.node !== node ? onDrop(item.node, node, null) : undefined,
        collect: monitor => ({
            isOverContainerHeader: !!monitor.isOver()
        })
    });

    const [{ isOverInside }, dropInside] = useDrop({
        accept: "node",
        drop: (item, monitor) => item.node !== node ? onDrop(item.node, node, null) : undefined,
        collect: monitor => ({
            isOverInside: !!monitor.isOver()
        })
    });

    const [{ isOverSibling }, dropSibling] = useDrop({
        accept: "node",
        drop: (item, monitor) => item.node !== node ? onDrop(item.node, parent, node) : undefined,
        collect: monitor => ({
            isOverSibling: !!monitor.isOver()
        })
    });

    // isSelf is a valid drop target but without showing any drop zones
    const showInsideDropZone = (isOverContainerItemCurrent || isOverContainerHeader || isOverInside || isOverSibling) && canDrop && !isSelfDrop && !node.noDragAndDrop;
    // siblings can drop even if node has noDragAndDrop
    const showSiblingDropZone = (isOverContainerItemCurrent || isOverContainerHeader || isOverInside || isOverSibling) && canDrop && !isSelfDrop;

    return (
        <div ref={canDrop && dragAndDrop ? dropContainerItem : undefined}
             // need to show margins for draggable, or flashing visual bug happens when mouse moves from parent container with drop zones to draggable without
             className={classnames(isSelfDrop && "drag-source", isOverContainerItem && "active")}>
            <div ref={dropContainerHeader}>
            <NodeRowDraggable node={node}
                              toggle={toggle}
                              isOpen={isOpen}
                              isRoot={isRoot}
                              isEmpty={allChildren.length === 0 &&
                              (!node.items || node.items.length === 0)}
                              level={level}
                              selectedId={selectedId}
                              allChildren={allChildren}
                              parent={parent}
                              dragAndDrop={dragAndDrop}
                              onAction={onAction}
                              executeWithLock={executeWithLock} />
            </div>

            {isOpen &&
            <div>
                {showInsideDropZone && !isDraggedItemLeaf &&
                <div ref={dropInside} className={classnames("drop-target", (isOverContainerHeader || isOverInside) && "active")} style={{paddingLeft: `${20 * (level + 2)}px`}}>
                    {faIcon('fa-arrow-right')}
                </div>}

                <Container parent={node}
                           nodes={allChildren}
                           level={level + 1}
                           selectedId={selectedId}
                           showEmptyNodes={showEmptyNodes}
                           dragAndDrop={dragAndDrop}
                           onDrop={onDrop}
                           onAction={onAction}
                           executeWithLock={executeWithLock} />

                {showInsideDropZone && isDraggedItemLeaf &&
                <div ref={dropInside} className={classnames("drop-target", (isOverContainerHeader || isOverInside) && "active")} style={{paddingLeft: `${20 * (level + 2)}px`}}>
                    {faIcon('fa-arrow-right')}
                </div>}

                {node.items &&
                node.items.map((item, j) => {
                    return (
                        <LeafItemWithDropZones key={j}
                                               node={item}
                                               level={level + 1}
                                               selectedId={selectedId}
                                               parent={node}
                                               dragAndDrop={dragAndDrop}
                                               onDrop={onDrop}
                                               onAction={onAction}
                                               executeWithLock={executeWithLock} />
                    )
                })}
                {showEmptyNodes && !node.items && (
                    <div className="tree-node-wrapper empty" style={{paddingLeft: `${20 * (level + 1)}px`}}>{t('components.blocks.Tree.noElements')}</div>
                )}
            </div>}

            {!isOpen && showInsideDropZone &&
            <div ref={dropInside} className={classnames("drop-target", (isOverContainerHeader || isOverInside) && "active")} style={{paddingLeft: `${20 * (level + 2)}px`}}>
                {faIcon('fa-arrow-right')}
            </div>}

            {showSiblingDropZone && !isDraggedItemLeaf &&     // will be a sibling next to it
            <div ref={dropSibling} className={classnames("drop-target", isOverSibling && "active")} style={{paddingLeft: `${20 * (level + 1)}px`}}>
                {faIcon('fa-arrow-right')}
            </div>}
        </div>);
};


const LeafItemWithDropZones = (props) => {
    const {node, parent, selectedId, level, dragAndDrop, onDrop, onAction, executeWithLock} = props;

    const [{ isOver, canDrop, isSelfDrop }, drop] = useDrop({
        accept: "node",
        canDrop: (item, monitor) => {
            const isNodeInsideDraggedItem = item.node === parent || (item.allChildren && item.allChildren.includes(parent));
            const isNodeIncompatibleWithDraggedItem = item.hasToggle;
            return !node.notSelectable && !isNodeInsideDraggedItem && !isNodeIncompatibleWithDraggedItem;
        },
        drop: (item, monitor) => item.node !== node ? onDrop(item.node, parent, node) : undefined,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            isSelfDrop: monitor.getItem() && monitor.getItem().node === node
        })
    });

    // isSelf is a valid drop target but without showing any drop zones
    const showDropZone = isOver && canDrop && !isSelfDrop;

    return (
        <div ref={canDrop && dragAndDrop && !parent.noDragAndDrop ? drop : undefined}
             // need to show margins for draggable, or flashing visual bug happens when mouse moves from parent container with drop zones to draggable without
             className={classnames(isSelfDrop && "drag-source", isOver && "active")}>
        <NodeRowDraggable node={node}
                          level={level}
                          selectedId={selectedId}
                          parent={parent}
                          dragAndDrop={dragAndDrop}
                          onAction={onAction}
                          executeWithLock={executeWithLock} />

            {showDropZone &&          // will be a sibling next to it
            <div className="drop-target active" style={{paddingLeft: `${20 * (level + 1)}px`}}>
                {faIcon('fa-arrow-right')}
            </div>}
        </div>);
};


const NodeRowDraggable = (props) => {
    const {node, toggle, isOpen, isEmpty, selectedId, level, allChildren, dragAndDrop, onAction, executeWithLock} = props;

    const [{isDragging}, drag] = useDrag({
        item: { type: "node", node: node, allChildren: allChildren, hasToggle: !!toggle },
        canDrag: () => !node.notSelectable,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const isSelected = (selectedId || selectedId === 0) && selectedId === node.id;
    return (
        // <div onDoubleClick={toggle && (() => toggle())}>
        <div ref={dragAndDrop && !(node.noDragAndDrop || node.noDrag ) ? drag : undefined}
             style={{ opacity: isDragging ? 0.5 : 1 }}>
            <DivWithActions className={classnames("tree-node", toggle && isOpen && "open", isSelected && "selected")}
                            data={node} onAction={onAction} executeWithLock={executeWithLock}>
                <div className="tree-node-wrapper" style={{paddingLeft: `${20 * level}px`}}>
                    {!node.notSelectable &&
                    <i className={classnames("tree-node-icon", `fa fa-${toggle ? (isEmpty ? 'circle fa-sm': (isOpen ? 'chevron-down' : 'chevron-right')) : node.icon ? node.icon: 'paperclip'}`)}
                       onClick={toggle && ((e) => {
                           e.stopPropagation();
                           // e.nativeEvent.stopImmediatePropagation();
                           toggle();
                       })} />}
                    <span className={classnames("tree-node-text", node.notSelectable && "controlelement")}>{node.name}</span>
                </div>
            </DivWithActions>
        </div>);
};

export default Tree;