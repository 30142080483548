import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import TableBlockWithSort from 'components/blocks/TableBlockWithSort'

import {queryLicenseEvents, queryEventUserInfos} from '../data/adminQueries'
import {deleteCompetitionSession} from '../data/adminMutations'
import {breadcrumbsStore} from 'appBase/TopNav'
import dateUtils from 'utils/dateUtils'
import {ADMIN_USERS_PROGRESS_VIEW} from 'constants/constants'

const UsersProgressView = compose(
    withUriParams([['eventId', Number, {optional: true}],
                   ['userId', Number, {optional: true}]]),
    queryLicenseEvents,
    queryEventUserInfos,
    deleteCompetitionSession,
    withInitialDataLoadWaiting(['licenseEvents'])
)((props) => {
    const {eventId, userId, licenseEvents, deleteCompetitionSession} = props;

    //showing ONLY events with isCompetitionMode
    const events = licenseEvents.filter(e => e.isCompetitionMode);
    const event = eventId && events.find(e => e.id === eventId);

    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    // links
    const containerId = ADMIN_USERS_PROGRESS_VIEW;
    breadcrumbsStore.register(containerId);

    const links = [{
        name: t('appAdmin.views.UsersProgressView.usersProgress'),
        to: `${props.uri}${props.location.search}`
    }];
    if (event) {
        links.push({
            name: event.name,
            to: `${props.uri}/${event.id}${props.location.search}`
        });
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    const headers = [t('appAdmin.views.UsersProgressView.name'),
                     t('appAdmin.views.UsersProgressView.email'),
                     t('appAdmin.views.UsersProgressView.userSolutionsNumber'),
                     t('appAdmin.views.UsersProgressView.competitionSessionStartedAt')];

    const eventUserInfos = event && props.eventUserInfos &&
          props.eventUserInfos.filter(eUI => userId ? (eUI.user.id === userId): true)
                              .map(eUI => ({
                                  fields: [
                                      eUI.user.name,
                                      eUI.user.email,
                                      eUI.userSolutionsNumber,
                                      dateUtils.dateToLocaleDateTimeString(eUI.competitionSessionStartedAt, i18n)
                                  ],
                                  actions: {
                                    "removeConfirmation": (() => {
                                        deleteCompetitionSession(eUI.id);
                                        navigate(`${props.uri}/${eventId}${props.location.search}`);
                                    })
                                  }
                              }));

    const users = event && props.eventUserInfos &&
        props.eventUserInfos.map(eUI => ({
            id: eUI.user.id,
            name: eUI.user.name
        }));

    return (
        <div className={"flex-grow d-flex flex-row scroll-parent"}>
            <div className="w-50 d-flex flex-column scroll">

                <select style={{width: "100%"}} onChange={(e) => navigate(`${props.uri}/${e.nativeEvent.target.value}${props.location.search}`)}>
                    <option value="">{t('appAdmin.views.UsersProgressView.allCompetitions')}</option>
                    {events.map(e => (<option key={e.id} value={e.id}>{e.name}</option>))}
                </select>

                {eventUserInfos &&
                <select style={{width: "100%"}} onChange={(e) => navigate(`${props.uri}/${eventId}/${e.nativeEvent.target.value}${props.location.search}`)}>
                    <option value="">{t('appAdmin.views.UsersProgressView.allUsersThatStartedExam')}</option>
                    {users.map(u => (<option key={u.id} value={u.id}>{u.name}</option>))}
                </select>}

                {eventUserInfos &&
                <TableBlockWithSort headers={headers}
                                    rows={eventUserInfos}
                                    saveable
                                    printable
                                    defaultSortDirections={[false, false, false, false]}
                                    sortIndex={3} />}

            </div>
        </div>);
});

export default UsersProgressView;